import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService as Storage } from '../../../../services/storage.service';
import { ApiService } from '../../../../services/api.service';
import {
  AlertController,
  MenuController,
  ModalController,
  PopoverController,
} from '@ionic/angular';

import { Router } from '@angular/router';
import { NotificationsService } from '../../../../services/notifications.service';
import { DetailsPage } from 'src/app/members/notifications/details/details.page';
import { notificationsImages } from 'src/app/members/notifications/notificationsUtil';
import { BadgeService } from 'src/app/services/plugins/badge.service';
import { AppService } from 'src/app/app.service';
import { Constants } from 'src/app/constants.enum';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  @Input('type') type: string = 'popover';
  deviceData;
  loading = true;
  alarmImages = [];
  devices = [];
  selectedNotifications = [];
  language;
  user: any;
  notifications: { meldungtyp; bezeichnung; deviceName; dateStr; dateunix }[] =
    [];
  allNotifysRead = false;
  notificationCount:any  
  nCount = 0;
  public darkMode = false;

  constructor(
    private _translate: TranslateService,
    private storage: Storage,
    private api: ApiService,
    private modalCtrl: ModalController,
    private route: Router,
    private popoverCtrl: PopoverController,
    private notifService: NotificationsService,
    private alert: AlertController,
    private badgeService: BadgeService,
    private menuController: MenuController,
    private appService: AppService,
    public dataService: DataService,
  ) {}

  async ngOnInit() {    
    this.user = this.appService.user;
    /*
    this.alarmImages = [
      '',
      'assets/images/alert_shock(1).svg',
      'assets/images/Alert_Battery(1).svg',
      'assets/images/alert_radius.svg',
      'assets/images/alert_sos.svg',
      'assets/images/speed.svg',
      'assets/images/power-cut-off.svg',
      'assets/images/ignition-alert.svg'
    ];
    */

    /*
    this.alarmImages = [
      '', //0
      'assets/images/alert_shock(1).svg', // 1
      'assets/images/Alert_Battery(1).svg', // 2
      'assets/images/alert_radius.svg', // 3
      'assets/images/alert_sos.svg', // 4
      'assets/images/speed.svg', // 5
      'assets/images/alert_powercut(1).svg', // 6
      'assets/images/ignition-alert.svg', // 7
      '', // 8
      'assets/images/alert_Drop-Alarm.svg', // 9
      'assets/images/alarm_enter-geofence.svg', // 10
      'assets/images/alarm_out-geofence.svg', // 11
      'assets/images/alert_falldown' // 12
    ];
    */
    this.user = await this.storage.get(Constants.USER_DATA);    
    this.notifService.notifications.subscribe(async(r: any) => {
      let notifications: any = await this.notifService.getNotificationsCount({});
      this.nCount = notifications.number_of_unread
    })

    this.notifService.changeUnreadNots.subscribe(async(r:any) => {      
      if(this.nCount > 0){
        this.nCount = this.nCount + r;
      }
    })

    this.appService._darkMode.subscribe((r) => {
      this.darkMode = r;
    })
    this.darkMode = this.appService.darkMode;    
  }

  async ngAfterViewInit() {
    this.alarmImages = notificationsImages;
    await this.loadNotifications();
    this.notificationCount = await this.notifService.getNotificationsCount(
      {},
    );    
  }

  async markAllRead() {
    const alert = await this.alert.create({
      header: this._translate.instant('dashboard.confirm'),
      message: this._translate.instant('dashboard.marlAllNofRead'),
      cssClass: 'delete-alert',
      mode: 'ios',
      buttons: [
        {
          text: this._translate.instant('dashboard.no'),
          role: 'cancel',
          handler: (blah) => {},
        },
        {
          text: this._translate.instant('dashboard.yes'),
          handler: () => {
            this.notifService.markAll(this.notifications, 1, true).then((r) => {
              if (!r) {
                this.notifService.showToast(
                  this._translate.instant('dashboard.errorToast'),
                  this._translate.instant('dashboard.errorToMark'),
                  3000,
                  'danger',
                );
              } else {
                this.badgeService.clearBadge();
              }
            });
          },
        },
      ],
    });

    await alert.present();
  }
  handleRefresh(event) {
    console.log('handleRefresh');
    setTimeout(async () => {
      // Any calls to load data go here
      // await this.loadNotifications();
      this.notifService.lastId = 0;
      await this.notifService.loadFromServer(null);
      event.target.complete();
    }, 1000);
  }
  async selectItem(ev: any, not: any) {
    if (ev.target.checked) {
      this.selectedNotifications.push(not);
    } else {
      const findIndex = this.selectedNotifications.findIndex(
        (ntf) => ntf.id === not.id,
      );
      if (findIndex > -1) {
        this.selectedNotifications.splice(findIndex, 1);
      }
    }
  }

  async markSelectedNotifications() {
    const alert = await this.alert.create({
      header: this._translate.instant('dashboard.confirm'),
      message: this._translate.instant('dashboard.markSelNotfRead'),
      cssClass: 'delete-alert',
      mode: 'ios',
      buttons: [
        {
          text: this._translate.instant('dashboard.no'),
          role: 'cancel',
          handler: (blah) => {},
        },
        {
          text: this._translate.instant('dashboard.yes'),
          handler: () => {
            this.notifService
              .markAll(this.selectedNotifications, 1)
              .then((r) => {
                if (!r) {
                  this.notifService.showToast(
                    this._translate.instant('dashboard.errorToast'),
                    this._translate.instant('dashboard.errorToMark'),
                    3000,
                    'danger',
                  );
                } else {
                  this.badgeService.clearBadge();
                  this.selectedNotifications.length = 0;
                }
              });
          },
        },
      ],
    });

    await alert.present();
  }

  async openDetails(not) {
    if (not) {
      const md = await this.modalCtrl.create({
        component: DetailsPage,
        cssClass: 'submodal-shadow',
        componentProps: {
          notification: not,
        },
      });

      await md.present();
    } else {
      this.notifService.showToast(
        this._translate.instant('dashboard.errorOpenHeader'),
        this._translate.instant('dashboard.errorOpenDetails'),
        3000,
        'danger',
      );
    }
  }

  async loadNotifications() {
    this.loading = true;
    this.notifService.notifications.subscribe((r: any) => {
      //await this.notifService.getUnreadNotifications().then(r => {
      this.notifications = this.notifService.getUnreadNotifications();
      this.checkUnreadNotifications();
    });
    this.loading = false;

    this.notifService.notifyChanged.subscribe(async (r: any) => {
      //this.events.subscribe('notifications:changed', async r => { // UPDATE WHEN CHANGE SOMETHING IN NOTIFICATIONS
      this.notifications = this.notifService.getUnreadNotifications();
      // this.badgeService.setBadge(this.notifications?.length);
      this.notificationCount = await this.notifService.getNotificationsCount(
        {},
      );
      this.badgeService.setBadge(this.notificationCount?.number_of_unread);
      this.checkUnreadNotifications();
    });
  }

  async checkUnreadNotifications() {
    if (
      this.notifService.notifications.value.length > 0 &&
      this.notifService.getUnreadNotifications().length === 0
    ) {
      this.allNotifysRead = true;
    } else {
      this.allNotifysRead = false;
    }
  }

  async close() {
    let SharedViewMode = this.dataService.getData('SharedViewMode');
    if (this.type == 'popover') {
      this.closeNotification();
    } else if (this.type == 'modal') {
      this.modalCtrl.dismiss();
    }
    if (SharedViewMode) {
      await this.route.navigate([
        '/members/notifications/' + SharedViewMode['viewcheck'],
      ]);
    } else {
      await this.route.navigate(['/members/notifications']);
    }
  }
  async closeNotification() {
    await this.popoverCtrl.dismiss();
  }

  async showPopup(notification: any) {}
}
