import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter, first, Observable, ReplaySubject, Subject, take } from 'rxjs';
import { AppFirstLoadResponse } from '../interfaces/app-first-load.model';
import { AuthenticationService } from './authentication.service';
import { StorageService } from './storage.service';
import { Constants } from '../constants.enum';
import { Customer } from '../interfaces/customer.model';
import { CustomerSettings } from '../interfaces/customer-settings.model';
import { Device } from '../interfaces/device.model';
import { ServerAlert } from '../interfaces/server-alert.model';
import { HttpClient } from '@angular/common/http';
import { environment as ENV, environment } from 'src/environments/environment';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class StartupService {
  public startupData$: Subject<AppFirstLoadResponse> = new Subject<AppFirstLoadResponse>();
  public startupData: AppFirstLoadResponse = null;
  public startupDataReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public customerData$ = new ReplaySubject<{ success: Customer }>(1);
  public customerSettings$ = new ReplaySubject<{ success: CustomerSettings }>(1);
  public devices$ = new ReplaySubject<{ success: Device[] }>(1);
  public serverAlerts$ = new ReplaySubject<{ success: ServerAlert[] }>(1);
  public notifications$ = new ReplaySubject<{ success: Notification[] }>(1);
  onLoadCustomerDataError = new Subject<boolean>();

  private userId = 0;
  hideLoadingScreen$ = new Subject();
  constructor (private authService: AuthenticationService, private storage: StorageService, private http: HttpClient) {
    this.load();
  }

  load () {
    // Subscribe to userId when change, update when check user Token
    this.authService.authenticationState.subscribe(async (tokenExist) => {
      // Get startup info from api
      this.authService.platform.pipe(filter(r => r!==false), first()).subscribe(async (state) => {
        if (state && tokenExist) {
          this.userId = await this.storage.get(Constants.USER_ID);
          if (this.userId) {
            //_ request all custoer
            this.requestFirstLoadData().subscribe((startupRes: AppFirstLoadResponse) => {
              console.warn('[DEBUG] First load data', startupRes);
              this.initializeObservables(startupRes);

              this.startupData = startupRes;
              this.startupData$.next(startupRes);
              this.startupDataReady$.next(true);

            }, error => {
              this.catchResponseError(error);
            })
          } else {
            //_ Test more this line ; is not working fine
            // this.hideLoadingScreen$.next(true);
          }
        }
      });
    });
  }

  requestFirstLoadData () {
    let url = ENV.ROUTE + ENV.API_VERSION + 'customer/app-first-load/' + this.userId;
    return this.http.get(url);
  }

  initializeObservables (data: AppFirstLoadResponse) {
    this.customerData$.next(data.success.customerData); //_ ✅
    this.customerSettings$.next(data.success.settings); //_ ✅
    this.devices$.next(data.success.devices); //_ ✅
    this.serverAlerts$.next(data.success.serverAlerts); //_ ✅
    this.notifications$.next(data.success.notifications); //_ ✅
  }

  obsToPromise (obs: Observable<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      obs.pipe(take(1)).subscribe(res => {
        resolve(res);
      }, error => reject(error));
    });
  }

  catchResponseError(error: Error) {
    console.warn('DEBUG CATCH RESPONSE RROR', error);
    this.onLoadCustomerDataError.next(true);
    this.hideLoadingScreen$.next(true);
  }
}
