import { DecimalPipe } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { DeviceService } from 'src/app/public/stand-alone-page/device.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PaneService } from '../../../home/components/right-menu/pane.service';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.scss'],
})
export class DeviceSettingsComponent implements OnInit {
  @Input() deviceId: number | null;
  @Input() device: any;
  @Input() map;
  isLoading: boolean = true;
  permissions = { see: true, modify: true, share: true };
  geofencesAreDisabled: any = false;
  speed_unit = '';
  // geofences: Array<any> = [];
  constructor(
    private appService: AppService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDataService,
    private authService: AuthenticationService,
    private toastController: ToastController,
    private decimalPipe: DecimalPipe,
    private translate: TranslateService,
    private modalController: ModalController,
    public paneService: PaneService,
  ) {}

  ngOnInit() {
    console.log('DeviceSettingsComponent', this.device);
    console.log('ss', this.deviceService.devices[this.deviceId]);
    this.permissions = this.deviceService.devices[this.deviceId]?.permissions;
    this.isLoading = false;
    this.getGeofenceAlertsState();
    let distance_unit = this.appService.user.distance_unit;
    this.speed_unit = this.translate.instant('speed.' + distance_unit + 'H');
  }
  alarmChange($event) {
    console.log('alarmChange', $event);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['device'] && !changes['device'].firstChange) {
      // Handle device change if it's not the first time
      console.log('Device has changed:', this.device);
      this.cdr.detectChanges(); 
    }
  }
  getGeofenceAlertsState(updateGeofences: Array<any> = null) {
    let deactiveGeofences = 0;
    const geofences = updateGeofences || this.device.geofences;

    geofences?.forEach((geofence: any) => {
      if (geofence.trigger_enter == 0 && geofence.trigger_leave == 0)
        deactiveGeofences = deactiveGeofences + 1;
    });

    this.geofencesAreDisabled = !(
      deactiveGeofences == this.device.geofences?.length
    );
    console.log('geofencesAreDisabled', this.geofencesAreDisabled);
  }

  async enableAlarmData(status: number, id: string) {
    console.log('enableAlarmData');
    console.log({ status, id });
    console.log('this.deviceService.devices', this.deviceService.devices);

    if (!this.permissions?.modify) return;
    if (id == 'geofence') {
      this.authService
        .enableDisableGeofences(this.deviceId, Number(status))
        .then((res) => {
          console.log('res', res);
          if (!res.success.length && !this.device.geofences?.length) {
            this.geofencesAreDisabled = status;
          } else {
            this.getGeofenceAlertsState(res.success);
          }
          //_ Update geofences status and then update the device in devices service
          this.device.geofences.forEach((geo) => {
            geo.trigger_enter = status;
            geo.trigger_leave = status;
          });

          this.deviceService.updateDeviceInfo(this.device);
          this.presentToast(`Device settings updated succesfully`);
        });
    } else {
      await this.authService
        .updateDeviceAlarms(this.deviceId, id, Number(status))
        .then((res) => {
          console.log('updateDeviceAlarms', res);
          this.device[id] = status;
          this.presentToast(`Device settings updated succesfully`);
        })
        .catch((e) => {});
    }
  }
  async updateSpeed(value, alarmName) {
    console.log('val', value);
    // if (value > 10) {
    //   this.presentToast(this.translate.instant('alerts.speedValidError'));
    //   return;
    // }
    let alarmUpdatedText = 'alerts.speedUpdateMessage';
    if (!this.permissions.modify) return null;

    //_ Convert speed if user settings is miles to -> km
    let speed = value;

    if (
      this.appService.user.distance_unit != 'km' &&
      alarmName === 'alarmgeschwindigkeitab'
    ) {
      speed = this.decimalPipe.transform(value * 1.60934, '1.0-0');
    }
    if (alarmName === 'alarmgeschwindigkeitab' && speed < 10) {
      this.presentToast(this.translate.instant('alerts.speedValidError'));
    }
    await this.authService
      .updateDeviceAlarms(this.deviceId, alarmName, speed)

      .then(async (res) => {
        console.log('res', res);
        this.device.properties[alarmName] = speed;
        this.presentToast(this.translate.instant(alarmUpdatedText));
      })
      .catch((e) => {});
  }
  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      cssClass: 'ion-text-center',
      color: 'dark',
    });
    toast.present();
  }
  preventDefault(event) {
    event.stopPropagation();
  }
  async enableDisableAllAlerts(event, item) {
    await this.authService
      .updateDeviceAlarms(
        item.id,
        'alarm_enabled',
        item.alarm_enabled == 1 ? 0 : 1,
      )
      .then((res) => {
        this.deviceService.devices[item.id].properties.alarm_enabled =
          res['success'].alarm_enabled;
        item.alarm_enabled = res['success'].alarm_enabled;
        this.appService.showToast(
          null,
          item.alarm_enabled
            ? this.translate.instant('enableDisableAllAlerts.alertEnabled')
            : this.translate.instant('enableDisableAllAlerts.alertDisabled'),
          3000,
          'success',
        );
      })
      .catch((e) => {
        this.appService.showToast(
          null,
          this.translate.instant('enableDisableAllAlerts.errorWhileUpdating'),
          2000,
          'danger',
        );
      });
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
