<ion-list [inset]="true" lines="none" class="ion-margin-bottom">
  <ion-item lines="none">
    <ion-icon
      slot="start"
      color="primary"
      name="notifications-outline"
    ></ion-icon>
    <ion-label class="ion-text-wrap">{{
      "sidemenuDrag.enableDisableAllAlerts" | translate
    }}</ion-label>
    <ion-toggle
      class="allAlertToggle ion-no-padding"
      mode="ios"
      slot="end"
      (click)="preventDefault($event); enableDisableAllAlerts($event, device)"
      [checked]="device.alarm_enabled == 1 ? true : false"
      title="{{ 'sidemenuDrag.enableDisableAllAlerts' | translate }}"
      id="enabledisable-alert{{ deviceId }}"
      aria-label=""
    >
    </ion-toggle>
  </ion-item>
</ion-list>

<ion-list [inset]="true" lines="none" class="ion-margin-bottom">
  <app-alert
    class="deviceInfo"
    [deviceID]="deviceId"
    [data]="[device]"
    [map]="map"
    [deviceMarkers]="[]"
    [hideGeofencesFeature]="true"
    (onAlarmChange)="alarmChange($event)"
    toastPosition="bottom"
  ></app-alert>
</ion-list>
