import { Injector, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Pickr from '@simonwep/pickr';
import { MapComponent } from 'src/app/members/map/components/map/map.component';
import { MapMixin } from 'src/app/members/map/map.mixin';

export class DeviceMixin extends MapMixin {
  shapeColor = '#ff4e00';
  pickrGL: any;
  public translate: TranslateService;

  geoTypes: any = [];
  selectedGeoType: any = {};
  constructor(injector: Injector) {
    super(injector);
    this.translate = injector.get(TranslateService);
    this.geoTypes = [
      {
        type: 'circle',
        name: this.translate.instant('alerts.circleText'),
        image: 'assets/images/alarm/circle.svg',
      },
      {
        type: 'polygon',
        name: this.translate.instant('alerts.polygonText'),
        image: 'assets/images/alarm/poligon.svg',
      },
    ];
    this.selectedGeoType = {
      type: 'circle',
      name: this.translate.instant('alerts.circleText'),
      image: 'assets/images/alarm/circle.svg',
    };
  }


  getPickr(element: string): Promise<string> {
    if (this.pickrGL?.getRoot()) {
      this.pickrGL.destroyAndRemove();
      this.pickrGL = null;
    }
  
    return new Promise((resolve, reject) => {
      try {
        const pickr = Pickr.create({
          el: element,
          theme: 'nano',
          default: this.shapeColor,
          useAsButton: true,
          appClass: 'pickr-border',
          components: {
            preview: true,
            opacity: false,
            hue: true,
            interaction: {
              hex: true,
              rgba: false,
              input: true,
              hsva: false,
              cancel: true,
              save: true,
            },
          },
          i18n: {
            'btn:save': this.translate.instant('alerts.saveText'),
            'btn:cancel': this.translate.instant('alerts.cancel'),
          },
        });
  
        pickr.on('save', (color, instance) => {
          const selectedColor = color.toHEXA().toString();
          this.shapeColor = selectedColor;
          
          pickr.destroyAndRemove();
  
          // Resolve the promise with the selected color
          resolve(selectedColor);
        });
  
        pickr.on('cancel', () => {
          pickr.destroyAndRemove();
          // Optionally reject or resolve with a default color if cancelled
          reject('Color selection cancelled');
        });
  
        pickr.show();
        this.pickrGL = pickr;
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }
  
  handleChange(ev) {
    console.log('Current value:', JSON.stringify(ev.target.value));

    this.selectedGeoType = this.geoTypes.find(
      (geo) => geo.type == ev.target.value,
    );
    console.log('this.selectedGeoType', this.selectedGeoType);
  }
}
