import { Component, OnInit, Input } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Constants } from "src/app/constants.enum";
import { environment as ENV } from "src/environments/environment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DeviceImgComponent } from "../device-img/device-img.component";
//import { CopyLinkComponent } from '../../../../shared/copy-link/copy-link.component';
import { StorageService as Storage } from "./../../../../services/storage.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastController } from "@ionic/angular";
import { PopoverController } from "@ionic/angular";
import { CsPopoverComponent } from "src/app/components/cspopover/cspopover.component";
import { DeviceManagmentService } from "../../device-managment.service";
import * as moment from "moment";
import { DateRangeComponent } from "src/app/components/date-range/date-range.component";
import { AppService } from "src/app/app.service";
import { DeviceDataService } from "src/app/members/map/devicesData.service";
import { DeviceImagePipe } from "src/app/pipes/deviceImagePipe";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { SocialShareService } from "src/app/services/plugins/social-share.service";
import { ClipboardService } from "src/app/services/plugins/clipboard.service";
import { HapticsService } from "src/app/services/plugins/haptics.service";
import { ApiService } from "src/app/services/api.service";
import { Router } from "@angular/router";
import { DeviceSettingsTabComponent } from "src/app/components/device-settings-tab/device-settings-tab.component";
@Component({
  selector: "app-device-setting",
  templateUrl: "./device-setting.component.html",
  styleUrls: ["./device-setting.component.scss"],
})
export class DeviceSettingComponent implements OnInit {
  @Input() id: number;
  @Input() privacy_mode: boolean;
  @Input() name: string;
  nameChanged: Subject<string> = new Subject<string>();
  @Input() color: string;
  @Input() iconusecustom: number;
  @Input() iconcustomimage: string;
  @Input() device_viewlink: boolean;
  @Input() iconname: string;
  @Input() user_id: number;
  @Input() url: string;
  @Input() showShareLink:boolean = true
  uploadedFile: any = null;
  shareLink = "";
  shareByMobile = false;
  device = {
    id: 0,
    iconusecustom: 0,
    iconname: "paj_iconset_auto5",
    iconcustomimage: "",
    threedModel_name: null,
    threedModel_color: null,
  };
  viewMode = null;
  iconUrl: any;
  shareSettings = {
    route_start_date: moment().endOf("day").unix(),
    route_end_date: moment().startOf("day").add(-1, "day").unix(),
    route_online_tracker: true,
    route_range: false,
    show_pauses: true,
    customer_id: this.appService.user.id
  };
  currentDevice: any;
  isFreeFinder: boolean = undefined;
  testUser: any;
  constructor(
    public modelCtrl: ModalController,
    private authService: AuthenticationService,
    public modalController: ModalController,
    private socialshare: SocialShareService,
    private storage: Storage,
    private toast: ToastController,
    public _translate: TranslateService,
    private popoverCtrl: PopoverController,
    public platform: Platform,
    private appService: AppService,
    private devicesService: DeviceDataService,
    private deviceService: DeviceManagmentService,
    private deviceImagePipe: DeviceImagePipe,
    private sanitizer: DomSanitizer,
    private hapticService: HapticsService,
    private apiService: ApiService,
    private copyService: ClipboardService,
    private router: Router
  ) {
    if (this.platform.is("ios") || this.platform.is("android")) {
      this.shareByMobile = true;
    }
    this.nameChanged
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((data) => {
        this.name = data;
        this.updateName();
      });
  }

  nameTextChanged(text: string) {
    this.nameChanged.next(text);
  }

  updateName() {
    if (this.name != "") {
      let data = {
        name: this.name,
      };

      //_ Update the device in devices service
      this.authService.updateDevice(this.currentDevice.id, data).then((r) => {
        this.currentDevice.properties.name = this.name;
        this.devicesService.updateDeviceInfo(this.currentDevice);
        this.settingUpdateSuccess();
      });
    }
  }

  ngOnInit() {
    this.currentDevice = this.devicesService.devices[this.id];
    if (this.currentDevice?.deviceModel.model_nr == ENV.FREE_FINDER) {
      this.isFreeFinder = true;
    } else {
      this.isFreeFinder = false;
    }
    this.getLink();

    this.testUser = this.appService.user.isTestAccount
  }

  async getLink() {
    this.shareLink = "";
    //this.device = { id: this.id, iconusecustom: this.iconusecustom, iconname: this.iconname, iconcustomimage: this.iconcustomimage };
    await this.authService
      .getShareLink(this.id, this.shareSettings)
      .then((response) => {
        if (response["success"]) {
          let res = response.success[0];

          if (!!res.route_start_date)
            this.shareSettings.route_start_date = res.route_start_date;

          if (!!res.route_end_date)
            this.shareSettings.route_end_date = res.route_end_date;

          this.shareSettings.route_online_tracker = res.route_online_tracker;
          this.shareSettings.route_range = res.route_range;
          this.shareSettings.show_pauses = res.show_pauses;
          this.shareSettings.customer_id = res.customer_id;
          this.shareLink = `${ENV.SHARE_URL}/stand-alone-page/viewmode_${res.iddevice}_${res.viewcheck}`;
          this.viewMode = res;
        } else {
          this.shareLink = "";
        }
      });
  }

  async regenerateLink() {
    await this.authService
      .getShareLink(this.id, this.shareSettings, 0)
      .then((response) => {
        this.getLink();
        this.appService.showToast(
          "",
          this._translate.instant(
            "deviceManagement.deviceSettings.linkGenerated"
          ),
          2000,
          "success"
        );
      });
  }
  deleteDevice() {
    this.apiService.deleteDevice(this.currentDevice.id).subscribe({
      next: async (data) => {
        // this.devicesService.devices= [...this.devicesService.devices.splice(this.id, 1)];
        this.devicesService.devices.splice(this.id, 1);
        await this.showToast(
          "",
          "Device deleted successfully",
          2000,
          "success"
        );
        await this.modalController.dismiss({deleted:true});
      },
      error: (e) => console.error(e),
      complete: () => console.info("complete"),
    });
  }

  async dismiss() {
    await this.modelCtrl.dismiss({
      dismissed: true,
      id: this.id,
      name: this.name,
      color: this.color,
      iconname: this.iconname,
      iconusecustom: this.iconusecustom,
      iconcustomimage: this.iconcustomimage,
      device_viewlink: this.device_viewlink ? true : false,
      threedModel_name: this.device.threedModel_name,
      threedModel_color: this.device.threedModel_color,
      privacy_mode: this.privacy_mode ? 1 : 0,
    });

    //this.showToast(this._translate.instant('deviceManagement.deviceSettings.deviceSettingsText'), this._translate.instant('deviceManagement.mapSettingMessage'), 3000, 'success');
  }

  myToast: any = null;
  async showToast(title, text, time, colorType) {
    if (this.myToast) {
      this.myToast.dismiss();
    }

    this.myToast = await this.toast.create({
      message: text,
      duration: time,
      color: colorType,
      position: "bottom",
      header: title,
      cssClass: "centerToast",
    });

    this.myToast.present();
  }

  async DeviceImg() {
    const modal = await this.modelCtrl.create({
      component: DeviceImgComponent,
      componentProps: {
        id: this.id,
        iconname: this.iconname,
        iconusecustom: this.iconusecustom,
        uploadedFile: this.uploadedFile,
        url: this.url,
        user_id: this.user_id,
        iconcustomimage: this.iconcustomimage,
        device: this.currentDevice,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (data) {
      if (data.iconname || data.iconcustomimage || data.threedObj) {
        this.iconname = data.iconname;
        this.iconusecustom = data.iconusecustom;
        this.iconcustomimage = data.iconcustomimage;
        this.device.iconname = data.iconname;
        this.device.iconusecustom = data.iconusecustom;
        this.device.iconcustomimage = data.iconcustomimage;

        this.currentDevice.properties.iconusecustom = data.iconusecustom;
        this.currentDevice.properties.iconname = data.iconname;
        this.currentDevice.properties.iconcustomimage = data.iconcustomimage;
        if (data.threedObj) {
          this.currentDevice.properties.threedModel_name = data.threedObj.name;
          this.device.threedModel_name = data.threedObj.name;
        }

        this.device = Object.assign({}, this.device);
        this.deviceImagePipe
          .transform(this.currentDevice.properties, this.user_id)
          .subscribe((iconUrl) => {
            this.iconUrl = this.sanitizer.bypassSecurityTrustUrl(iconUrl);
          });
        this.devicesService.updateDeviceInfo(this.currentDevice);
      }
    }
  }

  handleFileInput(files: FileList) {
    this.uploadedFile = files.item(0);
  }

  async updateDeviceCustImage() {}

  getColor(event) {
    this.color = event.target.value;
    this.storage.set(Constants.UPDATE_COLOR, 1);
    this.storage.set(Constants.UPDATE_MAP, 1);
    let data = {
      spurfarbe: this.color,
    };

    //_ Update the device in devices service
    this.authService.updateDevice(this.currentDevice.id, data).then((r) => {
      this.currentDevice.properties.spurfarbe = this.color;
      this.devicesService.updateDeviceInfo(this.currentDevice);
      this.settingUpdateSuccess();
    });
  }

  async copyLink(link, ev) {
    ev.preventDefault(); //Prevent call ionChange that setted automatic the value of selectedMenuItem
    ev.stopImmediatePropagation();
    this.copyService.copyToClipboard(link);
  }

  shareByMob() {
    this.socialshare.share("", "Share link", this.shareLink, null);
  }

  openLinkWeb(type) {
    switch (type) {
      case "simple":
        window.open(this.shareLink, "_blank");
        break;
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${this.shareLink}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `http://twitter.com/intent/tweet?text=Please Follow me&url=${this.shareLink}`,
          "_blank"
        );
        break;
      case "g-mail":
        window.open(
          `mailto:?subject=My current Location&body=link: ${this.shareLink}`,
          "_blank"
        );
        break;
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=%20${this.shareLink}`,
          "_blank"
        );
        break;
      default:
        break;
    }
  }

  privacyClicked = false;
  async enableDisablePrivacy(event) {
    if (!this.privacyClicked) return;
    // this.privacy_mode = event;
    let data = {
      privacy_mode: this.privacy_mode ? 1 : 0,
    };

    this.privacyClicked = false;
    //_ Update the device in devices service
    this.authService.updateDevice(this.currentDevice.id, data).then((r) => {
      this.currentDevice.properties.privacy_mode = this.privacy_mode;
      this.devicesService.updateDeviceInfo(this.currentDevice);
      this.settingUpdateSuccess();
    });
  }

  async enableDisablePrivacyViewlink(event) {
    this.device_viewlink = event;
    let data = {
      share_link: this.device_viewlink ? 1 : 0,
    };

    //_ Update the device in devices service
    this.authService.updateDevice(this.currentDevice.id, data).then((r) => {
      this.currentDevice.properties.share_link = this.device_viewlink;
      this.devicesService.updateDeviceInfo(this.currentDevice);
      this.settingUpdateSuccess();
    });

    if (this.device_viewlink) {
      this.getLink();
    }
  }

  async openInfo(ev, index) {
    const infoHeader = [
      this._translate.instant("deviceManagement.deviceSettings.sharingText"),
      this._translate.instant("deviceManagement.deviceSettings.privacyMode"),
    ];
    const infoMessage = [
      this._translate.instant(
        "deviceManagement.deviceSettings.sharingTooltipText"
      ),
      this._translate.instant(
        "deviceManagement.deviceSettings.privacyModeText"
      ),
    ];

    let popup = await this.popoverCtrl.create({
      componentProps: {
        title: infoHeader[index],
        description: infoMessage[index],
        image: "assets/images/popover/info2.png",
      },
      component: CsPopoverComponent,
      event: ev,
      mode: "ios",
    });

    await popup.present();
    popup = null;
  }

  async selectDate() {
    const modal = await this.modalController.create({
      component: DateRangeComponent,
      componentProps: {
        startDate: moment
          .unix(this.shareSettings.route_start_date)
          .toISOString(),
        endDate: moment.unix(this.shareSettings.route_end_date).toISOString(),
        deviceId: this.currentDevice.id,
        showFutureDate:true
      },
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      if (
        this.shareSettings.route_start_date != data.startDate ||
        this.shareSettings.route_end_date != data.endDate
      )
        this.shareSettings.route_start_date = moment(data.startDate).unix();
      this.shareSettings.route_end_date = moment(data.endDate).unix();
      this.updateShareLink();
    }
  }

  updateShareLink() {
    setTimeout(() => {
      if (this.viewMode) {
        this.authService
          .updateShareLink(this.viewMode.viewcheck, this.shareSettings)
          .then((r) => {
            this.appService.showToast(
              "",
              this._translate.instant(
                "deviceManagement.deviceSettings.shareLinkUpdateMessage"
              ),
              2000,
              "success"
            );
          })
          .catch((e) => {
            this.appService.showToast(
              "",
              this._translate.instant(
                "deviceManagement.deviceSettings.shareLinkError"
              ),
              3000,
              "danger"
            );
          });
      }
    }, 100);
  }

  settingUpdateSuccess() {
    this.appService.showToast(
      this._translate.instant(
        "deviceManagement.deviceSettings.deviceSettingsText"
      ),
      this._translate.instant("deviceManagement.mapSettingMessage"),
      2000,
      "success"
    );
  }
  setVibration() {
    this.privacyClicked = true;
    if (this.privacy_mode == false) {
      this.hapticService.vibrate(300);
    }
  }
}
