<ion-header [translucent]="true">
  <ion-toolbar color="transparent">
    <ion-buttons slot="start">
      <ion-back-button
        style="display: block !important"
        back-close
        back-locale
        >
        </ion-back-button>
      </ion-buttons>

      <ion-title>{{ 'devicesSetup.devicePosition.trackingSetting' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="ion-padding" scrollY="false">
  <div class="content no-scrollbars">
    <ion-list class="ion-no-padding">
      <ng-container [ngTemplateOutlet]="deviceLocation ? LAST_LOCATION_EXIST : NO_LAST_LOCATION"></ng-container>
    </ion-list>
  </div>
</ion-content>
<div class="ion-text-center" [ngClass]="{ 'finish-button': deviceLocation }">
  <ion-button class="ion-no-shadow" shape="round" color="primary" (click)="dismiss()">
    <ion-label>{{ 'devicesSetup.devicePosition.finish' | translate }}</ion-label>
  </ion-button>
</div>

<ng-template #NO_LAST_LOCATION>
  <div class="no-location" >
    <h2 style="color: var(--ion-color-primary)">
      {{ 'deviceManagement.deviceName' | translate }} {{ 'devicesSetup.devicePosition.noLocationDetected' | translate }}
    </h2>
    <ion-label color="primary">{{ 'devicesSetup.devicePosition.followInstructions' | translate }}</ion-label>
    <!-- <div class="loading" >
      <ion-icon src="assets/images/devices-setup/no-location.svg" color="danger" id="no-location-div" *ngIf="!deviceLocation && !loadingLasposition" @zoomIn></ion-icon>
      <ion-spinner color="primary" *ngIf="loadingLasposition && !deviceLocation"></ion-spinner>
    </div> -->
  </div>

  <ion-list lines="none" class="instructions-list">
    <ion-item class="instructions-item" detail="false">
      <ion-icon slot="start" src="assets/images/logbook/power-off.svg" color="primary"></ion-icon>
      <ion-label class="ion-text-wrap">{{ 'devicesSetup.devicePosition.deviceTurnOn' | translate }}</ion-label>
    </ion-item>

    <ion-item class="instructions-item" detail="false">
      <ion-icon slot="start" src="assets/images/Alert_Battery(1).svg" color="primary"></ion-icon>
      <ion-label class="ion-text-wrap">{{ 'devicesSetup.devicePosition.batteryCharged' | translate }}</ion-label>
    </ion-item>

    <ion-item class="instructions-item" detail="false">
      <ion-icon slot="start" src="assets/images/devices-setup/information.svg" color="primary"></ion-icon>
      <ion-label class="ion-text-wrap">{{ 'devicesSetup.devicePosition.checkUserManual' | translate }} <span style="cursor:pointer;" (click)="openLink(userManualLink)">({{ 'devicesSetup.devicePosition.seeDeviceManual' | translate }})</span></ion-label>      
    </ion-item> 

    <ion-item class="instructions-item" detail="false">
      <ion-icon slot="start" src="assets/images/devices-setup/motion.svg" color="primary"></ion-icon>
      <ion-label class="ion-text-wrap">{{ 'devicesSetup.devicePosition.walkOutside' | translate }}</ion-label>
    </ion-item>

    <ion-item class="instructions-item" detail="false" (click)="openInWhatsapp()">
      <ion-icon slot="start" src="assets/icons/whatsapp.svg" color="primary" style="cursor: pointer;"></ion-icon>
      <ion-label class="ion-text-wrap" style="cursor: pointer;">{{ 'devicesSetup.devicePosition.openWhatsappLink' | translate }}</ion-label>
    </ion-item>
  </ion-list>

  <div class="loading" >
    <ion-spinner color="light"></ion-spinner>
    <!-- <ion-spinner color="primary" *ngIf="loadingLasposition && !deviceLocation"></ion-spinner> -->
    <ion-img class="image-header" src="assets/images/devices-setup/not-found.png"></ion-img>
  </div>

  <!-- <div class="ion-text-center" style="margin-top: 0px;">
    <ion-button class="ion-no-shadow" shape="round" color="primary" (click)="dismiss()">
      <ion-label>{{ 'devicesSetup.devicePosition.finish' | translate }}</ion-label>
    </ion-button>
  </div> -->
</ng-template>

<ng-template #LAST_LOCATION_EXIST>
  <div class="ion-text-center device-ready">
    <ion-img class="tracking-image" src="assets/images/devices-setup/live-track.png"></ion-img>
    <h4>{{ 'devicesSetup.devicePosition.deviceIsReady' | translate : { deviceName: deviceName, deviceModel: deviceModel} }}</h4>    
    <ion-icon name="checkmark-outline" class="device-ready-check" @zoomIn></ion-icon>
  </div>


  <div class="map-container">
    <app-map-min height="250px" width="100%" #setup_device_map [center]="[deviceLocation?.lng, deviceLocation.lat]" [zoom]="9"> </app-map-min>
  </div>

  <!-- <div class="ion-text-center" style="margin-top: 15px;">
    <ion-button class="ion-no-shadow" shape="round" color="primary" (click)="dismiss()">
      <ion-label>{{ 'devicesSetup.devicePosition.finish' | translate }}</ion-label>
    </ion-button>
  </div> -->
</ng-template>
