import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http";
import { StorageService as Storage } from "./storage.service";
import { Observable, from } from "rxjs";
//import "rxjs/add/observable/fromPromise";
import { throwError } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { Constants } from "../constants.enum";
import { environment as ENV } from 'src/environments/environment';
import { StartupService } from "./startup.service";
import * as CryptoJS from 'crypto-js';
import { App, AppInfo } from '@capacitor/app';
import { AppInfoService } from "./plugins/app-info.service";

@Injectable({
  providedIn: "root",
})
export class InterceptorService {
  loaderToShow: any;
  routesToUncheck401 = [ENV.API_VERSION + "media/", "_media/", "assets/device_markers"];
  routesWithoutToken = ['maps.paj-gps.de', ENV.COUNTLY_URL];
  constructor(
    private storage: Storage,
    private authenticationService: AuthenticationService,
    private startupService: StartupService,
    private appInfo: AppInfoService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const promise = this.storage.getFromLocalOrSession(Constants.TOKEN_KEY);
    const appVersion = this.getAppVersion();

    return from(Promise.all([promise, appVersion])).pipe(
      mergeMap(([token, appVersion]) => {
        let clonedReq = this.addToken(request, token);
        clonedReq = this.addAppVersion(clonedReq, appVersion);

        return next.handle(clonedReq).pipe(
          // map((event: HttpEvent<any>) => {
          //   if (event instanceof HttpResponse) {
          //     //_ If it's a successful response, decrypt the response body
          //     event = event.clone({
          //       body: this.decryptResponse(event.body, token) // Decrypt here
          //     });
          //   }
          //   return event;
          // }),

          catchError((error) => {
            // Perhaps display an error for specific status codes here already?
            const msg = error.error.error;
            this.startupService.hideLoadingScreen$.next(true);

            if (error.status === 429) {
              console.log("ERROR: ", 'Too many requests');
              return throwError(error);
            }
            if (error.status === 0 || error.status === 500) {
              console.log("ERROR: ", 'Internal server error or no internet connection');
              return throwError(error);
            }

            if (error.status === 401 && !this.uncheck401Error(request.url)) {
              console.log("ERROR: ", 'REQUEST 401 INTERCEPTOR');
              this.requestNewToken();
              return
            }
            return throwError(error);
          })
        );
      })
    );
  }

  requestNewToken() {
    this.storage.get("userData").then(async (res) => {
      const refresh_token = await this.storage.get(Constants.REFRESH_TOKEN_KEY);
      //_ Check if refresh_token exists, in old logged users they doesnt have this data in storage
      if (refresh_token && res && refresh_token != null) {
        console.log("calling refresh token");
        let data = { email: res.email, refresh_token: refresh_token };
        this.authenticationService.refreshToken(data).then( (res) => {
          if(!res.success){
            this.authenticationService.logout();
          }
        });
      }
      else {
        //_ If user doesnt has refresh_token, clean storage calling logout
        //_ And restart authentication flow calling next(false) in logout method
        this.authenticationService.logout();
      }
    });
  }

  // Adds the token to your headers if it exists
  private addToken(request: HttpRequest<any>, token: any) {
    if (request.url.endsWith('updatetoken') || this.routesWithoutToken.some(url => request.url.includes(url))) {
      return request;
    }
    if (token) {
      let clone: HttpRequest<any>;
      const shouldSkipCacheHeaders = request.url.includes('_media');

      const headers = {
        "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Max-Age": "3600",
        'X-Request-Date': new Date().toUTCString(),
        Authorization: `Bearer ${token}`
      };

      if (!shouldSkipCacheHeaders) {
        headers["Cache-Control"] = "no-cache, no-store";
        headers["Pragma"] = "no-cache";
      }

      clone = request.clone({
        setHeaders: headers
      });

      return clone;
    }
    return request;
  }

  private uncheck401Error(url) {
    let exist = false;
    for (let route of this.routesToUncheck401) {
      if (url.includes(route)) {
        exist = true;
        break;
      }
    }
    return exist;
  }

  private decryptResponse(encryptedString: any, key: string): any {
    console.log('Encrypted String', { encryptedString })
    encryptedString = encryptedString.original.encrypted_data;
    try {
      // Split the encrypted data and IV using "::"
      const [encryptedDataBase64, ivBase64] = encryptedString.split('::');

      // Decode the Base64 data into a WordArray
      const encryptedData = CryptoJS.enc.Base64.parse(encryptedDataBase64);
      const iv = CryptoJS.enc.Base64.parse(ivBase64);

      // Decrypt the data using AES-256-CBC
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedData }, CryptoJS.enc.Utf8.parse(key), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      console.log('DECRYPTED TEXT 1', { decrypted })

      // Convert the decrypted data back to UTF-8 and parse as JSON
      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

      console.log('DECRYPTED TEXT 2', { decryptedText })
      // If decryption was successful, the data will be a JSON string that needs to be parsed
      return JSON.parse(decryptedText);
    } catch (error) {
      console.error("Decryption error: ", error);
      throw new Error("Decryption failed.");
    }
  }

  // Method to retrieve app version using Capacitor
  private async getAppVersion(): Promise<string> {
    return this.appInfo.getVersion();
  }

  // Method to add app version to the request headers
  private addAppVersion(request: HttpRequest<any>, version: string): HttpRequest<any> {
    if (!request.url.includes(ENV.ROUTE))
      return request;

    return request.clone({
      headers: request.headers.append('X-App-Version', version)
    });
  }

}
