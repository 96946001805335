import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { BadgeService } from './badge.service';

//via @capacitor-community/fcm
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
import { AppService } from 'src/app/app.service';

//via @capacitor-firebase/messaging
/*
import {
  FirebaseMessaging,
  GetTokenOptions,
} from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import { environment } from "src/environments/environment";
*/

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  isMobileApp = false;
  token: string;
  constructor(
    private authService: AuthenticationService,
    private badgeService: BadgeService,
    private appService: AppService,
  ) {
    this.isMobileApp =
      Capacitor.isNativePlatform() &&
      (Capacitor.getPlatform() == 'ios' ||
        Capacitor.getPlatform() == 'android');
    this.appService.user$.subscribe((user: any) => {
      console.log('user', user);
      if (this.token) {
        this.authService.registerFBToken(this.token);
      }
    });
  }

  //via @capacitor-community/fcm
  async initPushNotifications(notificationsService) {
    if (!this.isMobileApp) return;
    console.log('initNotitifications');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // Get FCM token instead the APN one returned by Capacitor
    PushNotifications.addListener('registration', async (token: Token) => {
      console.log('Push registration success, token: ' + token.value);
      this.token = token.value;
      // await this.authService.registerFBToken(token.value);
    });
    PushNotifications.addListener('registrationError', async (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
      this.token = await this.getFcmToken();
      // await this.authService.registerFBToken(await this.getFcmToken());
    });
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        notificationsService.notifyNew.next(notification);
        this.badgeService.increaseBadge();
      },
    );
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      },
    );
  }

  getFcmToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.isMobileApp) reject('FCM Plugin is NOT SUPPORTED ON BROWSER');
      FCM.getToken()
        .then((r) => {
          resolve(r.token);
          console.log('token', r.token);
        })
        .catch((error) => {
          console.log('Error getting Token', error);
          reject(error);
        });
    });
  }
  deleteFcm() {
    FCM.deleteInstance();
  }
  createChannel(obj) {
    return new Promise((resolve, reject) => {
      if (!this.isMobileApp) reject('FCM Plugin is NOT SUPPORTED ON BROWSER');
      PushNotifications.createChannel(obj)
        .then((r) => {
          resolve(r);
          console.log('createChannel', r);
        })
        .catch((error) => {
          console.log('Error  create Channel', error);
          reject(error);
        });
    });
  }
}
