<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-back-button
        (click)="dismiss()"
        style="display: block !important"
        back-close
        back-locale
      >
      </ion-back-button>
    </ion-buttons>
    <ion-title
      >{{ "deviceManagement.deviceSettings.deviceSettingsText" | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <!-- <ion-card>
    <ion-card-content>
      <ion-list lines="none">
        <div class="custom-field">
          <ion-label
            >{{ "deviceManagement.deviceSettings.deviceNameText" | translate
            }}<ion-text color="danger">*</ion-text>
          </ion-label>
          <ion-item class="item-field">
            <ion-input
              required
              [placeholder]="
                _translate.instant(
                  'deviceManagement.deviceSettings.deviceNamePlaceholderText'
                )
              "
              [(ngModel)]="name"
              (ngModelChange)="nameTextChanged($event)"
              maxlength="50"
            ></ion-input>
          </ion-item>
        </div>
        <ion-item lines="none">
          <ion-icon
            slot="start"
            src="../../assets/images/Welt und Ortung.svg"
          ></ion-icon>
          <ion-label class="ion-text-wrap flex">
            <ion-text>
            {{ "deviceManagement.finderText" | translate }}:
            </ion-text>
            <ion-text>
              {{ currentDevice?.properties.imei }}
            </ion-text>
          </ion-label>
        </ion-item>

        <ion-item class="widthAuto" style="z-index: 9999">
          <ion-label>{{
            "deviceManagement.deviceSettings.colorText" | translate
          }}</ion-label>

          <input
          type="color"
          class="color-box pointer"
          (input)="getColor($event)"
          [value]="color"
          [style.background-color]="color"
          >
        </ion-item>
        <ion-item>
          <ion-label>
            {{ "deviceManagement.deviceSettings.deviceImgText" | translate }}
            <ion-avatar style="--border-radius: 0" slot="start">
              <img
                class="deafultIcon profile"
                [src]="iconUrl"
                style="object-fit: contain"
                *ngIf="iconUrl"
              />
              <ion-img
                *ngIf="!iconUrl"
                style="object-fit: contain"
                [src]="currentDevice.properties | DeviceImage : user_id | async"
              >
              </ion-img>

            </ion-avatar>
          </ion-label>
          <ion-button
            class="cameraPhoto"
            expand="block"
            style="--border-radius: 8px; text-transform: none"
            (click)="DeviceImg()"
          >
            <ion-icon class="flaticon-photo-camera"></ion-icon>
            {{ "deviceManagement.deviceSettings.changePhotoText" | translate }}
          </ion-button>
        </ion-item>

        <h2>
          <b>{{ "deviceManagement.deviceSettings.privacy_header" | translate }}</b>
        </h2>
        <ion-item>
          <ion-label
            >{{ "deviceManagement.deviceSettings.nonTrackingMode" | translate
            }}<i
              (click)="openInfo($event, 1)"
              class="flaticon-information-2 pointer toolTipColor"
            ></i
          ></ion-label>
          <ion-toggle
            slot="end"
            mode="ios"
            class="ion-custom"
            color="success"
            (click)="setVibration()"
            [(ngModel)]="privacy_mode"
            (ngModelChange)="enableDisablePrivacy($event)"
            [checked]="privacy_mode"
            aria-label=""
          ></ion-toggle>
        </ion-item>

        <ion-card style="border: 0">
          <ion-card-content>
            <device-sharelink-options [deviceId]="id"></device-sharelink-options>
          </ion-card-content>
        </ion-card>


        <ion-grid *ngIf="isFreeFinder && !testUser">
          <ion-row>
            <ion-col size="12" class="ion-text-center">
              <ion-button (click)="deleteDevice()" mode="ios" color="danger">
                {{
                  "deviceManagement.deviceSettings.deleteDevice" | translate
                }}
              </ion-button>
            </ion-col>
            <ion-col size="6"></ion-col>
          </ion-row>
        </ion-grid>
      </ion-list>
    </ion-card-content>
  </ion-card> -->
  <app-device-settings-tab
    [id]="currentDevice.properties.id"
    [privacy_mode]="currentDevice.properties.privacy_mode"
    [name]="currentDevice.properties.name"
    [color]="currentDevice.properties.spurfarbe"
    [iconusecustom]="currentDevice.properties.iconusecustom"
    [iconcustomimage]="currentDevice.properties.iconcustomimage"
    [device_viewlink]="currentDevice.properties.share_link"
    [iconname]="currentDevice.properties.iconname"
    [user_id]="user_id"
    [url]="url"
    [showShareLink]="showShareLink"
  >
  </app-device-settings-tab>
</ion-content>
