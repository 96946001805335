<app-alert
  class="deviceInfo"
  [deviceID]="deviceId"
  [data]="deviceListService.deviceData"
  [map]="map"
  [deviceMarkers]="[]"
  [showAlertsSection]="false"
  [hideGeofencesFeature]="false"
  (onAlarmChange)="alarmChange($event)"
  toastPosition="bottom"
></app-alert>
