<div isInViewport (inView)="isInViewport()" (outView)="outOfViewport()" class="content">
  <ion-toolbar color="transparent" class="ion-no-padding">
    <!-- <ion-item slot="start" color="transparent" class="header-device-item" lines="none" button detail="false"> -->
    <ion-item slot="start" color="transparent" class="header-device-item" lines="none" detail="false">
      <ion-img
        src="{{ device | DeviceImage : userId | async }}"
        slot="start"
        *ngIf="device"
        @zoomIn
      ></ion-img>
      <ion-label>
        <h2 class="device-name">
          {{ device?.name  }}
          <p>{{ device?.imei }}</p>
        </h2>
      </ion-label>
      <!-- <ion-icon name="chevron-down-outline" slot="end"></ion-icon> -->
    </ion-item>
  </ion-toolbar>

  <ion-row>
    <ion-img src="{{ mapPreview | async }}" class="map-preview" #img></ion-img>
    <ion-spinner *ngIf="routePoints" name="dots" color="primary" class="loading-spinner" [hidden]="img?.src != whitePicture"></ion-spinner>
    <ion-badge class="distance-badge"  [class.loader]="loadingDistance">
      <ion-label class="ion-text-center ion-text-wrap">{{ (distance ? distance : 0) | KmtoMiles : '1.1-1'}}</ion-label>
    </ion-badge>

    <!-- <ion-fab-button color="light" class="show-route-fab" size="small" (click)="openRouteInMainMap()" title="Show route in main map.">
      <ion-icon src="assets/images/map/device-dashboard/maximize.svg" color="primary"></ion-icon>
    </ion-fab-button> -->

    <a href="javascript:void(0)" class="show-route-fab ion-fab-button"
      style="color: light; display: inline-flex; align-items: center; justify-content: center; border-radius: 50%; width: 40px; height: 40px; background-color: #f4f4f4;"
      (click)="openRouteInMainMap()" title="Show route in main map.">
      <ion-icon src="assets/images/map/device-dashboard/maximize.svg" color="primary" style="cursor: pointer"></ion-icon>
    </a>

    <!-- <div class="distance-badge" [class.loader]="loadingDistance">
      <ion-badge>{{ distance | KmtoMiles }}</ion-badge>
    </div> -->
    <!-- Route Information -->
    <ion-card class="route-card ion-no-padding">
      <!-- <ion-fab-button color="light" class="maximize-button" size="small" (click)="openRouteInMainMap()" title="Show route in main map.">
        <ion-icon src="assets/images/map/device-dashboard/maximize.svg" color="primary"></ion-icon>
      </ion-fab-button> -->
      <ion-card-content class="ion-no-padding">
        <!-- <ion-badge color="light">{{ route?.end_dateunix | translateUnixDate : "dddd DD MMMM, YYYY" }}</ion-badge> <br /> -->
        <ion-item lines="none" class="ion-no-padding route-info">
          <ion-icon slot="start" src="assets/images/logbook/arrow-up.svg" color="primary"></ion-icon>
          <ion-label>
            <p class="text-no-wrap" [title]="route?.end_address"><b>{{ route?.endID | translateUnixDate : "HH:mm" }}</b> {{ route?.end_address }}</p>
            <p class="text-no-wrap" [title]="route?.start_address"><b>{{ route?.startID | translateUnixDate : "HH:mm" }}</b> {{ route?.start_address }}</p>
          </ion-label>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </ion-row>
</div>
