<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="ion-hide-md-up">
      <ion-back-button
        (click)="close()"
        style="display: block !important"
        back-close
        back-locale
      ></ion-back-button>
    </ion-buttons>

    <ion-title class="ion-hide-md-down ion-text-left"
      >{{"notifications.details.notificationDetailText"|translate}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="setReadStatus2(notification, 0)" [ngStyle]="{ '--background': !notification?.isread ? 'rgba(255,255,255,0.1)' : '' }" shape="round">
        <ion-icon src="assets/images/notifications/uncheck.svg"></ion-icon
        >&nbsp;
        <ion-label> {{ 'notifications.unreadText' | translate }} </ion-label>
      </ion-button>
      <ion-button class="icon-color" (click)="setReadStatus2(notification, 1)" [ngStyle]="{ '--background': notification?.isread ? 'rgba(255,255,255,0.1)' : '' }" shape="round">
        <ion-icon src="assets/images/notifications/check.svg"></ion-icon>&nbsp;
        {{ 'notifications.readText' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-map-min
    #map
    id="mini-map"
    [height]="'250px'"
    [pitch]="70"
    mapStyle="paj"
    [useDefinedStyle]="true"
    [center]="[notification?.lng, notification?.lat]"
    [zoom]="16"
    *ngIf="isValidLatLng"
  ></app-map-min>

  <ion-card *ngIf="!isValidLatLng" class="box">
    <ion-item lines="none" detail="false">
      <ion-icon
        color="warning"
        src="assets/images/sidebar/warning.svg"
        slot="start"
      ></ion-icon>
      <h3 class="ion-text-center ion-text-wrap">
        {{ 'notifications.noLocation' | translate}}
      </h3>
    </ion-item>
  </ion-card>
  <!-- MINI MAP  -->
  <ion-card-header color="light" stick>
    <ion-item lines="none">
      <ion-avatar style="--border-radius: 0" slot="start">
        <ion-img
          src="{{ device | DeviceImage:userId | async }}"
          style="object-fit: contain"
        ></ion-img>
      </ion-avatar>
      <ion-label class="ion-text-wrap">
        {{device?.name}}
        <p>{{device?.imei}}</p>
      </ion-label>

      <!-- <ion-icon
        slot="end"
        [color]="notification?.isread? 'success':''"
        [name]="notification?.isread?'checkmark-circle-outline': 'radio-button-off-outline'"
      ></ion-icon> -->
    </ion-item>
  </ion-card-header>

  <ion-card-content>
    <ion-list lines="none">
      <ion-list-header>
        <ion-label>{{"notifications.details.detailsText"|translate}}</ion-label>
      </ion-list-header>
      <ion-item lines="none">
        <ion-label class="title" color="primary"
          >{{ notification?.bezeichnung |translate}}</ion-label
        >
      </ion-item>
      <ion-item class="ion-text-wrap" lines="none">
        <ion-label
          >{{"notifications.details.descriptionText"|translate}}
          <p class="ion-text-wrap" *ngIf="notification?.meldungtyp != 5 && notification?.meldungtyp != 10 && notification?.meldungtyp != 11">
            {{notification?.meldung | translate}}
          </p>
          <p
          class="ion-text-wrap"
          *ngIf="notification.meldungtyp == 5"
          [translateParams]="{ km: notification?.speederlaubt |  KmtoMiles: '1.0-0', kmAlert: notification?.speed |  KmtoMiles: '1.0-0' }"
          [translate]="notification?.meldung"
        ></p>
          <!-- <p
            class="ion-text-wrap"
            *ngIf="notification?.meldungtyp == 5"
            [translateParams]="{ km: notification?.speederlaubt +' '  +  speed_unit, kmAlert: notification?.speed  + ' ' + speed_unit }"
            [translate]="notification?.meldung"
          ></p> -->
          <p
            class="ion-text-wrap"
            *ngIf="notification.meldungtyp == 10 || notification.meldungtyp == 11"
            [translateParams]="{ GeofenceName: notification?.name ?? ' ' }"
            [translate]="notification?.meldung"
          ></p>
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label>{{"notifications.details.dateText"|translate}}</ion-label>
        <ion-badge
          >{{ notification?.dateunix | humanizeTime:'date':'D MMMM YYYY,HH:mm:ss' }}</ion-badge
        >
        <!-- <ion-badge
          >{{ notification?.dateunix * 1000 | date:'dd.MM.yyyy HH:mm' }}</ion-badge
        > -->
      </ion-item>

      <div *ngIf="notification?.meldungtyp == 5">
        <ion-list-header>
          <ion-label>{{"notifications.details.speedText"|translate}}</ion-label>
        </ion-list-header>
        <ion-item lines="none">
          <ion-icon
            slot="start"
            src="../../assets/images/speedometer.svg"
          ></ion-icon>
          <ion-label
            >{{"notifications.details.permittedSpeedText"|translate}}</ion-label
          >
          <ion-badge>
            {{ notification?.speederlaubt |KmtoMiles:'1.0-0' }}/h</ion-badge
          >
        </ion-item>
        <ion-item lines="none">
          <ion-icon
            slot="start"
            src="../../assets/images/registeredSpeed.svg"
          ></ion-icon>
          <ion-label
            >{{"notifications.details.registerSpeedText"|translate}}</ion-label
          >
          <ion-badge> {{ notification?.speed | KmtoMiles:'1.0-0' }}/h</ion-badge>
        </ion-item>
      </div>

      <ion-list-header>
        <ion-label
          >{{"notifications.details.locationText"|translate}}</ion-label
        >
      </ion-list-header>
      <ion-item
        *ngIf="location?.address?.road || location?.address?.house_number"
      >
        <ion-icon slot="start" src="../../assets/images/street.svg"></ion-icon>
        <ion-label style="padding-left: 5px"
          >{{ location?.address?.road }} {{ location?.address?.house_number
          }}</ion-label
        >
      </ion-item>
      <ion-item
        *ngIf="location?.address?.postcode||location?.address?.city||location?.address?.neighbourhood"
      >
        <ion-icon slot="start" src="assets/images/postcode_city.svg"></ion-icon>
        <ion-label style="padding-left: 5px"
          >{{ location?.address?.postcode }} {{ location?.address?.city }} {{
          location?.address?.neighbourhood }}</ion-label
        >
      </ion-item>

      <ion-item *ngIf="location?.address?.country || location?.address?.conty">
        <ion-icon slot="start" src="assets/images/Country_world.svg"></ion-icon>
        <ion-label style="padding-left: 5px"
          >{{ location?.address?.country }}
          <span *ngIf="location?.address?.county">({{ location?.address?.county }})</span>
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-icon slot="start" src="assets/images/Google-MapsV2.svg"></ion-icon>
        <ion-label style="cursor: pointer;">
          <a
            (click)="openMap(notification.lat,notification.lng)"
            *ngIf="notification?.lat && notification?.lng"
          >
            <!-- <a  href="https://www.google.com/maps/place/{{notification?.lat}},{{notification?.lng}}" *ngIf="notification?.lat && notification?.lng"> -->
            Google Maps
          </a>
        </ion-label>
        <ion-label *ngIf="!notification?.lat || !notification?.lng">
          {{"notifications.details.noGpsData"|translate}}
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card-content>
</ion-content>
