import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaneService {
  public breakpointSubject = new BehaviorSubject<string | null>(null);
  breakpoint$ = this.breakpointSubject.asObservable(); // Expose the observable
  enableScroll: boolean = false;
  constructor() {
    this.listenForBreakpointChanges();
  }
  listenForBreakpointChanges() {
    console.log('listenForBreakpointChanges');
    this.breakpoint$.subscribe((breakpoint) => {
      if (breakpoint === 'top') {
        console.log('Current breakpoint:', breakpoint);
        this.enableScroll = true;
        // Perform any additional actions based on the breakpoint change
      } else {
        console.log('Pane dismissed or no active breakpoint.');
        this.enableScroll = false;
      }
    });
  }
}
