<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-back-button
        (click)="close()"
        style="display: block !important"
        back-close
        back-locale
        [disableClose]="showBackButton"
      ></ion-back-button>
    </ion-buttons>
    <ion-title>{{
      "deviceManagement.deviceSettings.uploadedImages" | translate
    }}</ion-title>
  </ion-toolbar>

  <ion-toolbar color="light" class="buttons-toolbar">
    <div slot="start">
      <ion-button
        color="light"
        [hidden]="enableSelection"
        (click)="fileinput.click()"
      >
        <ion-icon name="cloud-upload" slot="start"></ion-icon>
        <ion-label>{{
          "deviceManagement.deviceSettings.uploadText" | translate
        }}</ion-label>
        <input
          type="file"
          multiple
          style="display: none"
          accept="image/*"
          (change)="cutAndFrameStep($event)"
          onclick="this.value = null"
          #fileinput
        />
      </ion-button>

      <ion-button
        color="danger"
        [disabled]="selectedItems == 0 || loading.delete"
        (click)="deleteImages()"
      >
        <ion-icon name="trash" slot="start"></ion-icon>
        <ion-label
          >{{ "deviceManagement.deviceSettings.deleteText" | translate }}
          {{ selectedItems ? selectedItems : "" }}</ion-label
        >
      </ion-button>
    </div>

    <ion-button
      slot="end"
      (click)="setSelection()"
      fill="clear"
      [color]="enableSelection ? 'primary' : 'light'"
      [hidden]="media?.length == 0"
    >
      <ion-icon name="checkmark" slot="start"></ion-icon>
      <ion-label>{{
        "deviceManagement.deviceSettings.selectionText" | translate
      }}</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- NO MEDIA GALLERY SECTION -->
  <ion-item
    color="transparent"
    lines="none"
    *ngIf="media?.length == 0 && !loading.upload"
  >
    <div class="ion-text-center" class="no-media">
      <ion-icon
        class="img"
        src="assets/images/media-gallery/images.svg"
      ></ion-icon>
      <br />
      <ion-label class="ion-text-center">{{
        "deviceManagement.deviceSettings.zeroUploadedImagesText" | translate
      }}</ion-label>
      <ion-button color="primary" (click)="fileinput.click()" size="normal" style="text-transform: none;">
        <ion-icon name="cloud-upload" slot="start"></ion-icon>
        {{ "deviceManagement.deviceSettings.addImageText" | translate }}
      </ion-button>
    </div>
  </ion-item>

  <ion-grid>
    <ion-row>
      <!-- UPLOADING SECTION -->
      <ion-col size="12" *ngIf="progressInfo.length > 0 && loading.upload">
        <ion-item-divider>{{
          "deviceManagement.deviceSettings.uploadingText" | translate
        }}</ion-item-divider>
        <ion-row>
          <ion-col
            *ngFor="let info of progressInfo"
            class="mb-2"
            [hidden]="info?.value > 99"
            style="flex-wrap: wrap"
          >
            <div class="uploading-item">
              <ion-img [src]="info?.image | async"> </ion-img>
              <ion-progress-bar [value]="info?.value / 100"></ion-progress-bar>
              <ion-note
                color="primary"
                *ngIf="info?.compressing"
                style="font-size: 10px"
                >Compressing ...</ion-note
              >
              <ion-note color="danger" *ngIf="info?.error">{{
                "deviceManagement.deviceSettings.errorTryingToUploadImage"
                  | translate
              }}</ion-note>
              <ion-button
                *ngIf="info?.error"
                size="small"
                expand="block"
                (click)="upload(info?.id, info?.file)"
              >
                {{ "deviceManagement.deviceSettings.tryAgainText" | translate }}
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
        <ion-item-divider></ion-item-divider>
      </ion-col>

      <!-- GALLERY ITEM SECTTION -->
      <div style="display: flex;justify-content: center;align-items: center;height: 200px;width: 100%;" *ngIf="this.loadingMedia">
        <ion-spinner color="primary"></ion-spinner>
      </div>

      <ion-col
        size-md="3"
        size-xs="4"
        *ngFor="let img of media"
        class="media-item"
      >
        <ion-item
         (click)="selectItem($event, img)"
          mode="md"
          lines="none"
          button
          [class.skeleton-item]="image.src == whitePicture"
          class="ion-no-padding"
        >
          <ion-checkbox
            color="primary"
            [checked]="img.isSelected"
            [hidden]="!enableSelection"
            class="checkBoxStyling"
          ></ion-checkbox>
          <ion-img
            #image
            [src]="img.id | secureMedia | async"
            ionImgDidLoad="img.src = true"
            (ionError)="errorImage(image)"
          >
          </ion-img>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-item-divider *ngIf="companyMedia?.length>0">
      <ion-label>{{ "deviceManagement.deviceSettings.companyImages" | translate }}</ion-label>
    </ion-item-divider>
    <ion-row>
      <ion-col
        size-md="3"
        size-xs="4"
        *ngFor="let img of companyMedia"
        class="media-item"
      >
        <ion-item
         (click)="selectItem($event, img)"
          mode="md"
          lines="none"
          button
          [class.skeleton-item]="image.src == whitePicture"
          class="ion-no-padding"
        >

          <ion-img
            #image
            [src]="img.id | secureMedia | async"
            ionImgDidLoad="img.src = true"
            (ionError)="errorImage(image)"
          >
          </ion-img>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
