import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/app.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class GeofenceService {
  geofencesAreEnable = false;
  constructor(
    private deviceService: DeviceDataService,
    public authService: AuthenticationService,
    private appService: AppService,
    public translate: TranslateService,
  ) {}
  async enableDisableGeofences(device): Promise<boolean> {
    console.log('enableDisableGeofences')
    let status = !this.geofencesAreEnable;
    try {
      const res = await this.authService.enableDisableGeofences(
        device.id,
        Number(status),
      );

      if (!res.success.length && !device.geofences?.length) {
        // If the operation fails, revert the status
        this.geofencesAreEnable = !status;
      } else {
        this.getGeofenceAlertsState(res.success, device);
      }

      // Update geofences status and update the device in the service
      device.geofences.forEach((geo) => {
        geo.trigger_enter = status;
        geo.trigger_leave = status;
      });

      // Update the device information in the service
      this.deviceService.updateDeviceInfo(device);

      // Show success toast message
      const message = status
        ? 'alerts.geofencesUpdatedEnableMessage'
        : 'alerts.geofencesUpdatedDisableMessage';
      this.appService.showToast(
        null,
        this.translate.instant(message),
        2000,
        'success',
      );

      return status; // Return the updated status
    } catch (e) {
      // Show error toast message if the operation fails
      this.appService.showToast(
        null,
        this.translate.instant('alerts.geofencesUpdatedErrorMessage'),
        2000,
        'danger',
      );

      return !status; // Return the original status if there’s an error
    }
  }

  getGeofenceAlertsState(updateGeofences: Array<any> = null, device) {
    let deactiveGeofences = 0;
    const geofences = updateGeofences || device?.geofences;

    geofences?.forEach((geofence: any) => {
      if (geofence.trigger_enter == 0 && geofence.trigger_leave == 0)
        deactiveGeofences = deactiveGeofences + 1;
    });

    if (device?.geofences?.length)
      this.geofencesAreEnable = !(
        deactiveGeofences == device?.geofences?.length
      );
    else this.geofencesAreEnable = false;
    console.log('geofencesAreEnable', this.geofencesAreEnable);
  }

  updateGeofencesStatus(device) {
    console.log('updateGeofencesStatus')
    this.getGeofenceAlertsState(device?.geofences, device);
  }
}
