import { getSource } from '../castMaplibreData';
import { AvailableHooks } from '../componentHooks';
import { Data, dataSourceType, dataTypes } from '../data';
import { DataSource } from '../dataSource';
import { Layer, layerType } from '../layer';
import { FeatureInterface } from './feature-interface';
import { MainMapInterface } from '../../main-map.interface';
import { MapService } from '../../map.service';
import { AppService } from 'src/app/app.service';
import {
  GetDistanceFromArray,
  getCssStyleVariable,
  getDeviceImageName,
  getLineOffset,
  resizeImage,
  stringToPoints,
} from '../mapUtil';
import { LngLatLike } from 'mapbox-gl';
import * as turf from '@turf/turf';
import { inject } from '@angular/core';
import { DeviceImageService } from 'src/app/pipes/deviceImagePipe';
import { DummyImage } from 'src/app/components/media-managment/media-api.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import mapboxgl from 'mapbox-gl';
import { MapSliderService } from 'src/app/services/map-slider-service';
import { CreateRelativeArrayIndexes, cleanRelativeArrayIndexes } from 'src/app/workers/slider-worker';

export enum MAP_CAMERA_OPTIONS {
  center = 'center',
  inBounds = 'inBounds',
  navigation = 'navigation'
}

export const CAMERA_OPTIONS_OBJ = {
  center: { icon: 'assets/images/map/slider/focus.svg', name: 'center' },
  inBounds: { icon: 'assets/images/map/slider/in-bounds.svg', name: 'inBounds' },
  navigation: { icon: 'assets/images/map/slider/navigation1.svg', name: 'navigation' }
}

export interface SLIDER_FEATURE_PROPS {
  currentIndex: number,
  isPlaying: boolean,
  duration: number,
  selectedCamera: MAP_CAMERA_OPTIONS|string
};

export class RouteSliderAnimation implements FeatureInterface {
  protected readonly imageService: DeviceImageService = inject(DeviceImageService);
  protected readonly sliderService: MapSliderService = inject(MapSliderService);

  SOURCE_NAME: string = 'animatedRouteSource';
  LAYER_NAME: string = 'animatedRouteLayer'; //_ Is used this name also in hitmap class
  CAR_MARKER_LAYER_NAME: string = 'carMarkerLayer';
  CAR_MARKER_SOURCE_NAME: string = 'carMarkerSource';
  source: DataSource = null;
  layer: any = null;
  carMarkerLayer: any = null;
  carMarkerSource: DataSource = null;
  carMarker: string = '../assets/car.png';
  primaryColor: string = '#FF4E00';
  show: boolean = true;
  mapService: MapService = inject(MapService);
  appService: AppService = inject(AppService);
  devicesService: DeviceDataService = inject(DeviceDataService);
  currentIndex: number = null;
  previousIndex: number = 0;
  dataPoints: any[] = [];
  device: any = null;
  direction: string = 'forward';
  animationRef: number = null;
  animationData: any = {
    startIndex: 0,
    endIndex: 0,
  };

  animationPoints: any[] = [];
  snapedPoints: any[] = [];
  playing: boolean = false;
  duration = 1000;
  tripsData: any = {};
  selectedCamera = MAP_CAMERA_OPTIONS.inBounds;
  cameraRoute = [];
  DISTANCE_TO_GENERATE_POINTS = 100; // METERS
  routeLengthKm = 0;
  distanceFactor = 0;
  relativeArrayIndexes = [];
  animationPointsWithDirIndex = [];
  obs = [];
  generatinAnimationError = false;
  generatingIndexes = false;
  reqAnimationPoints = null;
  constructor(public main: MainMapInterface) {
    this.init();

  }

  init(): void {
    this.source = this.createDataSource(this.SOURCE_NAME);
    this.carMarkerSource = this.createDataSource(this.CAR_MARKER_SOURCE_NAME);
    this.layer = this.createLayer(this.LAYER_NAME, this.SOURCE_NAME);
    this.carMarkerLayer = this.createLayer(
      this.CAR_MARKER_LAYER_NAME,
      this.CAR_MARKER_SOURCE_NAME,
    );

    setTimeout(() => this.load(), 200);
  }

  createDataSource(name: string): DataSource {
    let source: DataSource = {
      data: {} as Data,
      type: dataSourceType.geojson,
    };

    if (name === this.SOURCE_NAME) {
      source.data.type = dataTypes.FeatureCollection;
      source.data.features = [];
    } else if (name === this.CAR_MARKER_SOURCE_NAME) {
      source.data.type = dataTypes.Feature;
    }

    return source;
  }

  createLayer(layerName: string, source: string): Layer {
    this.primaryColor = getCssStyleVariable('--ion-color-primary');
    // console.log('PRIMARY COLOR', this.primaryColor)
    let layer = new Layer(layerName);
    layer.id = layerName;
    layer.source = source;

    if (layerName === this.LAYER_NAME) {
      layer.type = layerType.line;
      layer.paint['line-color'] = ['get', 'line-color'];
      layer.paint['line-opacity'] = 1;
      layer.paint['line-width'] = 5;
    } else if (layerName === this.CAR_MARKER_LAYER_NAME) {
      layer.type = layerType.symbol;
      layer.layout['icon-image'] = ['get', 'icon-image'];
      // layer['paint']['icon-color'] = this.primaryColor;
      layer.layout['icon-size'] = 0.3;
      layer.layout['icon-anchor'] = 'bottom';
      layer.layout['icon-allow-overlap'] = true;
      layer.layout['icon-rotation-alignment'] = 'viewport';
    }

    return layer;
  }

  load(): void {
    // console.log('slider updater loaded');
    this.main.on(AvailableHooks.onLoadImages, async () => {
    });

    this.main.on(AvailableHooks.onLoadLayer, async () => {

      if (!this.main.map.getLayer(this.CAR_MARKER_LAYER_NAME)) {
        this.main.map.addLayer(this.carMarkerLayer, 'markers');
      }
      if (!this.main.map.getLayer(this.LAYER_NAME)) {
        // console.log('-- ADDING LAYER')
        this.main.map.addLayer(this.layer, this.CAR_MARKER_LAYER_NAME);
        // this.main.map.moveLayer(this.LAYER_NAME, null);
      }
    });

    this.main.on(AvailableHooks.onLoadSource, () => {
      if (!this.main.map.getSource(this.SOURCE_NAME)) {
        this.main.map.addSource(this.SOURCE_NAME, this.source);
      }

      if (!this.main.map.getSource(this.CAR_MARKER_SOURCE_NAME)) {
        this.main.map.addSource(
          this.CAR_MARKER_SOURCE_NAME,
          this.carMarkerSource,
        );
      }
    });

    // this.main.on(AvailableHooks.onSetSliderView, (e: any) => {
    //   console.log('onSetSliderView', e);
    //   if (!e.isEnable) {
    //     this.removeSources();
    //   }
    // });

    this.mapService.sliderSnapedPointsReady.subscribe((obj: any) => {
      if (obj.deviceId === this.device?.id) {
        let snapedArray: any = [];
        // console.log(obj);
        const useGraphhopper = this.appService?.userSettings?.use_graphhopper;
        obj.snapPoints.forEach((p) => {
          if (!useGraphhopper)
            stringToPoints(p, 6).forEach((np) => {
              snapedArray.push(np);
            });
          else {
            snapedArray.push(p);
          }
        });

        this.updateSnapedArray(snapedArray);
      }
    });

    this.sliderService.error.subscribe(res => {
      this.generatinAnimationError = res;
      //_ Remove route source
      if (res) {
        this.clearRouteSource();
      }
    });

    this.main.on(AvailableHooks.onDestroy, () => {
      this.destroy();
    });

    this.obs["showHeatMap"] = this.mapService.showHideHeatMap.subscribe((isEnable) => {
      setTimeout(() => {
        // console.log('HEATMAP CHANGED', {isEnable, existsLAyer: this.main.map.getLayer('animatedRouteLayer')})
        if (isEnable) {
          // if (this.main.map?.getLayer('animatedRouteLayer')) {
            // this.main.map.moveLayer(this.LAYER_NAME, null);
            // console.log('MOVING ANIMATION ROUTE LAYER directions')
          // }
        } else {
          // if (this.main.map?.getLayer('animatedRouteLayer')){
            // console.log('MOVING ANIMATION ROUTE LAYER markers')
            // this.main.map.moveLayer(this.LAYER_NAME, 'markers');
          // }
        }
      }, 5000)
    });
  }

  updateSnapedArray(snapedArray: any[]): void {
    this.snapedPoints = snapedArray;
    const routeLength = GetDistanceFromArray(snapedArray);
    // console.log('ROUTE LENGTH', routeLength)
    //_ Fix custom distance between point for the animation (routeLength in meters)
    if (routeLength > 50000) this.DISTANCE_TO_GENERATE_POINTS = 50;
    if (routeLength < 50000) this.DISTANCE_TO_GENERATE_POINTS = 25;

    this.createDataset(this.currentIndex);
  }
  removeSources(): void {
    console.warn('REmOVING SOURCES');
    if (this.animationRef) {
      cancelAnimationFrame(this.animationRef);
    }

    this.dataPoints = [];
    this.snapedPoints = [];
    this.source.data.features = [];
    this.carMarkerSource.data.features = [];
    this.reqAnimationPoints?.unsubscribe();
    if (getSource(this.main.map, this.SOURCE_NAME)) {
      getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }
    if (getSource(this.main.map, this.CAR_MARKER_SOURCE_NAME)) {
      getSource(this.main.map, this.CAR_MARKER_SOURCE_NAME).setData(
        this.carMarkerSource.data,
      );
      this.main.map.setLayoutProperty(
        this.CAR_MARKER_LAYER_NAME,
        'visibility',
        'none',
      );
    }

    this.resetValues();
  }

  resetValues() {
    this.currentIndex = null;
    this.previousIndex = 0;
    this.device = null;
    this.animationPoints = [];
    // this.relativeArrayIndexes = [];
    // this.snapedPoints = [];
    this.playing = false;
    console.warn('RESETING VALUES');
  }

  onPause(sliderProps) {
    console.log('ON PAUSE SLIDER', sliderProps)
    // console.log('onPause');
    this.playing = false;
    cancelAnimationFrame(this.animationRef);
    //_ Move to the index of the pause
    if (sliderProps.currentIndex === this.device?.dataPoints.length-1) {
      this.changeIndex(this.device, sliderProps);
      this.fitRouteBounds(this.animationPoints);
    }
  }

  async updateDataSource(device: any, sliderProps: SLIDER_FEATURE_PROPS): Promise<void> {
    // console.log('SLIDER PROPS', sliderProps)
    const source = getSource(this.main.map, this.SOURCE_NAME);
    if (!source) {
      console.log('updateDataSource',this.main.map);
      console.error('Source not found');
      return;
    }

    this.main.map.setLayoutProperty(
      this.CAR_MARKER_LAYER_NAME,
      'visibility',
      'visible',
    );

    this.device = device;
    this.currentIndex = sliderProps.currentIndex;
    this.playing = sliderProps.isPlaying;
    this.dataPoints = device.dataPoints.map((data) => [data.lng, data.lat]);
    this.duration = sliderProps.duration;
    this.selectedCamera = MAP_CAMERA_OPTIONS[sliderProps.selectedCamera];
    this.snapedPoints = [];

    // console.log('DEVICE TRIPS', this.device)
    this.createDataset(sliderProps.currentIndex);
  }

  async startAnimation (sliderDevice, sliderProps) {
    //_ Stop animation in case is running
    if (this.animationRef) cancelAnimationFrame(this.animationRef);

    this.device = sliderDevice;
    this.duration = sliderProps.duration;
    this.currentIndex = sliderProps.currentIndex;
    this.playing = sliderProps.isPlaying;

    // console.log('SLIDER PROPS +', { currentIndex: this.currentIndex, device: this.device, deviceDataPoints: sliderDevice.dataPoints, sliderProps, animationPoints: this.animationPoints, relativeIndexes: this.relativeArrayIndexes, snapedPoints: this.snapedPoints  })
    //_ Update data in map
    const currentIndex: any = this.relativeArrayIndexes[sliderProps.currentIndex];
    // console.log('SLIDER PROPS + currentIndex: ', this.currentIndex, currentIndex, this.relativeArrayIndexes)
    if (this.playing) {
      this.animateAll(currentIndex, null, {start: null, lastTime: null}, this.currentIndex);
    } else {
      this.drawAndUpdate(currentIndex, this.currentIndex);
      this.centerMap(currentIndex);
    }
  }

  changeAnimationIndex (sliderIndex) {
    //_ Stop animation in case is running
    if (this.animationRef) cancelAnimationFrame(this.animationRef);
    // console.log('CHANGE SLIDE INDEX 3', { sliderIndex, relative: this.relativeArrayIndexes[sliderIndex] })
    this.currentIndex= this.relativeArrayIndexes[sliderIndex];
    // setTimeout(() => this.animateAll(this.currentIndex));
    this.animateAll(this.currentIndex, null, {start: null, lastTime: null}, sliderIndex);
  }

  animateAll (animIndex: number,
    timestamp = null,
    time = { start: null, lastTime: null },
    sliderIndex = null
  ): any {
    // console.warn('ANIMATE ALL');
    let elapsed = 0;
    const fps = 1000/20; // 20 frames per second
    const pointAnimDuration = this.duration/20;
    if (timestamp) {
      //_ Check prev time exists
      if (time.lastTime) elapsed = timestamp - time.lastTime;

      if (!time.start) time.start = timestamp;
      const lapsed = timestamp - time.start;
      //_ Fix last time when passed 100 ms
      if (elapsed >= fps || !time.lastTime) time.lastTime = timestamp;

      const timeRelativeIndex = 1000/this.duration;
      //console.log('SLIDER ANIMATIONG', { timestamp, lapsed, animIndex, timeRelativeIndex, realSize: this.dataPoints.length, relativeSize: this.animationPoints.length });
      animIndex += timeRelativeIndex;
    }

    if (animIndex >= this.animationPoints.length) {
      this.drawAndUpdate(animIndex, sliderIndex);
      this.centerMap(animIndex);
      this.sliderService.indexChanged.next(this.device.dataPoints.length-1);
      return; // Stop the animation loop
    }

    //_ Draw next point in map only if elapsed time is greater than 100 ms
    if (elapsed >= fps || !time.lastTime) {
      this.centerMap(animIndex);
      this.drawAndUpdate(animIndex, sliderIndex);
    }


    if (this.playing) {
      // then = now - (elapsed % fpsInterval);
      const directionIndex = this.getDirectionIndex(animIndex, sliderIndex);
      this.sliderService.indexChanged.next(directionIndex);
      //console.log('SLIDER PROPS + animIndex: ', { animIndex: animIndex, time: time, sliderIndex: sliderIndex })
      return this.animationRef = requestAnimationFrame((_timestamp) =>
        this.animateAll(
          animIndex,
          _timestamp,
          time,
          sliderIndex
      ));
    }
    
    this.drawAndUpdate(animIndex, sliderIndex); // Call drawAndUpdate after setting up the animation loop
    const directionIndex = this.getDirectionIndex(animIndex, sliderIndex);
    // console.log('SLIDER ANIMATION INDEXES', {animIndex, directionIndex, sliderIndex})
    this.sliderService.indexChanged.next(directionIndex);
    //_ THIS LINE STOP THE LOOP
  }

  getDirectionIndex(relativeIndex: number, sliderIndex: number | null = null): number {
    // const sortedIndexes = this.relativeArrayIndexes.sort((a, b) => a - b);
    const relativeIndexes =  this.relativeArrayIndexes;
    let nearestIndex = -1;
    let minDifference = Number.MAX_SAFE_INTEGER;

    // Start the loop from sliderIndex if it is defined and valid
    const startIndex = sliderIndex !== null && sliderIndex >= 0 ? sliderIndex : 0;

    // Iterate through the sorted array to find the nearest index
    //for (let i = 0; i < relativeIndexes.length; i++) {
    for (let i = startIndex; i < relativeIndexes.length; i++) {
        const difference = Math.abs(relativeIndexes[i] - relativeIndex);

        if (difference < minDifference) {
            minDifference = difference;
            nearestIndex = i;
        }
    }
    //console.log(`slider nearestIndex: ${nearestIndex}, relativeIndex: ${relativeIndex}, minDifference: ${minDifference}`);
    
    return nearestIndex;
  }

  setDuration (duration) {
    this.duration = duration;
  }

  async changeIndex(device: any, sliderProps: SLIDER_FEATURE_PROPS): Promise<void> {
    // console.warn('INDEX CHANGED', sliderProps)
    //_ Stop animation in case is running
    if (this.animationRef) cancelAnimationFrame(this.animationRef);

    const source = getSource(this.main.map, this.SOURCE_NAME);
    if (!source) {
      console.error('Source not found');
      return;
    }

    this.main.map.setLayoutProperty(
      this.CAR_MARKER_LAYER_NAME,
      'visibility',
      'visible',
    );

    this.device = device;
    this.currentIndex = sliderProps.currentIndex;
    this.playing = sliderProps.isPlaying;
    this.duration = sliderProps.duration;
    this.selectedCamera = MAP_CAMERA_OPTIONS[sliderProps.selectedCamera];

    //_ If still relative Indexes were not loaded just go out.
    // if (this.relativeArrayIndexes.length === 0) return;

    if (this.generatinAnimationError || this.generatingIndexes) {
      this.AnimMarkerFails_OnlyMoveMarker(this.currentIndex);
      return;
    }
    //_ Update data in map
    const prevSliderIndex = Math.max(sliderProps.currentIndex-1, 0);
    const currentIndex: any = this.relativeArrayIndexes[sliderProps.currentIndex] ? this.relativeArrayIndexes[sliderProps.currentIndex] : this.relativeArrayIndexes[Math.max(sliderProps.currentIndex - 1, 0)];
    let prevIndex: any = this.relativeArrayIndexes[prevSliderIndex];
    if (this.playing) {
      // console.log('IS PLAYING', this.playing)
      // this.doAnimation(prevIndex, currentIndex, prevIndex);
    } else {
      this.drawAndUpdate(currentIndex, this.currentIndex);
      this.centerMap(currentIndex);
    }
  }

  async createDataset(currentSliderIndex: number, updateMap = true) {
    // console.log('CREATE DATASET', { currentSliderIndex, device: JSON.parse(JSON.stringify(this.device)), snapedPoint: this.snapedPoints })
    if (!this.device) return;

    this.animationPoints = [];
    // const pointsToUse = this.snapedPoints.length
    //   ? this.snapedPoints
    //   : this.dataPoints;
    const pointsToUse = this.dataPoints.length
      ? this.dataPoints
      : this.snapedPoints;

    this.animationPoints = this.generatePointsForPath(pointsToUse);
    this.animationPointsWithDirIndex = this.generatePointsForPath(pointsToUse, true);
    this.primaryColor = getCssStyleVariable('--ion-color-primary');

    this.tripsData = this.device?.tripsData;
    if (this.tripsData === null || this.tripsData?.segments?.length === 0) {
      this.tripsData = this.createTripsData(this.device?.dataPoints, this.animationPoints);
    }

    // this.relativeArrayIndexes = await this.sliderService.createRelativeIndexes(this.tripsData, this.device?.dataPoints, this.animationPoints, this.snapedPoints);

    if (this.snapedPoints?.length > 0) {
      //_ TEST LOCAL GET ANIMATION INDEXES
      // this.relativeArrayIndexes = CreateRelativeArrayIndexes(this.tripsData, this.device?.dataPoints, this.animationPoints, this.snapedPoints);
      // console.log('RELATIVE INDEXES CREATED', this.relativeArrayIndexes);
      // if (updateMap) {
      //   const currentIndex: any = this.relativeArrayIndexes[currentSliderIndex];
      //   this.drawAndUpdate(currentIndex);
      // }
      //_____

      //_ GET REMOTE ANIMATION INDEXES
      this.sliderService.generatingIndexes.next(true);
      this.sliderService.error.next(false);
      this.generatingIndexes = true;
      this.reqAnimationPoints = this.sliderService.createRelativeIndexes(this.tripsData, this.device?.dataPoints, this.animationPoints, this.snapedPoints).subscribe((res: any) => {
        // console.log('CREATING RELATIVE INDEXES', res);
        if (res?.success?.animationPoints) {
          this.relativeArrayIndexes = res?.success?.animationPoints;
          //_ Clean and find segment index by dateunix
          // console.log('TRIP SEGMENTS', this.tripsData.segments);
          this.tripsData.segments = this.cleanSegments(this.tripsData.segments);

          if (updateMap) {
            const currentIndex: any = this.relativeArrayIndexes[currentSliderIndex];
            this.drawAndUpdate(currentIndex, currentSliderIndex);
          }
        }
        this.sliderService.generatingIndexes.next(false);
        this.generatingIndexes = false
        this.sliderService.error.next(false);
      }, error => {
        console.error('ERROR GETTING ANIMATION POINTS');
        this.sliderService.generatingIndexes.next(false);
        this.generatingIndexes = false;
        this.appService.showToast('', 'Error generating animation', 3000, 'danger');
        this.sliderService.error.next(true);
      });
    } else {
      //_ Extract the right index from the animationPoints with indexes
      // console.log('GENERATED ANIM INDEX', { dataPoints: this.device.dataPoints, animationPoints: this.animationPoints, withIndexes: this.animationPointsWithDirIndex})
      this.animationPointsWithDirIndex.forEach((item, index) => {
        if (!this.relativeArrayIndexes[0])
          this.relativeArrayIndexes[0] = 0;

        if (item[2] !== 0)
          this.relativeArrayIndexes[item[2]] = index;

        if (item[2] >= this.animationPoints.length-1)
          this.relativeArrayIndexes[item[2]] = this.animationPoints.length-1;
      });

      // console.log('RELATIVE GENERATED 1', JSON.parse(JSON.stringify(this.relativeArrayIndexes)))
      this.relativeArrayIndexes = cleanRelativeArrayIndexes(this.relativeArrayIndexes);
      // console.log('RELATIVE GENERATED 2', JSON.parse(JSON.stringify(this.relativeArrayIndexes)))
      // console.log('RELATIVE INDEXES 2', this.relativeArrayIndexes)
      if (updateMap) {
        const currentIndex: any = this.relativeArrayIndexes[currentSliderIndex];
        this.drawAndUpdate(currentIndex, currentSliderIndex);
      }
      // this.sliderService.generatingIndexes.next(false);
    }
  }

  drawAndUpdate(pointIndex: number, sliderIndex = null): void {
    let point = this.animationPoints[Math.floor(pointIndex)];

    const lineCoordinates = this.animationPoints.slice(0, pointIndex + 1);

    if (this.source.data.features.length === 0)
      this.source.data.features.push(this.createFeature());

    this.source.data.features[0].geometry.coordinates = lineCoordinates;

    if (sliderIndex) {
      const prevSliderIndex = Math.max(sliderIndex-1, 0);
      let prevIndex: any = this.relativeArrayIndexes[prevSliderIndex];
      
      if (prevIndex == pointIndex) {
        //find the nearest point in animationPoints
        const pt = this.device.dataPoints[sliderIndex];
        point = [pt.lng, pt.lat];
        // const pt = lineCoordinates[sliderIndex];
        // point = [pt[0], pt[1]];
        // console.log( 'slider => drawAndUpdate() => (prevIndex == pointIndex)', `
        //   prevIndex == pointIndex: ${prevIndex == pointIndex}\n
        //   sliderIndex: ${sliderIndex}\n
        //   pointIndex: ${JSON.stringify(pointIndex)}\n
        //   prevIndex: ${JSON.stringify(prevIndex)}\n
        //   pt value: ${JSON.stringify(pt)}\n
        //   lineCoordinates/animationPoints: ${JSON.stringify(this.animationPoints)}\n
        // `);
      }
    }

    // console.log( 'slider => drawAndUpdate()',
    //     {"point": point, 
    //     "pointIndex": pointIndex, 
    //     "sliderIndex": sliderIndex,
    //     "prevSliderIndex": Math.max(sliderIndex-1, 0), 
    //     "lineCoordinates": JSON.stringify(lineCoordinates),
    //     "geometryCoordinates": JSON.stringify(this.source.data.features[0].geometry),
    //     "getSource": getSource(this.main.map, this.SOURCE_NAME),
    //     "sourceData": JSON.stringify(this.source.data) }
    //   );
    
    //_ Move first the marker
    this.updateMarkerLocation(point);

    if (getSource(this.main.map, this.SOURCE_NAME)) {
      getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }
  }

  //_ Move the marker to direction position; in case getAnimationPoints fails.
  AnimMarkerFails_OnlyMoveMarker(pointIndex: number): void {
    const point = this.device.dataPoints[pointIndex];
    this.clearRouteSource();
    this.updateMarkerLocation([point.lng, point.lat]);
  }

  createFeature(properties = null, geometry = null): any {
    return {
      type: 'Feature',
      id: 0,
      geometry: {
        type: 'LineString',
        coordinates: [],
        ...geometry,
      },
      properties: {
        ...properties,
        'line-color': this.primaryColor
      },
    };
  }

  // updateMarkerLocation(coordinates: LngLatLike, direction: number):
  async updateMarkerLocation(coordinates: LngLatLike) {
    if (!this.device) return;

      //_ In case the device is not visible in map, image wont exists.
      const imageName = await this.addMarkerImage(this.device);
      //_ NOW WE CAN USE THE IMAGE
      const json: any = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coordinates,
        },
        properties: {
          // rotate: direction,
          'icon-image': imageName,
          deviceId: this.device?.id,
        },
      };

      this.carMarkerSource.data = json;
      if (getSource(this.main.map, this.CAR_MARKER_SOURCE_NAME)) {
        getSource(this.main.map, this.CAR_MARKER_SOURCE_NAME).setData(this.carMarkerSource.data);
      }
  }

  async addMarkerImage (device) {
    let imageName = getDeviceImageName(device);
    if (!this.main.map.hasImage(imageName)) {
      //_ In case the device is not visible in map, image wont exists.
      this.imageService.getImage(device.properties)
      .subscribe(async (img: any) => {
        if (img != DummyImage) {
          if (img.endsWith(".svg"))
            //_ Change aspect ratio if is a paj_iconset svg image
            img = await resizeImage(img, 122, 170, "white", false);
          else img = await resizeImage(img, 170, 170, "white", false, true);

          const imageLoaded = await this.main.loadImage(device, imageName, img);
          return Promise.resolve(imageName);
        }
      });
    }
    return Promise.resolve(imageName);
  }

  lastBearing = 0;
  DAMPING_FACTOR = 0.2;
  centerMap (index) {
    const location = this.animationPoints[Math.floor(index)];
    if (this.selectedCamera === MAP_CAMERA_OPTIONS.center) {
      this.centerMapLocation(location);
    }
  }

  centerMapLocation(location, props = {}) {
    this.main.map.easeTo({
      center: location,
      easing: (t) => t,
      // duration: 1000,
      essential: true,
      ...props
    });
  }

  generatePointsForPath (points, withIndex = false) {
    let generatedPoints = [];
    points.forEach((p, index) => {
      if (index > 0) {
        let pointA = null;
        let pointB = null;
        if (Array.isArray(p)){
          pointA = { lng: p[0], lat: p[1], index: index };
          pointB = { lng: points[index-1][0], lat: points[index-1][1], index: index };
        }
        else {
          pointA = { ...p, index: index };
          pointB = { ...points[index-1], index: index };
        }

        //_ [lng, lat];
        if (pointA.lat && pointA.lng && pointB.lng && pointB.lat) {
          generatedPoints = generatedPoints.concat(
            this.generatePointsBetweenTwoPoints([pointB.lng, pointB.lat], [pointA.lng, pointA.lat], pointA, pointB, withIndex)
          );
        }
      }
    });

    return generatedPoints;
  }

  generatePointsBetweenTwoPoints(pA, pB, pointA, pointB, withIndex, length = this.DISTANCE_TO_GENERATE_POINTS) {
    // console.log('GENERATING POINTS', { pA, pB, pointA, pointB, withIndex, length })
    if (!pA[0] && !pB[0]) return [];

    const line = turf.lineString([pA, pB]);
    const options: any = { units: "meters" };
    const dist = turf.distance(pA, pB, options);
    const distStep = length; //d5st < 200 ? 0.5 : 1; //dist/length;

    if (dist < length) {
      if (withIndex)
        return [[...pA, pointA.index], [...pB, pointB.index]];
      else
        return [pA, pB]; //_ Return same points if distance is less than 2 meters
    }

    let rPoints = [];
    if (withIndex)
      rPoints.push([...pA, pointA?.index]);
    else
      rPoints.push(pA);

    const distanceToPointB = turf.distance(turf.point(pA), turf.point(pB));
    for (let i = 0; i <= dist; i += distStep) {
      const p = turf.along(line, i, options);
      if (withIndex) {
        if (i < distanceToPointB/2) {
          rPoints.push([...p.geometry.coordinates, pointA.index]);
        } else {
          rPoints.push([...p.geometry.coordinates, pointB.index]);
        }
      }else {
        rPoints.push(p.geometry.coordinates);
      }
    }

    if (withIndex)
      rPoints.push([...pB, pointB.index]);
    else
      rPoints.push(pB);
    return rPoints;
  }

  fitRouteBounds (points) {
    console.log('FIT BOUNDS');
    const isSmallScreen = document.documentElement.clientWidth < 769 ? true : false;

    const padding = isSmallScreen
      ? { top: 50, right: 50, bottom: 250, left: 50 }
      : { top: 100, right: 500, bottom: 150, left: 100 };

    this.main.fitBounds(points, padding, 1.2, true, null, { duration: 2000 });
  }

  //_ Create formated data for no snapped points; using direction markers and animationPoints
  createTripsData(directionPoints, animationPoints) {
    return {
      segments: [directionPoints.map(point => [point.lng, point.lat, point.speed, point.dateunix])],
      trips: [{
        trip: {
          paths: [{
            points: {
              coordinates: [...animationPoints]
            }
          }]
        }
      }]
    }
  }

  cleanSegments(segments) {
    for (let i = 0; i < segments.length - 1; i++) {
        const currentSegment = segments[i];
        const nextSegment = segments[i + 1];

        const lastDateUnixCurrent = currentSegment[currentSegment.length - 1][3];
        const dateUnixNext = nextSegment[0][3];

        if (lastDateUnixCurrent === dateUnixNext) {
            // Remove the last item from the current segment
            currentSegment.pop();
        }
    }

    return segments;
  }

  getBearing(pointA: any, pointB: any): number {
    return turf.bearing(pointA, pointB);
  }

  clearRouteSource () {
    this.source.data.features = [];
    if (getSource(this.main.map, this.SOURCE_NAME)) {
      getSource(this.main.map, this.SOURCE_NAME).setData(this.source.data);
    }
  }

  destroy(){
    Object.keys(this.obs).forEach(k => this.obs[k].unsubscribe());
  }
}
