<ion-header [translucent]="true">
  <ion-toolbar color="transparent">
    <ion-buttons slot="start">
      <ion-back-button
        style="display: block !important"
        back-close
        back-locale
        >
        </ion-back-button>
      </ion-buttons>

      <ion-title>{{ 'devicesSetup.deviceAlarms.deviceAlarms' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" clasS="ion-padding no-scrollbars">  
  <ion-label class="text-wrap">{{ 'devicesSetup.deviceAlarms.deviceAlarmsInfo' | translate }}</ion-label>
  <ion-list class="ion-no-padding">
    <div class="colors-container">
      <app-alert
        class="deviceInfo"
        [deviceID]="device.id"
        [data]="[device]"        
        [map]="null"
        [showSpeedSection]="this.device.alarmgeschwindigkeit"
        [showTitles]="false"
        [deviceMarkers]="[]"
        [hideGeofencesFeature]="true"
        (onAlarmChange)="alarmChange($event)"
        toastPosition="top"
      ></app-alert>
    </div>
  </ion-list>

  <ion-img class="image-header" src="assets/images/devices-setup/alerts.png"></ion-img>

  <div style="display: flex;justify-content: center;">
    <ion-button mode="md"  color="primary" fill="solid" (click)="nextSetting()" style="margin-top: 24px;">      
      <div style="display: inline-block;flex-direction: column;">
        <ion-label>{{ 'devicesSetup.guideSteps.nextButton' | translate }}</ion-label><br />
        <small>{{ 'devicesSetup.devicePosition.trackingSetting' | translate }}</small>
      </div>
    </ion-button>
  </div>
  
</ion-content>
