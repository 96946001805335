import { HttpClient } from "@angular/common/http";
import { Pipe, PipeTransform } from "@angular/core";
import { AppService } from "../app.service";
import { MediaApiService } from "../components/media-managment/media-api.service";
import { MEDIA_OBJECT } from "../components/media-managment/media-interfaces";
import { IsInteger, IsString } from "../util/validations";

//_ THIS PIPE NEEDS TO BE CHECKED -- PERFORMANCE IS BAD !!
//_ MAYBE THE SERVICE HAS ISSUES
@Pipe({ name: 'secureMedia', })
export class SecureMediaPipe implements PipeTransform {

    lastThemeStatus = null;
    image = null;
    constructor(private http: HttpClient, private mediaApi: MediaApiService,
        private appService: AppService) {
        this.lastThemeStatus = this.appService.darkMode;
    }

    //_ @Async PIPE
    transform(image: MEDIA_OBJECT | string | number, shouldUpdate = false, errorImage='') {
        this.image = image;
        console.warn('FILE TYPE: ', { object: typeof image === 'object', int: IsInteger(image), string: IsString(image) })
        if (typeof image === 'object') {
          return this.mediaApi.getImageData(image, true, errorImage);
        }
        //_ If is string then create the imgObject
        else if (IsInteger(image)) {
          return this.mediaApi.getImageDataByMediaId(+image, true, errorImage);
        }
        else if (IsString(image)){
          const collection = (image as string).split("---")[0];
          const imgObject = this.mediaApi.createImageObject(
            collection,
            image
          );

          return this.mediaApi.getImageData(imgObject, true, errorImage);
        }
    }

    checkForThemeChanges() {
        this.appService.darkMode$.subscribe(newStatus => {
            if (this.lastThemeStatus != newStatus)
                this.transform(this.image, true);
        })
    }
}
