import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Constants } from 'src/app/constants.enum';
import { DeviceData } from 'src/app/members/map/components/map/class/deviceData';
import { calculateHandlePoint, CenterPoint } from 'src/app/members/map/components/map/class/geofence_center';
import { DecimalPipe } from '@angular/common';
import { AppService } from 'src/app/app.service';
import * as turfArea from '@turf/area';
import { AlertService } from 'src/app/members/map/devices-sidebar/device-item/device-submenu/alert/alert.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from 'src/app/members/map/components/map/map.service';
import { AlertController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { MapAddressPickerComponent } from 'src/app/components/map-address-picker/map-address-picker.component';
import { centroid as Tcentroid, } from '@turf/turf';
import { createCircle, getCenter, translatePolygon } from 'src/app/members/map/components/map/class/customDragmode';
import { Keyboard } from '@capacitor/keyboard';
import { NewPullupService } from '../../new-pullup/pullup.service';
import { DevicesSidebarService } from '../../devices-sidebar/devices-sidebar.service';
import Pickr from "@simonwep/pickr";
@Component({
  selector: 'app-map-geofence',
  templateUrl: './map-geofence.component.html',
  styleUrls: ['./map-geofence.component.scss'],
})
export class MapGeofenceComponent implements OnInit, OnDestroy {
  @Input() feature;
  enabled: boolean = true
  @Input() device: DeviceData = null;
  @Input() drawer = null;
  @Input() source = null;
  language = 'en';
  geofence = null;

  dragginVertex = null;
  radiusText: any = '0 km';
  areaText: any = ' 0 km';
  distance_unit = "";
  pickrGL: any;

  enabledSoundEnter: boolean = false;
  enabledSoundLeave: boolean = false;

  constructor(private appService: AppService, private geoCenter: CenterPoint,
    private decimalPipe: DecimalPipe, private alertService: AlertService,
    private devicesService: DeviceDataService, private authService: AuthenticationService,
    private translate: TranslateService, private centerPoint: CenterPoint,
    private mapService: MapService, private modalCtrl: ModalController,
   private alertController: AlertController,
    private _translate: TranslateService,private platform:Platform,
    private pullupService: NewPullupService, private devicesSidebarService: DevicesSidebarService) {
    this.distance_unit = this.appService.user
      ? this.appService.user.distance_unit
      : "km";
  }

  ngOnInit() {
    this.language = this.appService.language;
    this.geofence = this.device.geofences.find(g => g.id == this.feature.properties.geoId);
    this.geofence.prevName = this.geofence.name;

    this.enabledSoundEnter = this.geofence.sound_enter == 1;
    this.enabledSoundLeave = this.geofence.sound_leave == 1;

    this.calcRadius(this.feature.properties.radiusInKm);
    this.calcArea(this.feature);
    this.listenForVertex();
  }

  toogleSoundAlert(type: string){
    let propertyValue = 0;
    if(type == "enter"){
      this.enabledSoundEnter = !this.enabledSoundEnter
      propertyValue = this.enabledSoundEnter ? 1 : 0
    }else if(type == "leave"){
      this.enabledSoundLeave = !this.enabledSoundLeave
      propertyValue = this.enabledSoundLeave ? 1 : 0
    }

    const modelProperty = type == "enter" ? "sound_enter" : "sound_leave";
    this.authService.updateGeofenceSound(+ this.feature.properties.geoId, + this.device.id, modelProperty, propertyValue).then(res => {
      if (res) {
        if (res.success) {
          this.devicesService.setGeofence(res.success);
          this.appService.showToast('', this.translate.instant('alerts.geofenceUpdateSuccessMessage'), 2000, 'success');
        }
      }
    }).catch(e => this.errorUpdate());
  }

  listenForVertex() {
    if (this.dragginVertex) this.dragginVertex.unsubscribe();

    this.dragginVertex = this.geoCenter.dragVertex.subscribe((ev: any) => {
      const props = ev.state.feature.properties;
      if (this.feature.properties.isCircle){
        this.feature.properties.radiusInKm = props.radiusInKm;
        this.calcRadius(props.radiusInKm);
      }else
        this.calcArea(ev.state.feature);
    })
  }

  ngOnDestroy() {
    if (this.dragginVertex)
      this.dragginVertex.unsubscribe();
  }

  calcRadius(rkm) {
    let units = rkm < 1 ? ' m' : ' km';
    if (this.distance_unit == 'mi') {
      rkm = rkm / 1.609;
      units = ' mi';
    }
    else
      rkm = rkm < 1 ? rkm * 1000 : rkm;
    this.radiusText = this.decimalPipe.transform(rkm, '1.1-1', this.language) + units;
  }

  calcArea(feature) {
    let area = turfArea.default(feature);
    let units = area < 1000000 ? ' m²' : ' km²'; //_ 1 km² = 1000000 m²

    if (this.distance_unit == 'mi') {
      area = area * 0.00000038610; //_ 1m² = 3.8610x10-6 mileage²
      units = ' mi²';
    }
    else
      area = area > 1000000 ? area / 1000000 : area;

    this.areaText = this.decimalPipe.transform(area, '1.2-2', this.language) + units;
  }

  getPickr() {
    if (this.pickrGL?.getRoot()) {
      this.pickrGL.destroyAndRemove();
      this.pickrGL = null;
    }

    const pickr = Pickr.create({
      el: '.color-box',
      // el:".color-box1",
      theme: "nano",
      // container: "app-device-settings-tab",
      default: this.feature?.properties.portColor,
      //feature?.properties.portColor
      useAsButton: true,
      appClass: "pickr-border",
      components: {
        preview: true,
        opacity: false,
        hue: true,
        interaction: {
          hex: true,
          rgba: false,
          input: true,
          hsva: false,
          cancel: true,
          save: true
        }
      },
      i18n: {
        'btn:save': this._translate.instant("alerts.saveText"),
        'btn:cancel': this._translate.instant("alerts.cancel")
      }
    });

    pickr.on('save', (color, instance) => {
      const selectedColor = color.toHEXA().toString();
      this.changeColor(selectedColor, this.feature?.properties.opacity)

      pickr.destroyAndRemove();
    })
    pickr.show();
    this.pickrGL = pickr;
  }

  changeColor(color, opacity = 0.25) {
    console.log('this.feature',this.feature)
    //_ No allow to edit the geofence
    if (!Boolean(this.device.permissions.modify))
      return

    //const color = ev.target.value;
    this.feature.properties.portColor = color;
    this.feature.properties.opacity = opacity;

    this.drawer.setFeatureProperty(this.feature.id, 'portColor', color);
    this.drawer.setFeatureProperty(this.feature.id, 'opacity', opacity);
    this.drawer.add(this.feature);

    this.alertService.updateGeofence(this.feature).then(res => {
      this.devicesService.setGeofence(res);
      this.appService.showToast('', this.translate.instant('alerts.geofenceUpdateSuccessMessage'), 2000, 'success');
      this.alertService.geofenceUpdated.next(res);
    }).catch(e => this.errorUpdate());
  }

  errorUpdate() {
    this.appService.showToast('', this.translate.instant('geofence.errorTryingToUpdateGeofence'), 2000, 'danger');
  }

  updateGeofence(value, property) {
    //_ No allow to edit the geofence
    if (!Boolean(this.device.permissions.modify))
      return

    value = value.detail.checked ? 1 : 0;
    this.authService.updateGeofenceStatus(+ this.feature.properties.geoId, + this.device.id, property, + value).then(res => {
      if (res) {
        if (res.success) {
          this.devicesService.setGeofence(res.success);
          this.appService.showToast('', this.translate.instant('alerts.geofenceUpdateSuccessMessage'), 2000, 'success');
        }
      }
    }).catch(e => this.errorUpdate());
  }

  async deleteGeo() {
    //_ No allow to edit the geofence
    if (!Boolean(this.device.permissions.modify))
      return

    if (await this.appService.confirmDialogPopup(this.translate.instant('geofence.deleteGeofence'), this.translate.instant('geofence.sureToDeleteGeofence'))) {
      const geoId = this.feature.id;
      this.drawer.delete(geoId);
      this.centerPoint.removeCenterPoint(geoId);
      this.source.features =
        this.source.features.filter((obj) => obj.id !== geoId);
      let removeIndex = this.device.geofences.map(item => item.id).indexOf(this.geofence.id);

      if (removeIndex > -1) {
        this.device.geofences.splice(removeIndex, 1);
        this.devicesService.updateDeviceInfo(this.device);
      }

      //_ Delete map popup
      // const popups = document.getElementsByClassName("mapboxgl-popup maplibregl-popup new-map-popup");
      // console.log("popup value : ", popups)

      // Array.prototype.forEach.call(popups, function(popup) {
      //   console.log("remove function")
      //   popup.remove();
      // });
      const popups = document.querySelectorAll(".mapboxgl-popup, .maplibregl-popup, .new-map-popup");
      popups.forEach(popup => {
      popup.remove();
    });
      this.authService.deleteGeofence(this.geofence.id, this.device.id);
    }

  }

  async setName(ev) {
    //_ No allow to edit the geofence
    if (!Boolean(this.device.permissions.modify))
      return ;

    if (this.platform.is('cordova') || this.platform.is('capacitor')){
      Keyboard.hide();
    }

    if (this.geofence.name != this.geofence.prevName) {
      this.feature.properties.name = this.geofence.name;
      this.authService.updateGeofence(this.geofence.id, this.geofence.iddevice, this.geofence.coordinates, this.geofence.shape_type, this.geofence.options, this.geofence.name).then(res => {
        if (res) {
          if (res.success) {
            res = res.success;
            this.geofence.prevName = res.name;
            let index = this.device.geofences.findIndex(obj => obj.id === res.id);
            this.device.geofences[index] = this.geofence
            this.devicesService.setGeofence(res);
            this.alertService.geofenceUpdated.next(res);
            this.appService.showToast('', this.translate.instant('alarms.nameUpdated'), 2000, 'success');
          }
        }
      });
    }
  }

  async openAddressPicker() {
    //_ No allow to edit the geofence
    if (!Boolean(this.device.permissions.modify))
      return

    const geoPolygon = this.feature;
    const geoCenter = Tcentroid(geoPolygon);

    const md = await this.modalCtrl.create({
      component: MapAddressPickerComponent,
      componentProps: {
        coords: { lat: geoCenter.geometry.coordinates[1], lng: geoCenter.geometry.coordinates[0] },
        markerTitle: '',
        address: '',
        showWorkAndHomeOptions: false,
      }
    });

    await md.present();
    const { data } = await md.onWillDismiss();

    if (data)
      this.moveGeofence(data.coords.lat, data.coords.lng);
  }

  moveGeofence(lat, lng) {
    //_ Get polygon and current center of the geofence
    const geoPolygon = this.feature;
    const geoCenter = Tcentroid(geoPolygon);

    const from = geoCenter.geometry.coordinates;
    const to = [lng, lat];

    // //_ Find bearing and distance to move the polygon
    // const bearing = turf.rhumbBearing(from, to);
    // const distance = turf.rhumbDistance(from, to);

    // //_ Move the polygon using the distance and bearing angle
    // const translatePoly = turf.transformTranslate(geoPolygon, distance, bearing);
    let coords = [];
    if (this.feature.properties.isCircle)
      coords = createCircle(to, this.feature.properties.radiusInKm);
    else
      coords = this.translatePolygon(this.feature, from, to);

    this.feature.geometry.coordinates = coords;

    // //_ Move the center marker and popup
    const newCenter = getCenter(coords);
    // const centroId: any = window[this.feature.id];
    // const newHPoint = calculateHandlePoint(this.feature)
    // centroId.setLngLat(newHPoint.geometry.coordinates)
    this.movePopup(newCenter.geometry.coordinates);

    // //_ Apply changes (move) the feature and update the map drawer
    // this.feature.geometry = translatePoly.geometry;
    this.feature.properties.center = newCenter.geometry.coordinates;
    this.drawer.add(this.feature);

    //_ Update geofence data in backend
    this.centerPoint.updateFeature(this.feature);
  }

  movePopup(coordinates) {
    const popup = window['geofencePopup'];
    if (popup)
      popup.setLngLat(coordinates);
  }


  updateName(ev) {
    this.geofence.name = ev.detail.value;
  }

  adjustRadiusText() {
    if (this.distance_unit == 'km') {
      if (this.radiusText.endsWith('km'))
        return this.decimalPipe
            .transform(
            parseFloat(this.radiusText.replace(/[a-z]/gi, '').replace(/,/, '.')) * 1000 ,
            '1.1-2',
            this.language
        ) + ' m';
      else return this.radiusText;
    } else return this.radiusText;
  }

  async setRadius(){
    //_ No allow to edit the geofence
    const radiusQtyStr = this.adjustRadiusText().replace(/[a-z]/gi, '').trim();
    if (!Boolean(this.device.permissions.modify))
      return

    const radius = this.decimalPipe.transform(this.feature.properties.radiusInKm, '1.2-2', this.language);
    const alert = await this.alertController.create({
      cssClass: "geofence-radius",
      mode: "ios",
      header: this._translate.instant("geofence.setRadius"),
      subHeader: this._translate.instant("geofence.currentRadius") + ' ' +  this.adjustRadiusText(),
      backdropDismiss: false,
      inputs: [
        {
          name: "geofenceRadius",
          value: radiusQtyStr,
          placeholder: radiusQtyStr,
          type: 'number',
          min: 0.1,
          max: 500,
          attributes: { step: '0.1' },
          id: 'radiusInput'
        },
      ],
      buttons: [
        {
          text: this._translate.instant("alerts.cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: (data) => {

          },
        },
        {
          text: "Ok",
          handler: (res) => {
            //Given radius in meter
            if (res.geofenceRadius != ''){
              if (this.distance_unit == 'mi')
                res.geofenceRadius = res.geofenceRadius * 1609;

              // convert in Km
              res.geofenceRadius = res.geofenceRadius * 0.001;
              let newRadiusInKm = +this.decimalPipe.transform(+res.geofenceRadius, '1.2-3', 'en');

              if (newRadiusInKm != +this.feature.properties.radiusInKm && newRadiusInKm > 0){
                //_ Update current data in component
                this.feature.properties.radiusInKm = newRadiusInKm;
                this.calcRadius(newRadiusInKm);
                //_ Update geofence in the map
                this.moveGeofence(this.feature.properties.center[1], this.feature.properties.center[0]);
              }
            }
          },
        },
      ],
    });

    await alert.present();
  }
  async enableAlerts(){
    //_ No allow to edit the geofence
    if (!Boolean(this.device.permissions.modify))
      return

    await this.authService.updateDeviceAlarms(this.device.id,'alarm_enabled', 1 )
    .then(
      res => {
        this.devicesService.devices[this.device.id].properties.alarm_enabled = res['success'].alarm_enabled;
        this.device.properties.alarm_enabled = res['success'].alarm_enabled;
        this.devicesService.updateDeviceInfo(this.device);
        //this.appService.showToast(null, this._translate.instant("enableDisableAllAlerts.allUpdated"), 2000, 'success');
      }).catch(e => {
        this.appService.showToast(null, this._translate.instant("enableDisableAllAlerts.errorWhileUpdating"), 2000, 'danger');
      });
  }

  opacityFormat (value) {
    return Math.round(value*100).toFixed(0) + '%';
  }

  onChangeOpacity (ev) {
    //this.changeColor({ target: { value: this.feature.properties.portColor }}, ev.detail.value)
    this.changeColor(this.feature.properties.portColor, ev.detail.value)
  }

  decreaseOpacity(){
    if (this.feature.properties.opacity > 0){
      this.feature.properties.opacity -= 0.01;
      this.changeOpacityFromIcon(this.feature.properties.opacity);
    }
  }

  increaseOpacity(){
    if (this.feature.properties.opacity < 1){
      this.feature.properties.opacity += 0.01;
      this.changeOpacityFromIcon(this.feature.properties.opacity);
    }
  }

  changeOpacityFromIcon(value){
    //this.changeColor({ target: { value: this.feature.properties.portColor }}, value)
    this.changeColor(this.feature.properties.portColor, value)
  }

  openKeyboard () {
    if (this.mapService.showPullup.value) {
      this.mapService.showPullup.next(false);
      this.appService.deviceMenuisOpen.next(false);
      this.devicesSidebarService.isOpen.set(false);
    }
  }

  translatePolygon = (polygon, from, to) => {
    // Calculate the translation vector (difference in coordinates)
    const translationVector = [
      to[0] - from[0], // Difference in longitude
      to[1] - from[1], // Difference in latitude
    ];

    // Translate each coordinate of the polygon
    const translatedCoordinates = polygon.geometry.coordinates[0].map(coord => {
      return [
        coord[0] + translationVector[0], // Translate longitude
        coord[1] + translationVector[1], // Translate latitude
      ];
    });

    // Return the new translated coordinates in the GeoJSON format
    return [translatedCoordinates];
  };
}
