// import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
// import { Storage } from '@ionic/storage-angular';
// import { DeviceDataService } from 'src/app/members/map/devicesData.service';

// @Injectable({
//   providedIn: 'root',
// })
// export class TimerService {
//   walkCommand = {
//     id: '66ab367bad6c546c278e78f5',
//     name: 'XmPetWalkMode',
//     desc: 'Start walk mode',
//     availability: 'INSTANT',
//     remainingTime: null,
//     parameters: {
//       DURATION: 1,
//       INTERVAL: 30,
//     },
//   };
//   liveCommand = {
//     id: '657f764afd1f2b2a5ced18f7',
//     name: 'XmPetLiveTrack',
//     desc: 'Activate Live Tracking (Sending every 5 seconds for 3 minutes)',
//     availability: 'INSTANT',
//     remainingTime: null,
//   };
//   private timerSubject = new BehaviorSubject<{ [key: string]: number }>({});
//   timer$ = this.timerSubject.asObservable(); // Expose the observable
//   private timerIntervals: { [key: string]: any } = {};

//   constructor(
//     private storage: Storage,
//     private deviceService: DeviceDataService,
//   ) {
//     this.updateAllTimers();
//   }
//   async updateAllTimers(): Promise<void> {
//     // Update both timers
//     await this.updateTimerFromStorage(this.walkCommand.name);
//     await this.updateTimerFromStorage(this.liveCommand.name);
//   }

//   async startTimer(item: any,device:any): Promise<void> {
//     // Check if the timer is already running
//     const remainingTime = await this.getRemainingTime(item.name);
//     if (remainingTime > 0) {
//       console.log(
//         `Timer for ${item.name} is already running with ${remainingTime} seconds remaining.`,
//       );
//       return;
//     }
//     console.log('item', item);
//     const startTime = new Date().getTime();
//     const timerData = { startTime, duration: item.remainingTime };
//     console.log('timerData', timerData);
//     let timers = (await this.storage.get(item.name)) || {};
//     timers[item.name] = timerData;
//     await this.storage.set(item.name, timers);
//     // Call the device service when the timer starts
//     this.deviceService.updateSingleDevicePosition(device.id);
//     // Start the countdown
//     this.startCountdown(item.name);
//   }

//   async getRemainingTime(name: string): Promise<number> {
//     const timers = await this.storage.get(name);
//     if (!timers || !timers[name]) {
//       return 0;
//     }
//     const { startTime, duration } = timers[name];
//     const elapsed = Math.floor((new Date().getTime() - startTime) / 1000);
//     console.log('elapsed time (in seconds):', elapsed);
//     return Math.max(duration - elapsed, 0);
//   }

//   async updateTimerFromStorage(name: string): Promise<void> {
//     const remainingTime = await this.getRemainingTime(name);

//     const currentTimers = this.timerSubject.value;
//     // Update the remaining time for the specific timer
//     const updatedTimers = {
//       ...currentTimers,
//       [name]: remainingTime,
//     };
//     this.timerSubject.next(updatedTimers);
//     if (name === this.walkCommand.name) {
//       this.walkCommand.remainingTime = remainingTime;
//     } else if (name === this.liveCommand.name) {
//       this.liveCommand.remainingTime = remainingTime;
//     }

//     // Start countdown if there's remaining time
//     if (remainingTime > 0) {
//       this.startCountdown(name);
//     } else {
//       this.clearTimer(name);
//     }
//   }

//   private startCountdown(name: string) {
//     // Clear any existing interval
//     if (this.timerIntervals[name]) {
//       clearInterval(this.timerIntervals[name]);
//     }
//     // Start decrementing the timer every second
//     this.timerIntervals[name] = setInterval(async () => {
//       const remainingTime = await this.getRemainingTime(name);
//       const currentTimers = this.timerSubject.value;

//       // Update the remaining time for the specific timer
//       const updatedTimers = {
//         ...currentTimers,
//         [name]: remainingTime,
//       };
//       this.timerSubject.next(updatedTimers);
//       if (name === this.walkCommand.name) {
//         this.walkCommand.remainingTime = remainingTime;
//       } else if (name === this.liveCommand.name) {
//         this.liveCommand.remainingTime = remainingTime;
//       }

//       if (remainingTime <= 0) {
//         this.clearTimer(name);
//       }
//     }, 1000);
//   }

//   public async clearTimer(name: string): Promise<void> {
//     if (this.timerIntervals[name]) {
//       clearInterval(this.timerIntervals[name]);
//       delete this.timerIntervals[name];
//     }

//     const timers = (await this.storage.get(name)) || {};
//     delete timers[name];
//     await this.storage.set(name, timers);

//     // Update the subject to remove the cleared timer
//     const currentTimers = this.timerSubject.value;
//     const updatedTimers = { ...currentTimers };
//     delete updatedTimers[name];
//     if (name === this.walkCommand.name) {
//       this.walkCommand.remainingTime = 0;
//     } else if (name === this.liveCommand.name) {
//       this.liveCommand.remainingTime = 0;
//     }

//     this.timerSubject.next(updatedTimers);
//     // Call the device service when the timer ends
//     this.deviceService.stopTimerUpdate();
//   }
// }

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private timers: {
    [key: string]: {
      dId: number;
      startTime: number;
      duration: number;
      interval: number;
    };
  } = {};
  private timerSubject = new BehaviorSubject<{ [key: string]: number }>({});
  timer$ = this.timerSubject.asObservable(); // Expose observable for components/pages
  private timerIntervals: { [key: string]: any } = {};
  walkCommand = {
    id: '66ab367bad6c546c278e78f5',
    name: 'XmPetWalkMode',
    desc: 'Start walk mode',
    availability: 'INSTANT',
    remainingTime: null,
    parameters: {
      DURATION: 1,
      INTERVAL: 30,
    },
  };
  liveCommand = {
    id: '657f764afd1f2b2a5ced18f7',
    name: 'XmPetLiveTrack',
    desc: 'Activate Live Tracking (Sending every 5 seconds for 3 minutes)',
    availability: 'INSTANT',
    remainingTime: null,
    parameters: {
      DURATION: 300,
      INTERVAL: 5,
    },
  };
  constructor(
    private storage: Storage,
    private deviceService: DeviceDataService,
  ) {
    // this.initializeStorage();
  }

  // Initialize storage and load saved timers
  async initializeStorage(): Promise<void> {
    console.error('initializeStorage in pet mode');
    await this.storage.create(); // Initialize Ionic Storage
    const savedTimers = (await this.storage.get('timers')) || {};
    this.timers = savedTimers;
    this.resumeAllTimers();
  }

  // Start a new timer
  async startTimer(item: any, device: any): Promise<void> {
    const existingTimer = this.timers[item.name];
    if (existingTimer) {
      const remainingTime = await this.getRemainingTime(item.name);
      if (remainingTime > 0) {
        console.log(
          `Timer for ${item.name} is already running with ${remainingTime} seconds remaining.`,
        );
        return;
      }
    }

    const startTime = new Date().getTime();
    this.timers[item.name] = {
      dId: device.id,
      startTime,
      duration: item.parameters?.DURATION || item.remainingTime,
      interval: item.parameters?.INTERVAL || 3000,
    };
    await this.storage.set('timers', this.timers); // Persist timers to storage

    // Immediately call device update service
    this.deviceService.updateSingleDevicePosition(device.id);

    // Start the countdown and periodic updates
    this.startCountdown(item.name, device.id);
  }

  // Get the remaining time for a timer
  async getRemainingTime(name: string): Promise<number> {
    const timer = this.timers[name];
    if (!timer) {
      return 0;
    }

    const { startTime, duration } = timer;
    const elapsed = Math.floor((new Date().getTime() - startTime) / 1000);
    return Math.max(duration - elapsed, 0);
  }

  // Resume all timers on app restart
  private async resumeAllTimers(): Promise<void> {
    for (const name of Object.keys(this.timers)) {
      const remainingTime = await this.getRemainingTime(name);
      const deviceId = this.timers[name].dId;
      console.log('deviceId', deviceId);
      if (remainingTime > 0) {
        this.startCountdown(name, deviceId); // Resume countdown without device update
      } else {
        this.clearTimer(name); // Clear expired timers
      }
    }
  }

  // Start the countdown and periodic updates
  private startCountdown(name: string, deviceId: number): void {
    if (this.timerIntervals[name]) {
      clearInterval(this.timerIntervals[name]);
    }

    const timer = this.timers[name];
    if (!timer) {
      return;
    }

    this.timerIntervals[name] = setInterval(async () => {
      const remainingTime = await this.getRemainingTime(name);
      // Update the observable
      const currentTimers = this.timerSubject.value;
      const updatedTimers = {
        ...currentTimers,
        [name]: remainingTime,
      };
      this.timerSubject.next(updatedTimers);

      // Periodic API update if device is provided
      if (
        deviceId &&
        remainingTime > 0 &&
        remainingTime % timer.interval == 0
      ) {
        console.warn(
          `making api with ${timer.interval * 100} for  ${timer.duration} and remaining time is   ${remainingTime}`,
        );
        this.deviceService.updateSingleDevicePosition(deviceId);
      }
      if (name === this.walkCommand.name) {
        this.walkCommand.remainingTime = remainingTime;
      } else if (name === this.liveCommand.name) {
        this.liveCommand.remainingTime = remainingTime;
      }

      if (remainingTime <= 0) {
        this.clearTimer(name);
      }
    }, 1000); // Update every second
  }

  // Clear a timer
  public async clearTimer(name: string): Promise<void> {
    if (this.timerIntervals[name]) {
      clearInterval(this.timerIntervals[name]);
      delete this.timerIntervals[name];
    }

    delete this.timers[name];
    await this.storage.set('timers', this.timers); // Persist updated timers to storage

    // Update the observable
    const currentTimers = this.timerSubject.value;
    const updatedTimers = { ...currentTimers };
    delete updatedTimers[name];
    this.timerSubject.next(updatedTimers);
    if (name === this.walkCommand.name) {
      this.walkCommand.remainingTime = 0;
    } else if (name === this.liveCommand.name) {
      this.liveCommand.remainingTime = 0;
    }
    // Call device service to stop timer updates
    this.deviceService.stopTimerUpdate();
  }
}
