import { environment as ENV } from './../../../environments/environment';
const { v5: uuidv5 } = require('uuid');
export interface MEDIA_OBJECT {
    collection_name: string,
    file_name: string,
    //_ Pass -1 if not user is logged to get media file from shared url
    model_id: any
}

export interface COMPRESS_OPTIONS {
    /** @default Number.POSITIVE_INFINITY */
    maxSizeMB?: number;
    /** @default undefined */
    maxWidthOrHeight?: number;
    /** @default false */
    useWebWorker?: boolean;
    /** @default 10 */
    maxIteration?: number;
    /** Default to be the exif orientation from the image file */
    exifOrientation?: number;
    /** A function takes one progress argument (progress from 0 to 100) */
    onProgress?: (progress: number) => void;
    /** Default to be the original mime type from the image file */
    fileType?: string;
    /** @default 1.0 */
    initialQuality?: number;
}

//_ IMPLEMENT EXTERNAL FUNCTIONS FOR THIS
//_ PENDIENT ... ** need more time **
export const getImageLink = (image: MEDIA_OBJECT, showWhitePicture = true, defaultImage = '') => {
  const mediaPath = ENV.mediaServer + ENV.API_VERSION + '_media';
  try {
    let url = mediaPath + '/' + image.model_id + '/' + image.collection_name + '/' + image.file_name;
    console.log('DEBUG - imageData', { image: JSON.parse(JSON.stringify(image)) });
    return url;
  }
  catch {
    console.log('ERROR BUILDING IMAGE OBJECT', { image })
    return defaultImage;
  }
}

export const getImageLinkByFilename = (filename: string, userId, defaultImage = '') => {
  const mediaObj = createImageObjectFromString(filename, userId);
  // const mediaPath = ENV.mediaServer + ENV.API_VERSION + '_media';
  return getImageLink(mediaObj, false, defaultImage);
}

export const getImageLinkById = (mediaId: string, userId, defaultImage = '') => {
  const mediaPath = ENV.mediaServer + ENV.API_VERSION + '_media';
  const url = mediaPath + '/' + userId + '/getById/' + mediaId;
  return url;
}

export const createImageObjectFromString = (filename: string, userId: number) => {
  const collection = filename.split("---")[0];
  const imgObject = createImageObject(
    collection,
    filename,
    userId
  );

  return imgObject;
}

export const createImageObject = (collection_name: string, file_name: string, userId: number) => {
  console.log('DEBUG - createImageObject 1', { userId });
  return {
      model_id: userId,
      collection_name,
      file_name
  }
}



const NAMESPACE = 'd3b07384-d9a0-4d7c-88dd-7b34e50e0b35';
export const Generate_UUID_for_Media = (url) => {
  // Generate a UUID based on the URL and the namespace
  return uuidv5(url, NAMESPACE);
}
