import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { StorageService as Storage } from './../../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
// import { LngLatBounds } from "mapbox-gl";
// import { SideBarComponent } from "src/app/components/side-bar/side-bar.component";
// import { DeviceSidebarComponent } from "src/app/members/map/device-sidebar/device-sidebar.component";

import { Constants } from '../../constants.enum';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { sidebarService } from './../../components/side-bar/side-bar.service';
import { ApiService } from './../../services/api.service';
import { NotificationsService } from './../../services/notifications.service';
import { MapComponent } from './components/map/map.component';
import { DeviceListService } from './devices-sidebar/devices-list/device-list.service';
import { AlertService } from './devices-sidebar/device-item/device-submenu/alert/alert.service';
import { RouteService } from './devices-sidebar/device-item/device-submenu/route/route.service';
import { DeviceDataService } from './devicesData.service';
import { AppService } from 'src/app/app.service';
import { Effects } from './components/map/class/effects';
import { PopupComponent } from './components/popup/popup.component';
import { DynamicComponentService } from './components/dinamic.component.service';
import { DeviceSubmenuService } from './devices-sidebar/device-item/device-submenu/device-submenu.service';
import { MapService, roundCoordinates } from './components/map/map.service';
import { RotateMapCamera, getDistance } from './components/map/class/mapUtil';
import {
  TimerCountComponent,
  statusType,
} from '../generate-video/timer-count/timer-count.component';
import { TourService } from 'src/app/components/tour/tour.service';
import { mapTour } from '../guide-tour/map';
import { TourOptions } from 'src/app/components/tour/tour.component';
import { DetailsPage } from 'src/app/members/notifications/details/details.page';
import { translationsKeys } from 'src/app/members/notifications/notificationsUtil';
import { GlobalEventService } from 'src/app/services/globa-event.service';
import { MediaApiService } from 'src/app/components/media-managment/media-api.service';
import { ChoiceTourComponent } from 'src/app/members/map/components/choice-tour/choice-tour.component';
import { MapGeofenceComponent } from 'src/app/members/map/components/map-geofence/map-geofence.component';
import { debounceTime, filter, first, last, switchMap } from 'rxjs/operators';
import { NontrackingDevicelistComponent } from 'src/app/members/map/components/nontracking-devicelist/nontracking-devicelist.component';
import {
  circle as Tcircle,
  point as Tpoint,
  destination,
  getCoord,
  transformTranslate,
} from '@turf/turf';
import { zoomInAnimation } from 'src/app/animations/ionic-animations';
import { AppModesPopupPage } from './components/app-modes-popup/app-modes-popup.page';
import { DevicesSidebarService } from './devices-sidebar/devices-sidebar.service';
import { NewPullupComponent } from './new-pullup/new-pullup.component';
import { NodataPopupComponent } from 'src/app/members/map/components/nodata-popup/nodata-popup.component';
import { SubmenuActionButtonsComponent } from './components/submenu-action-buttons/submenu-action-buttons.component';
import { NewPullupService } from './new-pullup/pullup.service';
import { DeviceItemComponent } from './devices-sidebar/device-item/device-item.component';
import { DevicesListHomeComponent } from './devices-sidebar/home/home.component';
import { BatteryInfoPopupComponent } from './components/battery-info-popup/battery-info-popup.component';
import { environment as ENV } from './../../../environments/environment';
import {
  STORAGE_SETTINGS,
  StorageSetting,
} from 'src/app/services/storage-settings';
import { TabsService } from 'src/app/tabs/tabs.service';
import { Capacitor } from '@capacitor/core';
import { DevicesDashboardComponent } from './components/devices-dashboard/devices-dashboard.component';
import {
  devicesListFirstTour,
  devicesListTourObj,
} from '../guide-tour/devices-list';
import { App } from '@capacitor/app';
import { DevicesSetupPage } from '../devices-setup/devices-setup.page';
import { UsbFinderPopupComponent } from './components/usb-finder-popup/usb-finder-popup.component';
import { SurveyPopupComponent } from 'src/app/components/survey/survey-popup/survey-popup.component';
import { DeviceNavigationService } from 'src/app/services/device-navigation-service.service';
declare var window: any;
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from '@angular/animations';
import { TestaccountMessageComponent } from './components/testaccount-message/testaccount-message.component';
import { DeviceData } from './components/map/class/deviceData';
import { MapSelectedFeaturesPopupComponent } from './components/map-selected-features-popup/map-selected-features-popup.component';
import { AVAILABLE_SYMBOL_MARKERS } from './components/map/class/features/symbolMarkers';
import { CsPopoverComponent } from 'src/app/components/cspopover/cspopover.component';
import { SliderComponent } from './components/slider/slider.component';
import { of } from 'rxjs';
import {
  MapstylePopupComponent,
  setMapStylesPopupHeightProperty,
  UserMapStyles,
} from '../app-settings/components/mapstyle-popup/mapstyle-popup.component';
import { MapStylesList } from 'src/app/members/map/components/map/class/mapInterfaces';
import { DevicesDashboardService } from './components/devices-dashboard/devices-dashboard.service';
import { ApiServerAlert } from '../admin-main/admin-server-alert/apis';
import {
  checkAlertIsReadUsingTriggeredInterval,
  checkAlertModelsAndLanguage,
  getDeviceModels,
} from 'src/app/components/server-alert/util';
import { PajPopupTemplateComponent } from 'src/app/components/paj-popup-template/paj-popup-template.component';
import { MapWifiPopupComponent } from './components/map-wifi-popup/map-wifi-popup.component';
import { MapMixin } from './map.mixin';
import {
  boxPolygon,
  centerMapOnGeofence,
} from './components/map/class/geofence_center';
import { SetupWifiService } from './devices-sidebar/device-item/device-submenu/command/setup-wifi/setup-wifi.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.page.html',
  styleUrls: ['./map.page.scss'],
  animations: [
    trigger('inOutSliderAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('800ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('800ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MapPage extends MapMixin implements OnInit, AfterViewInit {
  // @ViewChild("pullup") pullup: PullupComponent;
  @ViewChild('pullup') pullup: NewPullupComponent;
  // @ViewChild(SideBarComponent, { static: true }) sideBar: SideBarComponent;
  // @ViewChild(DeviceSidebarComponent, { static: true })
  // deviceSideBar: DeviceSidebarComponent;
  @ViewChild('mainMap') map: MapComponent;
  @ViewChild('countdown') countdown: TimerCountComponent;
  //@ViewChild('mapMarkerMenu') mapMarkerMenu: SubmenuActionButtonsComponent;
  @ViewChild('timerTooltip') timerTooltip: ElementRef;
  _timerTooltip: any;
  @ViewChild('tooltipBadge') tooltipBadge: ElementRef;
  _tooltipBadge: any;

  language: string;
  userID;
  platformType;
  nCount;
  isHidden = true;
  firstTime;
  isGhost: boolean = false;
  subscription;
  _showPullup = false;
  _goToDeviceID = 0;
  selectedDeviceId = null;
  loading = { route: true, snap: false };
  loadingRoute = false;
  mainMenuOpen: boolean = false;
  alarmActivityMenuOpen: boolean = false;
  observables = {};
  disablesCount = [];
  userInfo: any;
  initialized = false;
  mapStyles = [];
  tourClicked = false;

  get showPullup() {
    return this._showPullup;
  }
  set showPullup(value) {
    //_ To notify the status of the device menu, to show the device sidebar tour
    this._showPullup = value;
    this.appService.deviceMenuisOpen.next(this._showPullup);
  }
  get goToDevice() {
    return this._goToDeviceID;
  }
  set goToDevice(value) {
    this._goToDeviceID = value;
  }
  deviceMenuOpen = false;
  // List of devices getted from Devices Data Service
  devices = null;
  enabledDevices: number;
  currentUrl = '';
  routeData: any = {};
  isIos = Capacitor.isNativePlatform() && Capacitor.getPlatform() == 'ios';
  testUser: any;
  lastAppPausedTime = 0;
  MIN_TIME_TO_TRIGGER_LAST_LOCATIONS_AFTER_APP_RESUME = 50; //_ Time in seconds
  appWasPaused = false;
  appWasResumed = false;
  triggerClearDeviceRoute: boolean = false;
  deviceData: DeviceData;
  previousRouteUpdateData = null;
  showCountDown = false;
  @ViewChild(SliderComponent) appSlider: SliderComponent;
  sliderIsManualRoute = false;
  useMapboxLib = false;
  MAX_DATAPOINT_LENGTH_TO_LOAD_IN_MAP = 200000; //_ 200000

  @ViewChild('Content') Content: TemplateRef<any> = null;
  app_modes: any;
  constructor(
    public authService: AuthenticationService,
    public storage: Storage,
    private router: Router,
    public platform: Platform,
    public modalController: ModalController,
    public loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    public menu: MenuController,
    public _translate: TranslateService,
    private notifService: NotificationsService,
    private popover: PopoverController,
    public toastController: ToastController,
    private _deviceListService: DeviceListService,
    private _alertService: AlertService,
    private _routeService: RouteService,
    private _sidebarService: sidebarService,
    private _deviceSubmenuService: DeviceSubmenuService,
    public _devicesService: DeviceDataService,
    public appService: AppService,
    public dinamicComponent: DynamicComponentService,
    private toast: ToastController,
    private mapService: MapService,
    private tourService: TourService,
    private activeRoute: ActivatedRoute,
    private globalEventService: GlobalEventService,
    private mediaService: MediaApiService,
    public modalDevices: ModalController,
    private devicesSidebarService: DevicesSidebarService,
    private pullupService: NewPullupService,
    private tabService: TabsService,
    private deviceNavigation: DeviceNavigationService,
    private renderer: Renderer2,
    public devicesActivityService: DevicesDashboardService,
    private serverAlertsApi: ApiServerAlert,
    public setupWifiService: SetupWifiService,
    public injector: Injector,
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeEvent: any) => {
        this.currentUrl = routeEvent.url;
        this.routeData = routeEvent;
        this.setAnimationStatus(
          this.currentUrl.startsWith('/tabs/map') ||
            this.routeData.urlAfterRedirects == '/tabs/map',
        );
      });
    super(injector);
  }

  //_ Used to stop or resume animation frame to animate some animations on the map component
  setAnimationStatus(status) {
    if (this.map)
      if (status) {
        // this.map.shouldCheckArf = true;
        // this.map.checkArf();
      } else this.map.stopArf();
  }

  //_ Check many things to know if should animate the marker or no
  //_ @Location of the feature to check if is in the viewport of the map
  //_ 4 things to check :: 1. If is activate on settings, 2. If is running ARF, 3. If is in the viewport
  //_ 4. If user is in the /map route
  shouldAnimateMarker(location) {
    //_ Disabled till we check a bug that is crashing the map in ios;
    //_ when animate the marker; it crash and reload the page in ios.
    // return false;
    const markerInView = this.map.pointInView(location);
    const routeIsMap =
      this.currentUrl.startsWith('/tabs/map') ||
      this.routeData.urlAfterRedirects.startsWith('/tabs/map');
    // console.log('NO ANIMATE STATUS', { performance: this.map.performance, isArfRunning: this.map.isArfRunning, markerInView, routeIsMap, currentUrl: this.currentUrl, isIos: this.isIos });
    return (
      !this.map.performance.noAnimatedMarker &&
      this.map.isArfRunning &&
      markerInView &&
      routeIsMap &&
      !this.appWasResumed
      //_ Remove this condition when ios crash is fixed
      // && !this.isIos
    );
  }

  async logoutUser() {
    // this.storage.get("deviceData").then(async (data) => {
    await this.storage.remove(Constants.USER_DATA);
    await this.storage.set(
      Constants.USER_ID,
      this.appService.adminId.get().value,
    );
    this.appService.ghostMode.remove();
    // await this.storage.set("isAdmin", true);
    // await this.storage.remove("deviceData");

    this.storage.remove(Constants.LASTPOINTS);
    //_ Remove all devices sidebar storaged data
    this.devicesSidebarService.devicesFlatList.remove();
    this.devicesSidebarService.groups.remove();
    this.devicesSidebarService.userPermissions.remove();
    this.storage.remove(STORAGE_SETTINGS.DEVICE_ACTIVITY_SELECTED_DEVICE);
    this.storage.remove(STORAGE_SETTINGS.DEVICE_ACTIVITY_TYPE);
    this.devicesSidebarService.resetFilters();

    window.location.href = '/map';
    setTimeout(() => location.reload(), 500);
    // });
  }
  swipe: number = 1;
  ionViewDidEnter() {
    console.log('ION VIEW DID ENTER *');
    let tc = this;
    App.addListener('backButton', () => {
      // console.log("backButton");
      // console.log("this.map.map", tc.map);

      // Check if the map is currently being dragged or zoomed
      if (this.map.map.isMoving() || this.map.map.isZooming()) {
        // If so, cancel the back button action
        return;
      } else {
        // If the map is not being dragged or zoomed, close the app
        if (this.swipe >= 2 && this.platform.url().includes('tabs/map')) {
          this.swipe = 0;
          App.exitApp();
        }
        this.showToast(
          null,
          this._translate.instant('map.swipeExit'),
          1000,
          'dark',
        );
        this.swipe++;
        setTimeout(() => {
          this.swipe = 0;
        }, 3000);
      }
    });
    this.menu.swipeGesture(false, 'mainMenu'); // Disable here too, because if the user back to map with back button no with the menu
    this.onResize();

    this.observables['devicesMenu'] =
      this.appService.deviceMenuisOpen.subscribe((value: boolean) => {
        this.deviceMenuOpen = value;
      });

    this.observables['tabsViewWillEnter'] =
      this.tabService.viewWillEnter.subscribe((r) => {
        this.onResize(); //_ Resize when view enterback to tabs page.
        console.warn('TABS VIEW WILL ENTER');
      });

    if (this.appService.userSettings) {
      const { app_modes } = this.appService.userSettings;
      // console.warn('[DEBUG] app_modes 1', app_modes);
      if (app_modes) {
        this.app_modes = JSON.parse(app_modes);
      }
    }

    this.observables['userSettings'] = this.appService._userSettings$.subscribe(
      (r: any) => {
        if (r.app_modes) {
          // console.warn('[DEBUG] app_modes 2', r.app_modes);
          this.app_modes = r.app_modes;
        }
      },
    );
  }

  async toggle(menuId) {
    // this.showPullup = false;
    let menuOpen = await this.menu.isOpen(menuId);
    menuOpen ? await this.menu.close(menuId) : await this.menu.open(menuId);

    // this.showPullup = false;
    // await this.menu.isOpen("mainMenu").then((val) => {
    //   this.menu.enable(true, "mainMenu");
    //   this.menu.toggle();
    //   this.menu.open("mainMenu");
    // });
  }
  ionViewDidLeave() {
    console.log('ION VIEW DID LEAVE');
    this.showPullup = false;
  }

  ngAfterViewInit() {
    this.initPage();
    console.log('- AFTER VIEW INIT');
    this.observables['mapReady'] = this.map.mapReady.subscribe((r) => {
      if (r) {
        this.showPopupRightClick();
        this.testUser = this.appService.user.isTestAccount;
      }
    });

    // this.map.mapReady.pipe( first() ).subscribe( r => {
    //   //_ Disable searchbar in devices list to prevent auto scrolling
    //   this.map.map.on('mousedown', (ev) => {
    //     this._deviceListService.disableSearchbar.next(true);
    //   });

    //   this.map.map.on('mouseup', (ev) => {
    //     this._deviceListService.disableSearchbar.next(false);
    //   });
    // });

    //_ Open devices list if previoous status is open
    // this.observables['devicesSidebar'] = this.devicesSidebarService.isOpen.get().subscribe( r => {
    //   if (r)
    //     this.menu.open('deviceMenu');
    // })
    this._timerTooltip = this.timerTooltip?.nativeElement;
    this._tooltipBadge = this.tooltipBadge?.nativeElement;
  }

  petMode: boolean = false;
  async ngOnInit() {}

  async initPage() {
    this.petMode = await this.storage.get(Constants.PET_MODE);
    // setTimeout(() => {
    //   const newSetting = {...this.appService.userSettings, map_projection: 'globe'};
    //   this.storage.set(Constants.USER_SETTINGS, newSetting);
    //   this.appService._userSettings.next(newSetting)
    // }, 10000)
    setTimeout(() => (this.initialized = true), 300);
    this.userInfo = Object.assign({}, await this.getUserInfo());
    this.mapStyles = Object.keys(MapStylesList).map((k) => MapStylesList[k]);
    console.log('USER IN MAP', this.userInfo);
    this.showCountDown = this.appService.userSettings?.show_timer_map ?? true;

    this.showNonTrackingMessage();
    this.openDevicesSetup();

    this.getScreenSize();
    // this.showNoDataPopUp('TEST poopup 1', 'Test poopup title');
    //_ Initialize devicesService to load all devices data
    this._devicesService.init();

    if (this.activeRoute.snapshot.params['startTour'] == 'start-map-tour') {
      await this.storage.set('tour.map', false);
    }

    this.menu.enable(true, 'deviceMenu');
    this.menu.enable(true, 'mainMenu');
    this.menu.close('mainMenu');

    //if (!this.appService.user)
    //  await this.storage.get(Constants.USER_ID).then(r => {
    //    if (r)
    //      this.appService._userId.next(r);
    //  });

    /*this.storage.get('folderArray').then(res => {
      if (res instanceof Array) {
        res.forEach(function (part, index, theArray) {
          if (theArray[index]) {
            theArray[index].folderOpen = false;
          }
        });
        this.storage.set('folderArray', res);
      }
    });*/

    this.platformType = this.platform.is('android')
      ? 'android'
      : this.platform.is('ios')
        ? 'ios'
        : 'android';

    this.authService.initUserData();

    this.platformType = this.platform.is('android')
      ? 'android'
      : this.platform.is('ios')
        ? 'ios'
        : 'android';
    //this._sidebarService.updateUserInfo.next(true);

    this.isGhost = await this.appService.isGhostMode();
    console.log('DEBUG isGhost', this.isGhost);
    const showAppMode = (user) => {
      this.storage.get(Constants.SHOW_APP_MODE_OPTION).then((res) => {
        if (res == null && user.super_user == 1) {
          //_ ENABLE TO SHOW THE APP MODES POPUP
          //setTimeout(() => this.showSupportedAppModes(), 5000);
        }
      });
    };

    if (this.appService.user) showAppMode(this.appService.user);
    else {
      this.appService.user$.pipe(first()).subscribe((user) => {
        showAppMode(user);
      });
    }

    // this.appService._user.subscribe((user:any) => {
    //   if(user['company'] != null) {
    //     this.storage.get(Constants.BUSINESS_MODE).then((res) => {
    //       if(res == null){
    //         this.storage.set(Constants.BUSINESS_MODE, 1);
    //         this.appService.appModeChanged.next(true);
    //         }
    //       });

    //   }
    // });
    // Initialize the map
    this.initializeMap();

    //_ This is very important, many code uses selectedDeviceId
    //_ Previously it was only changed when flyed to the device position
    //_ Now it trigger when you select the device on the devices list
    this.observables['deviceSelected'] =
      this._deviceListService.deviceSelect$.subscribe((id) => {
        this.selectedDeviceId = id;
      });

    this.observables['showPullup'] = this.mapService.showPullup.subscribe(
      (value) => {
        this.showPullup = value;
      },
    );

    // Assign drawer for alarm component
    // And the map
    this.observables['mapDrawer'] = this.mapService._drawer.subscribe((dr) => {
      this._alertService.drawer = this.map.geofenceDrawer;
      this._deviceListService.drawer = this.map.geofenceDrawer;
      this._deviceSubmenuService.map = this.map;

      if (this.map) {
        this.observables['mapisPanned'] = this.map.panFeature.isPan$.subscribe(
          (pan) => {
            //_ Stop slider playing when move the map
            if (this.appSlider?.playing) this.appSlider?.play();

            if (this._devicesService.devices[this.selectedDeviceId]) {
              console.log('PAN THE MAP', {
                deviceFocus:
                  this._devicesService.devices[this.selectedDeviceId].autoFocus,
              });
              if (
                this._devicesService.devices[this.selectedDeviceId].autoFocus
              ) {
                this._devicesService.devices[this.selectedDeviceId].autoFocus =
                  false;
                this.appService.showToast(
                  '',
                  this._translate.instant(
                    'deviceManagement.autoFocusDeActivated',
                  ),
                  2000,
                  'danger',
                );
              }
            }
          },
        );
      }
    });

    // this.notifService.initialize();

    // Load listeners for devices sidebar, map component and submenu components ...
    this.loadListeners();

    // this.startTour();
    setTimeout(() => {
      this.observables['mapTour'] = this._sidebarService.choiceTour.subscribe(
        (r) => {
          this.tourClicked = true;
          this.choiceTour(this.tourClicked);
        },
      );

      console.warn('DEBUG - Starting choose tour popup');
      if (this.router.url.includes('/tab/map'))
        this.choiceTour(this.tourClicked);
    }, 5000);

    this.globalEventService.getMessageCode().subscribe((data) => {
      if (data == 'STOP_BACKGROUND_API') {
        this.countdown?.stop();
        this._devicesService.liveTrackinHandler.stopAll();
      }
    });

    //_ Flag to bypass first step of Devices Tour
    this.storage.remove('SHOULD_IGNORE_FIRST_STEP');

    //show survey popup if the value is null in the db
    // this.observables["surveyCheck"] = this.appService._userSettings$
    //   .pipe(first())
    //   .subscribe((r: any) => {
    //     // this.authService.updateUserSettings(this.appService.user.id, {survey_response:null}).then((r: any) => {})
    //     if (
    //       this.appService.userSettings.survey_response == null &&
    //       this.router.url.includes("tabs/map")
    //     ) {
    //       this.showSurveyPopup();
    //     }
    //   });

    this.observables['settingsUpdated'] =
      this.appService._userSettings$.subscribe((r: any) => {
        this.hideMapPopup();
        this.showCountDown = r.show_timer_map;
      });

    //_ Handle app resume and pause
    this.platform.ready().then((res) => {
      this.platform.pause.subscribe(async () => {
        this.appWasPaused = true;
        this.lastAppPausedTime = new Date().getTime() / 1000;
      });

      this.platform.resume.subscribe(async () => {
        console.log('APP RESUMEd was paused =', this.appWasPaused)
        if (this.appWasPaused) {
          this.appWasPaused = false;
          this.appWasResumed = true;
          //_ Check if is an update running in this time
          if (!this.loading.route && !this.loading.snap) {
            this._devicesService.updateLastPosition();
            // this._devicesService.startTimerUpdate();
            // this._devicesService.updateLastPosition();
          }
        }
      });
    });

    //_ Call methods when are already loaded the app devices
    // if (this.appService.devices) {
    //   this.checkIfShouldShowSurvey(this.appService.devices);
    // } else {
    //   this.appService.devices$.pipe(first()).subscribe((devices) => {
    //     this.checkIfShouldShowSurvey(devices);
    //   });
    // }
  }

  async animateLocation(location) {
    this.map.map.flyTo({
      center: [location[0], location[1]],
      zoom: 13,
      bearing: 0,
      pitch: 76,
      duration: 10000,
      essential: true,
    });

    await this.delay(10000);

    await RotateMapCamera(10, this.map.map);

    this.map.map.flyTo({
      center: [location[0], location[1]],
      zoom: 1,
      bearing: -8.01,
      pitch: 1.5,
      duration: 7000,
      essential: true,
    });

    console.log('MAP PITCH AND BEARING', {
      zoom: this.map.map.getZoom(),
      pitch: this.map.map.getPitch(),
      bearing: this.map.map.getBearing(),
    });
  }

  async showSupportedAppModes() {
    const modal = await this.modalController.create({
      component: AppModesPopupPage,
      mode: 'ios',
      componentProps: {},
    });

    await modal.present();
    await modal.onWillDismiss();
    this.storage.set(Constants.SHOW_APP_MODE_OPTION, false);
  }

  ngOnDestroy() {
    this._devicesService.allPointsReady.observers = [];
    this._devicesService._loadingData.observers = [];
    Object.keys(this.observables).forEach((obs: any) =>
      this.observables[obs]?.unsubscribe(),
    );
  }

  markersLoaded = false;
  updatingMapSources = null;
  initializeMap() {
    this.observables['loadLastPositions'] =
      this._devicesService.lastPositionsReady.subscribe(async (devices) => {
        this.markersLoaded = true;
        this.devices = this._devicesService.onlyDevices;
        this.enabledDevices = this._devicesService.enabledDevicesIds.length;
        this._deviceListService._devicesReady.next(this.devices);
        this._deviceListService.deviceData = this.devices;

        this.noDataDevice(devices);

        //_ Call function to load the markers on the map
        if (this.map.mapReady.value) {
          this.addMarkers(devices);
          this.fitDeviceMarkersBounds();
        } else {
          this.map.mapReady.subscribe((r) => {
            if (r) {
              this.addMarkers(devices);
              this.fitDeviceMarkersBounds();

              // **** IMPORTANT ****
              // After added devices data, then update the map
              // this.map.updateDataSources({ directions: true, markersAndRoutes: true });
            }
          });
        }
      });

    // Load devices data into the map
    // For first time to load all data in the map
    // InitializeMap from deviceService only is called once
    this.observables['initializeMap'] =
      this._devicesService.initializeMap$.subscribe(async (devices) => {
        if (this.markersLoaded) {
          Object.keys(devices).forEach((d) => this.map._moveMarker(d, true));
        } else {
          this.devices = this._devicesService.onlyDevices;
          this._deviceListService._devicesReady.next(this.devices);
          this._deviceListService.deviceData = this.devices;

          //_ Call function to load the markers on the map
          if (this.map.mapReady.value) {
            this.addMarkers(devices);
            this.fitDeviceMarkersBounds();
          } else {
            this.map.mapReady.subscribe((r) => {
              if (r) {
                this.addMarkers(devices);
                this.fitDeviceMarkersBounds();
              }
            });
          }
        }
        this.observables['loadLastPositions'].unsubscribe();
        this.showBatteryInfoPopupForPetFinder();
        this.showChargerInfoPopupForUsbFinder();
      });

    //_ Add all datapoints
    //_ Here add all polylines
    this.observables['allPointsReady'] =
      this._devicesService.allPointsReady.subscribe((devices: any) => {
        const dataPointsLength = this._devicesService.getAllDataPointsLength();
        //_ Load/Unload directionsSource
        if (dataPointsLength > this.MAX_DATAPOINT_LENGTH_TO_LOAD_IN_MAP) {
          this.map.unloadDirectionsSource();
          //_ OPEN_TOO_MUCH_DATA_POPUP
          this.openTooMuchDataPopup();
        } else {
          this.map.loadDirectionsSource();
        }

        Object.values(devices).forEach((d: DeviceData) => {
          // Create polylines
          // Last flag is for no Update the map
          this.map._addDataPoints(d, false, false, false);
          d.newDataPoints = [];

          // Create direction markers
          // Last flag is for no update the map
          if (dataPointsLength <= this.MAX_DATAPOINT_LENGTH_TO_LOAD_IN_MAP) {
            this.map._addDirectionMarkers(d, false, false, false);
            d.newDirectionMarkers = [];
          }
        });

        // **** IMPORTANT ****
        // After added devices data, then update the map
        this.map.updateDataSources({
          directions: true,
          markersAndRoutes: true,
        });

        this.loading.snap = true;
        // Get snaped points
        // And pause points
        for (let key in devices) {
          // uncoment for testing
          let d = devices[key];

          // console.log('DATAPOINTS OF ' + d.properties.name, { length: d.dataPoints.length, datapoints: d.dataPoints })
          //_ Request pauses for the device
          if (d.dataPoints.length > 0) {
            this._devicesService
              .getSnapedAndPausePoints(d.dataPoints, 0.05, false, d)
              .then((res: any) => {
                d.pauseMarkers = res.pauses;
                d.newPauseMarkers = d.pauseMarkers;
                if (d.newPauseMarkers.length > 0) this.map._addPauseMarker(d);
                d.newPauseMarkers = [];
              });
          }

          //_ Request snaped points in case user settings has enabled it
          if (
            d.dataPoints.length > 0 &&
            d.properties.deviceshow &&
            this._devicesService.user.snap_to_road &&
            !this.mapService.mapMonitorMode.value
          ) {
            this._devicesService
              .getSnapedAndPausePoints(d.dataPoints, 0.05, true, d)
              .then((res: any) => {
                d.snapedPoints = res.snapPoints;
                d.newSnapedPoints = d.snapedPoints;

                // If snaped to the route flag  is active and if the valhalla response has snaped points
                if (
                  this._devicesService.user.snap_to_road &&
                  d.snapedPoints.length > 0
                )
                  this.map._addDataPoints(d, true, false, true); // True flag to delete all datapoints in the feature

                // Setted new snapd and pauses points
                d.newDirectionMarkers = [];
                d.newSnapedPoints = [];
              });
          }
        }

        this.loading.snap = false;
      });

    this.observables['geofencesReady'] =
      this._devicesService.geofencesReady.subscribe((devices: any) => {
        // Add geofences - create source features
        Object.values(devices).forEach((d) => {
          this.map._addGeofence(d);
        });

        // Draw the geofence features in the map using geofensesDrawer
        if (this.map.geofenceDrawer) {
          this.map.drawGeofences();
        } else {
          this.mapService.drawer$.subscribe((res) => {
            if (res) this.map.drawGeofences();
          });
        }
      });

    // Select first device to use in the sidebar menu :(
    //let selectDevice = this._devicesService.onlyDevices.find(d => { return d.deviceshow == true; });
    //if (selectDevice) {
    //  const objData = { deviceID: selectDevice.id, deviceName: selectDevice.name, radioIndex: 0 };
    //  this.storage.set('selected-device-data', objData);
    //}
    //---------------------------------------------------
    //});
  }

  // fitDeviceMarkersBounds() {
  //   let points = this._devicesService.getDevicesBound();
  //   points = points.filter(function (el) {
  //     return el[0] != null && el[1] != null;
  //   });
  //   var bounds: any = points.reduce(
  //     function (bounds, coord) {
  //       return bounds.extend(coord);
  //     },
  //     new this.map.LngLatBounds(points[0], points[0]),
  //   );

  //   const defaultZoom = this.appService.userSettings.default_map_zoom || 14;
  //   if (points.length > 0)
  //     this.map.fitBounds(
  //       this._devicesService.getDevicesBound(),
  //       100,
  //       null,
  //       null,
  //       null,
  //       { maxZoom: defaultZoom },
  //     );
  // }

  async openTooMuchDataPopup() {
    const popup = await this.popover.create({
      componentProps: {
        headerTitle: this._translate.instant('map.performancePopup.title'),
        contentTemplate: this.Content,
      },
      component: PajPopupTemplateComponent,
      cssClass: 'paj-popover-template',
      translucent: true,
    });

    await popup.present();
    await popup.onWillDismiss();
  }

  async closePopUp() {
    await this.popover.dismiss();
  }

  async noDataDevice(devices) {
    if (this._devicesService.devicesIds.length == 0) {
      this.showNoDataPopUp(
        'noDataPopUp.noDeviceAssigned',
        'noDataPopUp.noDevice',
      );
    } else {
      // const allLoastLocations = devices.map(d => d.lastPoint);
      const allLoastLocations = Object.keys(devices).map(
        (k) => devices[k].lastPoint,
      );
      if (allLoastLocations.length === 0) {
        this.showNoDataPopUp(
          'noDataPopUp.nodataContent',
          'noDataPopUp.noDeviceData',
        );
      }
    }
  }

  loadListeners() {
    //_ Fix map resize after ios/android keyboard is hidden
    window.addEventListener('keyboardDidHide', () => {
      this.onResize();
    });

    this.observables['mainMenuClosed'] = this.appService.menuClosed.subscribe(
      (status) => {
        this.mainMenuOpen = status;
      },
    );

    // ----------------------------------------------------
    // ------------ LISTENERS DEVICE LIST MENU ------------
    //Device List:
    //Toggle Button show / hide device
    this.observables['deviceToggle'] =
      this._deviceListService.deviceToggle$.subscribe((buttonStatus) => {
        this.hideMapPopup();
        this.onToggleDeviceShow(buttonStatus);
      });

    this.observables['allDevicesToggle'] =
      this._deviceListService.allDevicesToggle$.subscribe((buttonStatus) => {
        this.onToggleAllDeviceHideShow(buttonStatus);
      });

    //select device
    this.observables['flyToDevice'] =
      this._deviceListService.flyToDevice.subscribe((deviceID: any) => {
        // console.log('FLY TO DEVICE ' + deviceID);
        this.selectedDeviceId = deviceID;
        //_ Disable autofocus for alldevices
        this._devicesService.disableAllAutofocus();
        // console.log('DEVICE STATUS', { devAutofocus: this._devicesService.devices[deviceID].autoFocus , settingAuto: this.appService.userSettings.autofocus_on_move });

        if (deviceID && this.appService.userSettings.autofocus_on_move)
          this._devicesService.devices[deviceID].autoFocus = true;

        let device = this._devicesService.devices[deviceID];

        // Fly or not fly :)
        // Only fly if the device has at least one point
        // Else show the toast error message
        if (device) {
          if (device.lastPoint) {
            if (this.pullup && this.pullup instanceof NewPullupComponent)
              this.pullup.moveToBreak('bottom');
            // if (this.pullup && this.pullup instanceof PullupComponent) this.pullup.slideDown();

            const animDevice = this.map.getRunningAnimations()[device.id];

            if (
              animDevice?.point ||
              (animDevice?.point && device?.lastLocation)
            ) {
              this.map._flyTo(animDevice.point[0], animDevice.point[1], 16);
            } else {
              this.map._flyTo(
                device.lastLocation[0],
                device.lastLocation[1],
                16,
              );
            }
          } else
            this.showToast(
              null,
              this._translate.instant('map.noDataError'),
              2000,
              'danger',
            );
        }
      });
    // ____________________________________________________

    // ----------------------------------------------------
    // ----- LISTENERS COMPONENTS OF DEVICE LIST MENU -----
    // Alert component
    this.observables['selectGeozone'] =
      this._alertService.selectGeozone$.subscribe((r) => {
        //Manage Alert Geozone events
        if (this.pullup) this.pullup.minimize();
        this.triggerGeozone(r, this.selectedDeviceId);
      });

    this.observables['addGeozone'] = this._alertService.addGeozone$.subscribe(
      (r) => {
        console.log('addGeozone');
        //Manage Alert Geozone events
        if (this.pullup) this.pullup.minimize();
        this.triggerGeozone(r, this.selectedDeviceId);
      },
    );

    // Route component
    this.observables['updateRoute'] = this._routeService.updateRoute$.subscribe(
      (r: any) => {
        this.addNotifications(r);

        console.log('UPDATE ROUTE CALLED', r);
        let currentLength = this._devicesService.getAllDataPointsLength();
        currentLength -=
          this._devicesService.devices[r.deviceId].dataPoints.length;

        //_ Load or Unload directionsMarkers Source in Map
        if (
          currentLength + r.datapoints.length >
          this.MAX_DATAPOINT_LENGTH_TO_LOAD_IN_MAP
        ) {
          this.map.unloadDirectionsSource();
          //_ OPEN_TOO_MUCH_DATA_POPUP
        } else {
          this.map.loadDirectionsSource();
        }

        this.updateRoute(r);
        this.previousRouteUpdateData = r;

        setTimeout(() => {
          if (this.map.heatMapClass?.isEnabled) {
            this.map.toggleHeatMap(null);
            // this.mapService._heatMapMode.next(false);
          }
        }, 500);

        // this.map.RouteSliderAnimationClass.removeSources(); // to remove the car marker and line when new route updated
      },
    );

    // Get loading route event from Route component
    this.observables['loadingRoute'] =
      this._routeService.loadingRoute$.subscribe((r: any) => {
        console.log('LOADING ROUTE triggered', {
          r,
          showSlider: this.showSlider,
        });
        if (r.loading) {
          this.showSlider = false;
          // this.mapService.showSlider.next(false);
          this.map.symbolMarkersClass.clearMarkers();
          this.deletePreviousData(r.deviceId, false);
          this.loading = { route: r.loading, snap: false };
          this._devicesService.stopTimerUpdate();
          this._devicesService._loadingData.next(true);
        }
        //else
        //  this._devicesService.startTimerUpdate();
      });

    // ____________________________________________________

    //Update notifications counter:
    this.observables['counterChanged'] =
      this.notifService.counterChanged.subscribe(async (n: any) => {
        let notifications: any = await this.notifService.getNotificationsCount(
          {},
        );
        this.nCount = notifications.number_of_unread;
        //this.nCount = n;
      });

    //this.events.subscribe('notifications:updatedCounter', n => { this.nCount = n; });

    // All update for the device, pass here -> Update the map for each device when is moving
    // Move the marker, the pulse marker
    // Add new data points, add Directions markers
    // Add snaped to road
    // Add new stoped points
    this.observables['deviceMoved'] =
      this._devicesService.deviceMoved$.subscribe(async (d) => {
        // Request snap points to the route and pauses
      console.log('deviceMoved in Map page ' )
        let distance = getDistance(
          d.realtimePoints[d.realtimePoints.length - 2].lat,
          d.realtimePoints[d.realtimePoints.length - 2].lng,
          d.lastPoint.lat,
          d.lastPoint.lng,
          'K',
        );

        //_ Only request points when distance between lastPoint and new are more than 5 meters
        //_ and there are less than 8 stored points in forPauses variable
        // console.log('SNAP SLIDING', {
        //   deviceId: d.id,
        //   slided: d.realtimePoints.slice((d.newRealtimePoints.length+1)*-1),
        //   realtimePoints: d.realtimePoints,
        //   newRealtimePoints: d.newRealtimePoints
        // });
        //_ Should check monitor mode too
        if (
          distance > 0.005 &&
          this._devicesService.user.snap_to_road &&
          !this.mapService.mapMonitorMode.value
        ) {
          let newSnapedPoints = await this._devicesService.getOnlySnapedPoints(
            d.newRealtimePoints,
            0.005,
            d.properties,
          );
          //_ Only get last N items to select only newRealtimePoints
          // d.realtimePoints.slice((d.newRealtimePoints.length+1)*-1), 0.005
          if (newSnapedPoints.length > 0) {
            d.newSnapedPoints = newSnapedPoints;
            d.realtimeSnapedPoints.push(d.newSnapedPoints);
          }
        }

        //_ Request async pauses after get last 8 points
        if (d.pointsForPauses.length > 7) {
          this._devicesService
            .getSnapedAndPausePoints(d.pointsForPauses, 0.0001, false)
            .then((snapPause) => {
              d.pointsForPauses = [];

              // Add pause markers
              if (snapPause.pauses.length > 0) {
                snapPause.pauses.forEach((p) => d.realtimePauseMarkers.push(p));
                d.newPauseMarkers = snapPause.pauses;
                this.map._addPauseMarker(d, false, true);
              }
            })
            .catch((error) => {
              console.error('[LIVE TRACKING] ERROR FROM VALHALLA RESPONSE');
            });
        }

        // If setting snap to road is true, then delete all newDataPoints
        if (
          this._devicesService.user.snap_to_road &&
          d.newSnapedPoints.length > 0
        ) {
          d.newDataPoints = [];
          d.newRealtimePoints = [];
        }

        //_ If performance is disable thats means not should animate route and should add the polyline
        let shouldAnimateMarker = this.shouldAnimateMarker(
          d.previousLastLocation,
        );

        //_ Only for testing animation ON
        // this.map.performance.noAnimatedMarker = true;
        // shouldAnimateMarker = true;
        if (!this.map.hasMarker(d.id)) {
          this.map._addMarker(d, null, true);
        }

        //_ Add points or snaped points to the map, last flag is to no Update the map
        const JUST_MOVE_MARKER = () => {
          //_ Only add datapoints if monitor mode is disabled
          // if (!this.mapService.mapMonitorMode.value)
          // this.map._addDataPoints(d, false, true, true);
          this.map._moveMarker(d);

          //_ Move focus
          if (
            d.autoFocus == true &&
            this.appService.userSettings.autofocus_on_move === true
          ) {
            this.map.map.flyTo({
              center: [d.lastLocation[0], d.lastLocation[1]],
            });
            //this.appService.showToast("", this._translate.instant("deviceManagement.autoFocusActivated"), 2000, "success");
          }
        };

        if (!shouldAnimateMarker) JUST_MOVE_MARKER();
        //_ Move the marker with animation is arf and if marker is in the map view
        //_ Only animate when new Points length < 4 (1=last(prevPoint), 2=new, 3=new)
        else if (d.newSnapedPoints.length < 4 || d.newRealtimePoints.length < 4)
          this.map.animateMarker(d);
        else JUST_MOVE_MARKER();

        // Need to check if still online the device
        // This function move the position of the online effect marker
        if (d.online)
          this.map._onlineEffect(
            d,
            new Effects().PulseShadow(
              this.map.map,
              '#000',
              d.properties.spurfarbe,
              this.mapService.use3dMarkers.value ? 200 : 80,
            ),
            true,
          );

        //_ Add the point and the direction to the map, last flag is for not update the map
        //_ If performance is disable thats means not should animate route and should add route markers
        //_ Also check if monitorMode is false to add the direction marker and route lines
        if (!shouldAnimateMarker && !this.mapService.mapMonitorMode.value) {
          this.map._addDirectionMarkers(d, false, true, false);
          this.map._addDataPoints(d, false, true, false);
        }

        // Delete new datapoints and new directions markers
        d.newDataPoints = [];
        d.newDirectionMarkers = [];
        d.newSnapedPoints = [];
        d.newPauseMarkers = [];
        d.newRealtimePoints = [];

        // After added the new data, then update the map
        if (this.updatingMapSources) clearTimeout(this.updatingMapSources);

        this.updatingMapSources = setTimeout(() => {
          this.map.updateDataSources({
            directions: true,
            markersAndRoutes: true,
          });
        }, 300);

        this.appWasResumed = false;
      });

    // Change status only is trigger when the status change
    // No when is moving the marker when has new point !
    this.observables['statusChanged'] =
      this._devicesService.statusChanged$.subscribe((d) => {
        if (d.online)
          this.map._onlineEffect(
            d,
            new Effects().PulseShadow(
              this.map.map,
              '#000',
              d.properties.spurfarbe,
              this.mapService.use3dMarkers.value ? 200 : 80,
            ),
            true,
          );
        else this.map._deleteEffect(d);
      });

    // Loading data flag to show/hide spinner
    this.observables['_loadingData'] =
      this._devicesService._loadingData.subscribe((ld) => {
        this.loading.route = ld;
        this.countdown?.stop();
        this.countdown?.countDown(0, 0, 10);
        if (!ld) {
          this.lastAppPausedTime = 0;
          this.countdown?.start();
        }
      });

    //_ Update device color and image -> when change in device managment
    this.observables['deviceUpdated'] =
      this._devicesService.deviceUpdated$.subscribe((d) => {
        this.map.updateDevice(d);
      });
    // ____________________________________________________

    //_ Notifications on the map
    this.observables['notificationsReady'] =
      this._devicesService.notificationsReady.subscribe((devices: any) => {
        Object.values(devices).forEach((d: any) => {
          if (d.notifications.length > 0) this.map._addNotifications(d);
        });
      });

    //_ Add wifiHome markers
    this.observables['wifiMarkersReady'] =
      this._devicesService.wifiHomeMarkersReady.subscribe((devices: any) => {
        Object.values(devices).forEach((d: any) => {
          if (d.wifiMarkers.length > 0)
            this.map.wifiHomeMarkers.addMarkersForDevice(d, true);
        });
      });

    this.observables['clearAllData'] = this.mapService.clearAllData.subscribe(
      (clear) => {
        if (clear) {
          this._devicesService._loadingData.next(true);
          this._devicesService.stopTimerUpdate();
          this.map.clearAllData({ markers: false });
        }
      },
    );

    //_ Listen for activity popup open status
    this.observables['activityPopupIsOpen'] =
      this.devicesActivityService.openInMobiles.subscribe((status) => {
        if (status === false) {
          this.onResize();
        }
      });

    this.observables['wifiEditPopup'] =
      this.setupWifiService.openEditWifiModal.subscribe((wifiFeature: any) => {
        const lat = wifiFeature.feature.properties.lat;
        const lng = wifiFeature.feature.properties.lng;
        this.map.map.flyTo({
          center: [lng, lat],
          zoom: 13,
          //bearing: 0,
          //pitch: 76,
          duration: 0,
          essential: true,
        });

        if (this.isSmallScreen) {
          this.closePullup();
        }
        this.openWifiHomeInfoPopup(wifiFeature);
      });
  }

  // Open the mapbox popup with the content of popupComponent
  // Inject data into the component and add the html to the map popup
  async clickFeature(e, isContextmenu = false) {
    setTimeout(async () => {
      if (this.map.geofenceDrawer.getMode() !== 'static') return;
      console.log('e.features', {
        features: e.features,
        zoom: this.map.map.getZoom(),
      });
      //_ Open marker list popup only when has more than 1 feature and zoom is >= 15
      if (e.features?.length > 1 && this.map.map.getZoom() >= 15) {
        this.openSelectMapFeatureOnClick(e.features);
        return;
      }

      const feature = e.feature;
      const latLng = e.e?.lngLat;

      //console.log('EVENT EE', e)
      const properties = feature.properties;

      //_ HERE ADD CODE TO OPEN THE WIFI HOME INFO POPUP
      if (feature.layer.id == 'wifiHomeLayer') {
        //_ OPEN POPUP
        this.openWifiHomeInfoPopup(e);
        // console.log('[DEBUG] Opening wifi home info');
        return;
      }

      if (feature.layer.id == 'alarms') {
        const alarm = this._devicesService.devices[
          properties.deviceId
        ].notifications.find((n) => n.id == properties.alarmId);
        if (alarm) this.showDetail(alarm);
        return false;
      }

      if (
        feature.layer.id == 'markers' ||
        feature.layer.id == 'carMarkerLayer'
      ) {
        //&& !this.isSmallScreen && !isContextmenu
        //if (this.mapMarkerMenu) {
        // this.mapMarkerMenu.openMenu(feature);
        //}

        //_ Used to navigate to previous selected item in pullup menu (because right now is destroying when hide/show in dom)
        //_ Wait a bit till pullup is loaded to set selectedDevice for next time pullup menu is open
        const isDifferentDevice =
          this.devicesSidebarService.selectedDevice.value !=
          properties.deviceId;
        if (
          isDifferentDevice ||
          this.devicesSidebarService.activePage == 'APP-DEVICES-LIST-HOME'
        ) {
          this.devicesSidebarService.selectedDevice.next(null);
          await this.openDeviceMenu();
          this.devicesSidebarService.selectedDevice.next(properties.deviceId);
        } else await this.openDeviceMenu();

        //await this.delay(200);
        setTimeout(() => {
          if (this.map.geofenceDrawer.getMode() !== 'static') return;

          if (feature.layer.id == 'carMarkerLayer') return;
          this.flyToLastPosition({ device_id: properties.deviceId });
          this.pullup?.moveToBreak('middle');
        }, 200);

        // Navigate to device view
        if (isDifferentDevice)
          this.deviceNavigation.navigateToDevice(properties.deviceId);
        return false;
      }

      //_ Select point in slider
      if (
        feature.source == 'directionsSource' ||
        (feature.source == 'symbolsSource' &&
          (feature.properties['image'] ==
            AVAILABLE_SYMBOL_MARKERS['start-marker'] ||
            feature.properties['image'] ==
              AVAILABLE_SYMBOL_MARKERS['end-marker']))
      ) {
        if (this.showSlider) {
          this._routeService.selectSliderPoint.next(feature.properties);
          setTimeout(() => {
            if (feature.layer.id == 'directions') {
              this.map.flyTo(latLng?.lng, latLng?.lat, null, 1);
            }
          }, 800);
        }
      }

      let popupContent = this.dinamicComponent.injectComponent(
        PopupComponent,
        (x) => ((x.data = e), (x.map = this.map.map)),
      );
      //console.log('popupContent', popupContent)
      this.hideMapPopup();

      const popupOffset =
        feature.layer.id == 'markers' || feature.layer.id == 'markers3d'
          ? 50
          : 25;

      setTimeout(() => {
        let popup = new this.map.Popup({
          offset: popupOffset,
          className: 'new-map-popup',
        })
          .setLngLat(feature.geometry.coordinates)
          .setDOMContent(popupContent)
          .addTo(this.map.map);

        const popupCont = document.getElementsByClassName('mapboxgl-popup');
        if (popupCont.length > 1) {
          popupCont[0].remove();
        }
      }, 200);
    }, 3);

    // const mapPage: any = document.querySelector('app-map');
    // if (mapPage){
    //   const ionHeader = mapPage.querySelector('.map-page-toolbar');
    //   console.log('MAP HEADER', ionHeader);
    //   if (ionHeader)
    //     ionHeader.prepend(el);
    // }
    // const element: any = document.createElement('div');
    // await this.delay(1000);
    // console.log('ELEMENT', el);
    // el.setAttribute('style', 'max-width: 400px; max-height: 400px; position: absolute;');
    // el.append(popupContent);
  }

  flyToLastPosition(device) {
    //function for show the last position of the device when clicked on the marker on the map
    if (device.device_id) {
      if (this._devicesService.devices[device.device_id].lastPoint) {
        this._deviceListService.flyToDevice.next(device.device_id);
      } else {
        setTimeout(() => {
          this._deviceListService.flyToDevice.next(device.device_id);
        }, 1000);
      }
    }
  }

  async openDeviceMenu() {
    if (this.isSmallScreen) {
      // Pullup Device Menu
      this.showPullup = true; //Open device pullup menu
    } else {
      // Desktop Device Menu
      this.showPullup = false;
      await this.menu.enable(true, 'deviceMenu');
      await this.menu.open('deviceMenu');
    }
    this.appService.deviceMenuisOpen.next(true);
  }

  showPopupRightClick() {
    let tc = this;
    this.map.map.on('contextmenu', function (e) {
      let features = tc.map.map.queryRenderedFeatures(e.point);
      if (features.length == 0) {
        return;
      }
      if (features[0].layer.id == 'markers') {
        const feature = {
          e: e,
          feature: features[0],
          screenBounds: tc.map.getBoundingMarker(features[0]),
        };
        tc.clickFeature(feature, true);
      }
    });
  }

  openSelectMapFeatureOnClick(features) {
    console.log('openSelectMapFeatureOnClick');
    const onClickFeature = (feature) => {
      this.clickFeature({ features: [feature], feature });
      this.hideMapPopup();
    };

    let popupContent: any = this.dinamicComponent.injectComponent(
      MapSelectedFeaturesPopupComponent,
      (x) => (
        (x.features = features),
        (x.map = this.map.map),
        x.onSelectMarker.subscribe(onClickFeature)
      ),
    );

    // console.log('popupContent', popupContent)
    setTimeout(() => {
      new this.map.Popup({ offset: 50, className: 'new-map-popup' })
        .setLngLat(features[0].geometry.coordinates)
        .setDOMContent(popupContent)
        .addTo(this.map.map);

      const popupCont = document.getElementsByClassName('mapboxgl-popup');
      if (popupCont.length > 1) popupCont[0].remove();
    }, 200);
  }

  // Open notifications list
  showNotifications(ev) {
    this.popover
      .create({
        component: NotificationsListComponent,
        cssClass: 'notifications-popover',
        componentProps: {
          type: 'popover',
        },
        event: ev,
        translucent: false,
        mode: 'ios',
      })
      .then(async (popOverElement) => {
        await popOverElement.present();
      });
  }

  async ionViewWillEnter() {
    console.log('ION VIEW WILL ENTER *');
    // LOOK INTO THIS CODE, TO KNOW WHAT IS DOING ...
    this.subscription = this.platform.backButton.subscribe(async (eve) => {
      if (
        (this.router.url === '/map' ||
          this.router.url === '/splash' ||
          this.router.url === '/login') &&
        this.authService.initClose === 0
      ) {
        await navigator['app'].exitApp();
      } else {
        if (this.router.url === '/splash' || this.router.url === '/login') {
          await navigator['app'].exitApp();
        }
        this.authService.initClose--;
      }
    });
    if (this.map.map) {
      setTimeout(() => {
        this.map.map.resize();
      }, 200);
    }
  }

  ionViewWillLeave() {
    console.log('ION VIEW WILL LEAVE');
    this.authService.initClose = 2;
    // App.removeAllListeners();
  }

  async onToggleAllDeviceHideShow(devicesData) {
    devicesData.forEach((data) => {
      this.handleDeviceHideShow(data);
    });
  }

  routeUpdate(res) {
    console.log('routeUpdate', res);
    this.updateRoute(res);
  }
  showSlider = false;
  maxSliderPoints = 0;
  sliderDevice = null;
  stepSlider = 0;
  prevDevicesData = [];
  async updateRoute(res) {
    //_ Rollback changes in case sliderView is open and request to render new route in the map
    //_ Should first disable sliderView mode in map and then enable again with new data
    if (this.showSlider) {
      this.map.disableSliderView();
      //_ Clear route start/end marker when is passed as parameter
      this.map.symbolMarkersClass.clearMarkers();
    }

    this.showSlider = false;

    this.mapService.showSlider.next(false);
    this.maxSliderPoints = 0;
    this.sliderDevice = null;
    this.stepSlider = 0;

    // console.warn('UPDATE ROUTE OPTIONS', res);

    //_ Save all device data - before to edit the route, pauses, direction ... and so on.
    if (res.routeFrom === 'activity' || res.routeFrom === 'timeline') {
      this._devicesService.sliderDeviceId = res.deviceId;
      //_ Clean/restore previous devices data stored in prevDevicesData
      this.cleanAndRestorePreviousDevicesData();

      //_ Then save the current data of the device
      this.prevDevicesData[res.deviceId] = JSON.parse(
        JSON.stringify(this._devicesService.devices[res.deviceId]),
      );
    } else {
      this.prevDevicesData = [];
    }

    // 0: Sort datapoints response
    // The data points need to be reorder by the dateunix propertie
    if (res.datapoints) {
      if (res.datapoints.length > 0) {
        // if (!this.map.map.getLayer("scrollDirections"))
        // this.map.map.addLayer(this.map.scrollDirectionsLayer, "clusterBgMarkers");

        res.datapoints = res.datapoints.sort((a, b) =>
          a.dateunix > b.dateunix ? 1 : -1,
        );
        if (!res.notSaveOriginalDataPoints) {
          this._devicesService.devices[res.deviceId].previousDataPoints =
            res.datapoints;
        }

        // Configure slider variables and show the popup
        this.sliderIsManualRoute = res.isManualRoute;
        this.maxSliderPoints = res.datapoints.length - 1;
        this.sliderDevice = this._devicesService.devices[res.deviceId];
        console.log('map.page.new.ts', this.sliderDevice);
        // console.log('SLIDER DEVICE DATA', { sliderDevice: {...this.sliderDevice}, showSlider: this.showSlider })
        this.stepSlider = 0;

        //_ if onlyUpdateRoute is true; not show the slider just update the route
        // if (!res.onlyUpdateRoute) {
        if (!res.hideSlider) {
          this.map.enableSliderView();
          this.showSlider = true;
          this.mapService.showSlider.next(true);
          this._showSlider();
        } else {
          if (this.map.isSliderViewEnable) this.map.disableSliderView();
        }
        // this.map.enableSliderView();
        // this.showSlider = true;
        // this.mapService.showSlider.next(true);

        this.loading.route = true;
        //this._showSlider();

        const dataPointsCoors = await res.datapoints.map((p) => [p.lng, p.lat]);
        // this.map.fitBounds(dataPointsCoors, this.isSmallScreen ? 25 : 100);

        const padding = this.isSmallScreen
          ? { top: 50, right: 50, bottom: 250, left: 50 }
          : { top: 100, right: 500, bottom: 150, left: 100 };
        this.map.fitBounds(dataPointsCoors, padding);
        // }

        //this._showSlider();
      } else {
        this.hideSlider();
        let toastMessage = this._translate.instant(
          'errorToastForDevice.noDateRange',
        );
        if (res.rangeMode == 0) {
          toastMessage = this._translate.instant(
            'errorToastForDevice.noLastMinute',
          );
        }
        if (res.rangeMode == 1) {
          toastMessage = this._translate.instant(
            'errorToastForDevice.noLastPoint',
          );
        }
        this.showToast(
          null,
          this._translate.instant(toastMessage),
          3000,
          'danger',
        );
      }

      // First: update the device data
      this._devicesService.devices[res.deviceId].dataPoints = res.datapoints;
      this._devicesService.devices[res.deviceId].newDataPoints = res.datapoints;

      this._devicesService.devices[res.deviceId].directionMarkers =
        res.datapoints;
      this._devicesService.devices[res.deviceId].newDirectionMarkers =
        res.datapoints;

      //_ -- IMPORTANT -- isOnlySliderData is used as a feature property to not remove those features
      //_ when updateRoute data is not only to show in the slider view
      const comesFromActivity =
        res.routeFrom === 'activity' || res.routeFrom === 'timeline';
      const isOnlySliderData = comesFromActivity;
      const shouldRemoveMapData = !isOnlySliderData;

      this.map.symbolMarkersClass.clearMarkers();
      if (!res.onlyUpdateRoute && res.datapoints.length > 0) {
        //_ Add start end markers
        const device = this._devicesService.devices[res.deviceId];
        const startPoint = res.datapoints[0];
        const endPoint = res.datapoints[res.datapoints?.length - 1];
        this.map.symbolMarkersClass.addMarker(
          AVAILABLE_SYMBOL_MARKERS['start-marker'],
          [startPoint.lng, startPoint.lat],
          false,
          {},
          { p: startPoint, d: device },
        );
        this.map.symbolMarkersClass.addMarker(
          AVAILABLE_SYMBOL_MARKERS['end-marker'],
          [endPoint.lng, endPoint.lat],
          true,
          { iconOffset: [35, 3] },
          { p: endPoint, d: device },
        );
      }

      //this.triggerClearDeviceRoute = comesFromActivity;

      //_ addDataPoints: second flag true, is for delete all datapoints that no are realtime points
      this.map._addDataPoints(
        this._devicesService.devices[res.deviceId],
        shouldRemoveMapData,
        false,
        false,
        { isSliderData: true, isOnlySliderData },
      );

      //_ Second: update the map data
      //_ addDirectionMarkeres: second flag true, is for delete all directions that no are realtime markers
      this.map._addDirectionMarkers(
        this._devicesService.devices[res.deviceId],
        shouldRemoveMapData,
        false,
        false,
        { isSliderData: true, isOnlySliderData },
      );
      this._devicesService.devices[res.deviceId].newDirectionMarkers = [];
      this._devicesService.devices[res.deviceId].newDataPoints = [];

      // update the map sources
      this.map.updateDataSources({ directions: true, markersAndRoutes: true });

      const CONTINUE_FLOW = () => {
        this._devicesService._loadingData.next(false);
        this.loading.snap = false;

        // If rangeMode == 0 or 1 is because the query show the x last minutes or x last points
        // So we need to update the realtime points and directions, to show only the new points
        if (res.rangeMode <= 1) {
          // Asign the last point to the newRealtime and realtimePoints to start again from this point
          let lastPoint = res.datapoints[res.datapoints.length - 1];
          if (lastPoint) {
            lastPoint.iddevice = res.deviceId;
            this._devicesService.devices[res.deviceId].newRealtimePoints = [
              lastPoint,
            ];
            this._devicesService.devices[res.deviceId].realtimePoints = [
              lastPoint,
            ];
            this._devicesService.devices[
              res.deviceId
            ].realtimeDirectionMarkers = [];
            this._devicesService.devices[res.deviceId].lastPoint = lastPoint;
            this._devicesService.devices[res.deviceId].lastLocation = [
              lastPoint.lng,
              lastPoint.lat,
            ];
            // Update marker position and effect position, and add the points
            this.map._moveMarker(this._devicesService.devices[res.deviceId]);
            this._devicesService.updateOnlineStatus(lastPoint);
            this.map._addDataPoints(
              this._devicesService.devices[res.deviceId],
              shouldRemoveMapData,
              true,
              false,
              { isSliderData: true, isOnlySliderData },
            );

            // Delete realtime direction markers
            this.map._addDirectionMarkers(
              this._devicesService.devices[res.deviceId],
              shouldRemoveMapData,
              true,
              false,
              { isSliderData: true, isOnlySliderData },
            );
          }
        }

        // Delete previous new points and directions markers
        this._devicesService.devices[res.deviceId].newDataPoints = [];
        this._devicesService.devices[res.deviceId].newDirectionMarkers = [];

        // Thrid: update the map sources and canvas
        this.map.updateDataSources({
          directions: true,
          markersAndRoutes: true,
        });
        this.loading = { route: false, snap: false };

        this._devicesService.startTimerUpdate();
      };

      //_ Get snaped points
      this.loading.snap = true;

      //_ Request pauses for the device
      this.observables['device-request-snapsAndPauses'] =
        this._devicesService.getSnapedAndPausePoints(
          this._devicesService.devices[res.deviceId].dataPoints,
          0.05,
          this.appService.user.snap_to_road,
          null,
          res.isManualRoute || comesFromActivity,
        );

      this.observables['device-request-snapsAndPauses'].then((resData: any) => {
        // console.log('SNAPS GETTED', { resData, res, });
        this.observables['device-request-snapsAndPauses'].isCompleted = true;
        if (resData.pauses) {
          this._devicesService.devices[res.deviceId].newPauseMarkers =
            resData.pauses;
          this._devicesService.devices[res.deviceId].pauseMarkers =
            resData.pauses;
          this.map._addPauseMarker(
            this._devicesService.devices[res.deviceId],
            shouldRemoveMapData,
            false,
            { isSliderData: this.showSlider, isOnlySliderData },
          );
        }
        if (
          resData.snapPoints.length > 0 &&
          this.appService.user.snap_to_road
        ) {
          this._devicesService.devices[res.deviceId].newSnapedPoints =
            resData.snapPoints;
          this._devicesService.devices[res.deviceId].newDataPoints = [];

          // console.warn('SNAP ADD TO MAP', { isOnlySliderData })

          this.map._addDataPoints(
            this._devicesService.devices[res.deviceId],
            true,
            false,
            true,
            { isSliderData: this.showSlider, isOnlySliderData, isSnap: true },
          );
          this.mapService.sliderSnapedPointsReady.next({
            deviceId: res.deviceId,
            snapPoints: resData.snapPoints,
          });

          if (!isOnlySliderData && this.prevDevicesData[res.deviceId]) {
            // console.log('ADDING SNAPES TO PREV DEVICES DATA', this.prevDevicesData[res.deviceId]);
            this.prevDevicesData[res.deviceId].dataPoints = res.dataPoints;
            this.prevDevicesData[res.deviceId].snapedPoints =
              resData.snapPoints;
          }

          //_ Wait a bit until newSnaped points are added to the map
          setTimeout(() => {
            this._devicesService.devices[res.deviceId].newSnapedPoints = [];
          }, 2000);
        }
        CONTINUE_FLOW();
      });

      this.observables['device-request-snapsAndPauses'].onCancel.subscribe(
        (error) => {
          console.log('CANCELED PROMISE');
          CONTINUE_FLOW();
          this._routeService.canceledValhallaRequest.next(true);
        },
      );
    }
  }

  cleanAndRestorePreviousDevicesData() {
    //_ Clean/restore previous devices data stored in prevDevicesData
    this.prevDevicesData.forEach((device, index) => {
      //_ Save a copy to _devicesService.devices
      this._devicesService.devices[index] = JSON.parse(JSON.stringify(device));
      //_ Remove the element from the array
      this.prevDevicesData.splice(index, 1);
    });
  }

  async updatePausesDevice(device, points = null) {
    if (!points) points = device.dataPoints;
    let pauseSnap = await this._devicesService.getSnapedAndPausePoints(points);
  }

  _showSlider() {
    if (this.pullup) this.pullup.moveLayoutFix(this.pullup.currentBreak);
  }

  //_ Hide the slider and move the buttons in the bottom of the map
  hideSlider() {
    this.showSlider = false;
    this.mapService.showSlider.next(false);

    //_ Remove direction feature on top
    this.map._addScrollDirection(null, true);

    //_ Hide popup if is open
    this.hideMapPopup();

    //_ Move the loading div on top of slider
    document
      .querySelector('.loadingDiv')
      ?.setAttribute('style', 'bottom: 60px !important');

    if (this.pullup) this.pullup.moveLayoutFix(this.pullup.currentBreak);

    //_ Check if it comes from activity popup to stop listening; because snaps will not needed anymore
    //_ Otherwise it should cancel the request
    const comesFromActivity =
      this.previousRouteUpdateData?.routeFrom === 'activity' ||
      this.previousRouteUpdateData?.routeFrom === 'timeline';
    // console.log('HIDDING SLIDER', { comesFromActivity })
    //_ Cancel previous request
    if (
      this.observables['device-request-snapsAndPauses'] &&
      !this.observables['device-request-snapsAndPauses'].isCompleted &&
      comesFromActivity
    )
      this.observables['device-request-snapsAndPauses'].cancel();

    //_ Clear polylines (datapoints), directions markers and pauses when close the slider bar
    if (this.mapService.mapMonitorMode.value) {
      if (this.sliderDevice) {
        const device = this._devicesService.resetDeviceDatapoints(
          this.sliderDevice.id,
        );
        this.map._addDataPoints(device, true, false, true);
        this.map._addDirectionMarkers(device, true, false, true);
        this.map._addPauseMarker(device, true);
      }
      // this.map.filterDirectionsMarkers(true);
    }

    this.cleanAndRestorePreviousDevicesData();
    this.map.disableSliderView();
    this.map.symbolMarkersClass.clearMarkers();
    setTimeout(() => this.map.filterDirectionsMarkers(true), 200);
  }

  // Show the popup selecting the direction point by stepSlider
  // Seaching in map features by 'direction' + deviceId + point.id
  // And sending to the clickFeature function
  prevOpenPopupRequest = null;
  showPopup(e) {
    // console.log('SHOW POPUP EVENT', e);
    if (this.prevOpenPopupRequest) clearTimeout(this.prevOpenPopupRequest);
    this.prevOpenPopupRequest = setTimeout(() => {
      if (this.stepSlider === e.detail.value) return;

      if (e) this.stepSlider = e.detail.value;
      let point = this.sliderDevice.dataPoints[this.stepSlider];

      let propId = 'direction-' + this.sliderDevice.id + '-' + point.id;

      let feature = this.map.searchFeatureById(
        this.map.directionsSource,
        propId,
      );
      if (feature == undefined)
        //_ Search if is new point added to the slider in realtime points
        feature = this.map.searchFeatureById(
          this.map.directionsSource,
          'realtime-' + propId,
        );

      if (!feature) return;

      feature = {
        ...feature,
        ...{
          layer: { id: 'directions' },
          properties: {
            ...feature.properties,
            isSliderData: true,
          },
        },
      }; // Merge object to add layer id for use in popup component

      //   this.map._addScrollDirection(feature, true);
      // this.hideMapPopup(); // Hide the map popup
      //   // Center the map in the point coordinates
      //   this.map.map.flyTo({
      //     center: [point.lng, point.lat],
      //     //duration: 100,
      //     //easing: (t) => t,
      //   });

      // Send the faature as is received when clicked in the map features
      // With e variable with lngLat as an [lng, lat]
      // if (e && !e.showPopup) return false;

      if (e.showPopup) {
        this.showSliderPopup({
          e: { lngLat: feature.geometry.coordinates },
          feature,
        });
      } else {
        this.hideSliderPopup();
        this.hideMapPopup();
        return false;
      }

      // this.clickFeature({
      //   e: { lngLat: feature.geometry.coordinates },
      //   feature,
      // });
    }, 0);
    // this.map.RouteSliderAnimationClass.updateDataSource(this.sliderDevice, this.stepSlider);
  }

  currentPopup = null; // Variable to store the current popup instance

  showSliderPopup(e) {
    const feature = e.feature;
    let popupContent = this.dinamicComponent.injectComponent(
      PopupComponent,
      (x) => ((x.data = e), (x.map = this.map.map)),
    );

    const popupOffset =
      feature.layer.id == 'markers' || feature.layer.id == 'markers3d'
        ? 50
        : 25;

    if (!this.currentPopup) {
      // If no popup is currently open, create a new one
      this.currentPopup = new this.map.Popup({
        offset: popupOffset,
        className: 'new-map-popup',
      })
        .setLngLat(feature.geometry.coordinates)
        .setDOMContent(popupContent)
        .addTo(this.map.map);
    } else {
      // If a popup is already open, update its content
      this.currentPopup
        .setLngLat(feature.geometry.coordinates)
        .setDOMContent(popupContent);
    }

    let that = this;
    that.currentPopup.on('close', () => {
      //console.log('popup was closed');
      that.appSlider?.handleMapEvent({ closed: true });
      this.currentPopup = null; // Reset currentPopup when popup is closed
    });
  }

  hideSliderPopup() {
    if (!this.currentPopup) return;
    this.currentPopup.remove(); // Close the popup
    this.currentPopup = null;
  }

  delay = (ms) => new Promise((r) => setTimeout(r, ms)); //for Delay times
  async devicesMenuChange(e) {
    // this.deviceMenuOpen = e.open;
    if (e.open != this.appService.deviceMenuisOpen.value)
      this.appService.deviceMenuisOpen.next(e.open);
    // await this.delay(300);
    // this.map.map.resize();
  }

  reloadPage() {
    window.location.reload();
  }

  // screenHeight;
  // screenWidth;
  //previousSmallScreen = false;
  isSmallScreen = false;
  toggleDeviceMenuWhenResize = null;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.getScreenSize();
    if (this.map.map) {
      if (this.toggleDeviceMenuWhenResize)
        clearTimeout(this.toggleDeviceMenuWhenResize);
      this.toggleDeviceMenuWhenResize = setTimeout(() => {
        this.map.map?.resize();
        //_ If menu is open, then clos
        // if (this.deviceMenuOpen && this.previousSmallScreen != this.isSmallScreen){
        //   if (this.deviceMenuOpen){
        //   if (this.isSmallScreen)
        //     this.showPullup = false;
        //     setTimeout( () => this.openDeviceMenu(this.devicesSidebarService.selectedDevice.value), 200);

        // }
      }, 500);
    }
    if (this.deviceMenuOpen) {
      setTimeout(() => this.openDeviceMenu(), 200);
    }
  }

  getScreenSize() {
    let sceenSize: boolean = false;
    //this.previousSmallScreen = this.isSmallScreen;
    // this.screenHeight = window.innerHeight;
    // this.screenWidth = window.innerWidth;

    if (this.isSmallScreenSize()) {
      if (window.innerWidth < window.innerHeight) {
        sceenSize = true;
      } else {
        sceenSize = true;
      }
    } else {
      sceenSize = false;
    }

    //this.isSmallScreen = document.documentElement.clientWidth < 769 ? true : false;
    this.isSmallScreen = sceenSize;
  }

  isSmallScreenSize() {
    return window.innerWidth <= 768;
  }

  //_ Second flag to delete all data by this device
  deletePreviousData(deviceId, realtime = false) {
    this.hideMapPopup();
    const device = this._devicesService.devices[deviceId];

    device.notifications = [];
    this.map._addNotifications(device, true);

    device.newPauseMarkers = []; //Fix first charge when valhalla no return response queckly
    this.map._addDataPoints(device, true, realtime, false);
    this.map._addDirectionMarkers(device, true, realtime, false);
    this.map._addScrollDirection(device, true);
    this.map._addPauseMarker(device, true, realtime);
    this.map.updateDataSources({ directions: true, markersAndRoutes: true });
  }

  animatingDeviceMenu = false;
  togglePullUp() {
    this.hideMapPopup();

    this.showPullup = !this.showPullup;
    if (this.showPullup == false) {
      this.pullupService.setBreakPoint.next('middle');
    }

    //_ used to pull to the left (15px) devices menu while is animating
    this.animatingDeviceMenu = true;
    setTimeout(() => (this.animatingDeviceMenu = false), 180);
    // this.appService.deviceMenuisOpen.next(this.showPullup)
  }

  closePullup() {
    this.showPullup = false;
    this.pullupService.setBreakPoint.next('middle');
    // this.appService.deviceMenuisOpen.next(false);
  }

  async choiceTour(tourClicked: boolean) {
    //_ If already exist choice tour, not should show again
    const choiceTour = await this.storage.get('choice.tour');
    const showInLogin = this.appService?.userSettings?.show_tour_at_login
      ? this.appService.userSettings.show_tour_at_login
      : false;

    //if (choiceTour || showInLogin) return;
    if (!tourClicked && (choiceTour || showInLogin)) return;

    const popover = await this.popover.create({
      component: ChoiceTourComponent,
      cssClass: 'choice-tour-popover',
      componentProps: {},
      showBackdrop: true,
      mode: 'ios',
      enterAnimation: zoomInAnimation,
    });
    popover.present();
    const { data } = await popover.onDidDismiss();
    await this.storage.set('choice.tour', true);
    //await new Promise((resolve) => setTimeout(resolve, 300));
    if (data) {
      switch (data) {
        case 'map':
          await this.storage.remove('tour.map');
          this.startTour();
          break;

        case 'devicesList':
          this.startDevicesListTour();
          break;
      }
    }
  }

  devicesListTour = null;
  devicesListTourEnds = null;
  async startDevicesListTour() {
    //_ OPEN DEVICES LIST
    //_ And reset settings to start the other tour
    const openDeviceList = async () => {
      await this.storage.remove('tour.new-devices-list');

      this.devicesSidebarService.selectedDevice.next(null);
      // this.devicesSidebarService.resetFilters();

      if (this.isSmallScreen) {
        this.showPullup = true;
      } else {
        await this.menu.enable(true, 'deviceMenu');
        await this.menu.open('deviceMenu');
      }

     setTimeout(() => this.devicesSidebarService.startDevicesListTour.next(true), 1000);

    };

    const FIRST_TOUR = devicesListTourObj;
    FIRST_TOUR.name = 'FIRST_DEVICES_TOUR';
    FIRST_TOUR.endText = 'deviceLogMap.next';

    if (this.devicesListTour) this.devicesListTour.unsubscribe();

    this.devicesListTour = this.tourService
      .start(devicesListFirstTour(FIRST_TOUR, this._translate))
      .subscribe((step) => {});

    if (this.devicesListTourEnds) this.devicesListTourEnds.unsubscribe();
    this.devicesListTourEnds = this.tourService._end.subscribe((isEnd) => {
      if (isEnd && this.tourService.steps[0].tour.name == FIRST_TOUR.name) {
        this.devicesListTour.unsubscribe();
        this.storage.set(FIRST_TOUR.name, true);

        if (this.tourService._buttonPressed.value == 'end') {
          //_ Flag to bypass first step of Devices List Tour
          this.storage.set('SHOULD_IGNORE_FIRST_STEP', true);
          openDeviceList();
        }
      }
    });
  }

  async startTour() {
    let tour: TourOptions = { name: 'tour.map' }; //_ Feature no fully implemented, just for end tour works now.
    await this.storage.get(tour.name).then((r) => {
      if (!r) {
        // setTimeout((r) => {
        if (
          this.router.url == '/map' ||
          this.router.url == '/map/start-map-tour' ||
          this.router.url == '/tabs/map'
        ) {
          //_ Fix show tour on login screen
          this.tourService
            .start(
              mapTour(tour, this._translate, { isBrowser: this.map.isBrowser }),
            )
            .subscribe((step: any) => {
              if (step.dom == '#menuList') this.menu.open('mainMenu');

              if (step.dom == '.mainMenuButton') this.menu.close('mainMenu');

              if (step.dom == '#map3dview') {
                if (!this.map.fab.activated) {
                  this.map.toggleFabList(true);
                }
              }

              if (step.dom == '.notificationButton')
                this.menu.close('mainMenu');
            });
        }
        // }, 5000);

        this.tourService._end.subscribe((r) => {
          if (this.tourService.steps[0].tour.name == tour.name)
            this.storage.set(tour.name, true);
        });
      }
    });
  }

  //_ Add notificatios by passing return data from updateRoute observable
  addNotifications(data) {
    if (data.datapoints.length > 1 || data.rangeMode == 4) {
      //_ Get data if the device has datapoints
      data.datapoints = data.datapoints.sort((a, b) =>
        a.dateunix > b.dateunix ? 1 : -1,
      );
      //['spurdatum'];
      //$requestInfo['dateEnd'] = $device['spurdatumbis'];

      const startDate =
        data.rangeMode == 4 ? data.startDate : data.datapoints[0].dateunix;
      const endDate =
        data.rangeMode == 4
          ? data.endDate
          : data.datapoints[data.datapoints.length - 1].dateunix;

      this.authService
        .getAllNotifications([{ id: data.deviceId, startDate, endDate }])
        .then((response: any) => {
          //if (response && response.success) {}
          // const notifications = response?.success;
          const notifications = response?.success ?? {};
          Object.keys(notifications).forEach((deviceId) => {
            this._devicesService.devices[deviceId].notifications =
              notifications[deviceId];
          });

          const comesFromActivity =
            data.routeFrom === 'activity' || data.routeFrom === 'timeline';
          const isOnlySliderData = comesFromActivity;
          const shouldRemoveMapData = !isOnlySliderData;

          this.map._addNotifications(
            this._devicesService.devices[data.deviceId],
            shouldRemoveMapData,
            { isSliderData: this.showSlider, isOnlySliderData },
          );

          // this.map._addNotifications(
          //   this._devicesService.devices[data.deviceId],
          //   shouldRemoveMapData,
          //   { isSliderData: true, isOnlySliderData }
          // );
        })
        .catch((error) =>
          this.appService.showToast(
            '',
            this._translate.instant('toast.errorToGetNotification'),
            3000,
            'danger',
          ),
        );
    } else {
      this._devicesService.devices[data.deviceId].notifications = [];
      this.map._addNotifications(
        this._devicesService.devices[data.deviceId],
        true,
      );
    }
  }

  toggleBackDrop($event) {}

  //_ GEOFENCE POPUP

  //_ Geofence Events
  geoMouseUp(ev) {
    this.showGeofencePopup(ev);
  }

  geoMouseDown(ev) {
    this.hideMapPopup();
  }

  geoDragVertex(ev) {
    this.map.geofenceLineIndicatingFeature.updateLineIndication(
      ev.state.feature,
    );
  }

  geoMouseUpVertex(ev) {
    this.map.geofenceLineIndicatingFeature.updateLineIndication(
      ev.state.feature,
    );
  }

  showNonTrackingMessage() {
    const getDevices = (d) => {
      this.disablesCount = d.filter((d) => d.privacy_mode);
    };
    this.appService.devices$
      .pipe(first())
      .subscribe((devices) => getDevices(devices));
    this._devicesService.deviceUpdated$.subscribe((devices) => {
      getDevices(
        Object.values(this._devicesService.devices).map((d) => d.properties),
      );
    });

    if (this.appService.devices?.length > 0) {
      getDevices(this.appService.devices);
    }
  }
  async showNonTrackingDevices() {
    // const modal = await this.modalController.create({
    const modal = await this.popover.create({
      component: NontrackingDevicelistComponent,
      componentProps: { devices: this.disablesCount },
      mode: 'ios',
      cssClass: 'popup-300px',
    });

    await modal.present();
  }

  //_ Autogenerate a point to simulate device motion
  //_ Method to test motion ...
  addPoint() {
    const device =
      this._devicesService.devices[this.map.threedHelper.selectedId];
    const location: any = device.lastLocation;
    const point = Tpoint(location);
    //_ point , distance in km, and bearing / angle in degrees
    const newPoint = transformTranslate(point, 1, Math.random() * 360);

    device.lastLocation = newPoint.geometry.coordinates; //[4.485686, 51.029807]

    this.map.animateMarker(device);
    this._devicesService.stopTimerUpdate();
  }
  async showNoDataPopUp(msg, title) {
    const popup = await this.popover.create({
      component: NodataPopupComponent,
      cssClass: 'paj-popover-template',
      translucent: false,
      backdropDismiss: false,
      mode: 'ios',
      componentProps: { message: msg, title: title },
    });

    await popup.present();
  }

  mapClicked(ev) {
    // const device = this._devicesService.devices[1162524];
    // this.animateLocation(device.lastLocation);
    this.hideMapPopup();
  }

  //_ POPUP only for PET FINDER device model on array ENV.PET_MODELS_WITH_DIFF_CHARGER
  //_ Should show only onece
  //_ Changed the showed status after close it
  async showBatteryInfoPopupForPetFinder() {
    const PET_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED = new StorageSetting(
      this.storage,
      STORAGE_SETTINGS.PET_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED,
      false,
    );
    await this.delay(1000); //_ Wait 1 sec while setting get the storage value

    if (PET_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED.get().value) return;

    let shouldShow = false;
    const devicesModel = Object.keys(this._devicesService.devices).map(
      (id) => this._devicesService.devices[id].deviceModel?.model_nr,
    );
    //_ Check if exist some device model matching ENV array of pet devices for charger
    shouldShow = devicesModel.some((dm) =>
      ENV.PET_MODELS_WITH_DIFF_CHARGER.includes(dm),
    );

    const showPopupAtLogin = this.appService.userSettings.show_tour_at_login;

    if (!shouldShow || showPopupAtLogin) return;

    const popup = await this.popover.create({
      component: BatteryInfoPopupComponent,
      cssClass: 'paj-popover-template',
      translucent: false,
      backdropDismiss: false,
      mode: 'ios',
      componentProps: { title: this._translate.instant('map.petPopup.title') },
    });

    await popup.present();
    await popup.onWillDismiss();
    PET_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED.set(true);
  }

  //_ POPUP only for PET FINDER device model on array ENV.PET_MODELS_WITH_DIFF_CHARGER
  //_ Should show only onece
  //_ Changed the showed status after close it
  async showChargerInfoPopupForUsbFinder() {
    const USB_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED = new StorageSetting(
      this.storage,
      STORAGE_SETTINGS.USB_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED,
      false,
    );
    await this.delay(1000); //_ Wait 1 sec while setting get the storage value

    if (USB_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED.get().value) return;

    let shouldShow = false;
    const devicesModel = Object.keys(this._devicesService.devices).map(
      (id) => this._devicesService.devices[id].deviceModel?.model_nr,
    );
    //_ Check if exist some device model matching ENV array of pet devices for charger
    shouldShow = devicesModel.includes(9083); //_ 9083

    const showPopupAtLogin = this.appService.userSettings.show_tour_at_login;

    if (!shouldShow || showPopupAtLogin) return;

    const popoverObj = await this.popover.create({
      component: UsbFinderPopupComponent,
      cssClass: 'paj-popover-template',
      translucent: false,
      backdropDismiss: false,
      mode: 'ios',
      // componentProps: { title: this._translate.instant("map.petPopup.title") },
    });

    await popoverObj.present();
    await popoverObj.onWillDismiss();
    USB_FINDER_FIRST_TIME_CHARGIN_POPUP_SHOWED.set(true);
  }

  async showDeviceDashboard() {
    //_ Show sidebar for desktop view
    if (!this.isSmallScreen) {
      // if ((await this.menu.getOpen())?.menuId == 'deviceMenu')
      //   await this.menu.close('deviceMenu');

      await this.menu.enable(true, 'devicesDashboard');
      await this.menu.open('devicesDashboard');
      return;
    }

    const modal = await this.modalController.create({
      component: DevicesDashboardComponent, //DeviceDashboardComponent,
      cssClass: 'device-dashboard-popup',
      backdropDismiss: true,
      // mode: "ios",
      componentProps: {},
    });

    await modal.present();
    await modal.onWillDismiss();
  }

  devicesDashboardIsOpen = false;
  devicesDashboardMenuChange(ev) {
    this.devicesDashboardIsOpen = ev.open;
  }

  //_ Logic to open devices setup popup
  async openDevicesSetup() {
    let devices = this.appService.devices?.filter((d) => d.setup_done == false);
    //let devices = this.appService.devices;

    const showDevicesSetup = async (devicesForSetup) => {
      //_ Check if array is empty to just go out
      const modal = await this.modalController.create({
        component: DevicesSetupPage, //DeviceDashboardComponent,
        cssClass: 'device-guide-setup',
        backdropDismiss: false,
        keyboardClose: false,
        componentProps: {
          devices: devicesForSetup,
        },
      });

      await modal.present();
      await modal.onWillDismiss();
    };

    //_ Read devices from appService or wait for it
    if (!devices)
      this.observables['onUserDevicesLoad'] = this.appService.devices$
        .pipe(first())
        .subscribe((userDevices: any) => {
          devices = userDevices.filter((d) => d.setup_done == false);
          if (devices.length > 0) showDevicesSetup(devices);
        });
    else if (devices.length > 0) showDevicesSetup(devices);
  }

  //_ Load survey alerts, and check d.models lang and read status
  // checkIfShouldShowSurvey(devices) {
  //   if (!this.router.url.includes('tabs/map')) return;

  //   const deviceModels = getDeviceModels(devices);
  //   this.serverAlertsApi.getActiveAlerts().then(async (res) => {
  //     // console.log('ALERT TO SHOW 1', res)
  //     if (res.success?.length > 0) {
  //       const surveyAlerts = res.success.filter((al) => al.type === 'survey');
  //       if (surveyAlerts.length === 0) return;

  //       const alert = surveyAlerts[0];
  //       const language = await this.storage.get(Constants.LANGUAGE);
  //       const langAndModelOk = checkAlertModelsAndLanguage(
  //         alert,
  //         deviceModels,
  //         language ?? 'en',
  //       );
  //       const isRead = await checkAlertIsReadUsingTriggeredInterval(
  //         alert,
  //         this.storage,
  //       );
  //       // console.log('ALERT TO SHOW 2', { alert, langAndModelOk, isRead });
  //       if (alert && langAndModelOk && !isRead) {
  //         this.showSurveyPopup(alert);
  //       }
  //     } else {
  //       console.warn('NOT ALERTS TO CHECK.');
  //     }
  //   });
  // }

  // async showSurveyPopup(alertData) {
  //   //if(this.DONT_SHOW_SURVEY_POPUP.get().value) return
  //   const modalObj = await this.popover.create({
  //     component: SurveyPopupComponent,
  //     componentProps: { alert: alertData },
  //     mode: 'ios',
  //     cssClass: 'survey-popup',
  //     //backdropDismiss: false,
  //   });

  //   await modalObj.present();
  // }

  pullupNavigateToDevice(ev) {
    this.deviceNavigation.navigateToDevice(ev);
  }

  async refreshingMapData() {
    this._devicesService.updateLastPosition();
  }

  async showTimerTooltip() {
    if (this._timerTooltip && this._tooltipBadge) {
      const rect = this._timerTooltip.getBoundingClientRect();
      // console.log('Posición top:', rect.top);
      // console.log('Posición left:', rect.left);

      this.renderer.setStyle(this._tooltipBadge, 'visibility', `visible`);
      this.renderer.setStyle(this._tooltipBadge, 'opacity', `1`);
      this.renderer.setStyle(this._tooltipBadge, 'left', `${rect.left + 55}px`);
      this.renderer.setStyle(this._tooltipBadge, 'top', `${rect.top}px`);
    }
  }

  async hideTimerTooltip() {
    if (this._tooltipBadge) {
      this.renderer.setStyle(this._tooltipBadge, 'visibility', 'hidden');
      this.renderer.setStyle(this._tooltipBadge, 'opacity', '0');
    }
  }

  handleMapEvent(event) {
    this.appSlider.handleMapEvent({ event });
  }



  async updateSettings() {
    if (!this.initialized) return false; // Fix first load of user, trigger toggles buttons when changed the model

    return new Promise((resolve, reject) => {
      // Update map style user settings
      if (this.userInfo.map != this.appService.user.map) {
        this.appService.user.map = this.userInfo.map;
        this.mapService._style.next(this.userInfo.map);
        this.mapService.mapStyle.next(this.userInfo.map); // we can remove this when we remove the local storage
      }

      this.authService
        .updateUserMapSetting(
          this.userInfo.route_icons,
          this.userInfo.distance_unit,
          this.userInfo.map,
          this.userInfo.show_kmanzeige,
          this.userInfo.snap_to_road,
          this.userInfo.pauses,
          this.userInfo.show_pauses ? 1 : 0,
        )
        .then((res) => {
          if (res) {
            this.appService.showToast(
              this._translate.instant('deviceManagement.mapSettingsText'),
              this._translate.instant('deviceManagement.mapSettingMessage'),
              2000,
              'success',
            );
            this.appService._settingsChanged.next(this.userInfo);
          }
          resolve(true);
        })
        .catch((error) => {
          console.log('ERROR: ', error);
          this.appService.showToast(
            this._translate.instant('deviceManagement.mapSettingsText'),
            this._translate.instant('deviceManagement.ErrorSettingMessage'),
            2000,
            'danger',
          );
          reject(error);
        });
    });
  }

  async showMapStylePopup(event) {
    console.log('showMapStylePopup in map');

    const useMapStyles = UserMapStyles(this.userInfo);
    const mapStylesPopupWidth = useMapStyles.length > 3 ? '250px' : '150px';
    const mapStylesPopupArrowTransform =
      useMapStyles.length > 3 ? 'rotate(180deg)' : 'unset';
    setMapStylesPopupHeightProperty(useMapStyles);
    document.documentElement.style.setProperty(
      '--map-styles-popup-width',
      mapStylesPopupWidth + '',
    );
    document.documentElement.style.setProperty(
      '--map-styles-popup-arrow',
      mapStylesPopupArrowTransform + '',
    );

    const popup = await this.popover.create({
      component: MapstylePopupComponent,
      cssClass: 'main-map-style-picker',
      mode: 'ios',
      translucent: false,
      backdropDismiss: true,
      side: this.isSmallScreen ? 'top' : 'right',
      componentProps: { map: this.userInfo.map },
      event,
    });

    await popup.present();
    const { data } = await popup.onWillDismiss();
    if (data) {
      this.userInfo.map = data.map;
      const shouldReload = this.map.shouldUseMapboxLib(data.map).reload;
      await this.updateSettings();

      //_ Reload page if needs to be reloaded
      if (shouldReload) {
        setTimeout(() => location.reload(), 200);
      }
    }
  }
}
