<div *ngIf="!device?.properties.alarm_enabled" class="overlayAlerts">
  <div class="alert-disable-msg">
    <ion-icon
      src="assets/icons/simple-disable-notification.svg"
      slot="start"
      style="font-size: 60px"
    ></ion-icon>
    <label>{{ "enableDisableAllAlerts.alertDisabledMsg" | translate }}</label>
  </div>
  <ion-button
    mode="md"
    shape="round"
    size="small"
    (click)="enableAlerts()"
    expand="block"
  >
    {{ "enableDisableAllAlerts.enableAlerts" | translate }}
  </ion-button>
  <div></div>
  <!-- For the space-around elements -->
</div>

<div
  class="content"
  (click)="$event.stopPropagation()"
  [class.disable]="!device?.properties.alarm_enabled"
>
  <!-- <ion-label class="ion-no-margin tab-label" *ngIf="isOnDeviceList">{{ "map.alertText" | translate }}</ion-label> -->
  <div *ngIf="deviceModel; else NoDeviceModel">
    <!-- <div *ngIf ="!device?.properties.alarm_enabled" class="overlayAlerts">
      <ion-button (click)="enableAlerts()" expand="block">
        Enable Alerts
        </ion-button>
    </div> -->

    <ion-card>
      <div
        [class.overlayGeofencesAlerts]="!permissions.modify"
        class="bak-geof-subcontainer"
      >
        <label *ngIf="!permissions.modify">{{
          "alerts.noModifyPermissionMessage" | translate
        }}</label>
      </div>
      <ion-card-content [class.disabled-content]="!permissions.modify">
        <div *ngIf="showAlertsSection">
          <ion-list lines="none">
            <!-- SOS Start -->
            <ion-item
              *ngIf="deviceModel.alarm_sos === 1 || deviceModel.alarm_drop == 1"
            >
              <div
                class="div-flex"
                *ngIf="deviceModel.alarm_sos === 1; else drop"
              >
                <!-- <ion-avatar slot="start"> -->
                <ion-icon
                  slot="start"
                  class="icon-align icon-padding"
                  src="assets/images/alert_sos.svg"
                >
                </ion-icon>
                <!-- </ion-avatar> -->
                <ion-label class="label-padding-bottom">SOS</ion-label>
                <ion-icon
                  slot="start"
                  name="help-circle-outline"
                  class="icon-padding-right pointer"
                  (click)="
                    showAlarmInfo(
                      'SOSAlertText',
                      'SOSInfoText',
                      'assets/images/alert_sos.svg',
                      $event
                    )
                  "
                ></ion-icon>
              </div>
              <ng-template #drop>
                <!-- <ion-avatar slot="start"> -->
                <div class="div-flex">
                  <ion-icon
                    slot="start"
                    class="icon-align icon-padding"
                    src="assets/images/alert_Drop-Alarm.svg"
                  ></ion-icon>
                  <!-- </ion-avatar> -->
                  <ion-label class="label-padding-bottom">{{
                    "alerts.removeFINDERText" | translate
                  }}</ion-label>
                  <ion-icon
                    slot="start"
                    name="help-circle-outline"
                    class="pointer"
                    (click)="
                      showAlarmInfo(
                        'removeFINDERText',
                        'removeFinderInfoText',
                        'assets/images/alert_Drop-Alarm.svg',
                        $event
                      )
                    "
                  ></ion-icon>
                </div>
              </ng-template>
              <!-- <ngx-switch-input
              slot="end"
              label=""
              [value]="deviceAlarmsValues.alarmsos"
              (valueChange)="enableAlarmData($event, 'alarmsos')"
              [class.disabled-content]="!permissions.modify"
            >
            </ngx-switch-input> -->
              <ion-toggle
                color="success"
                mode="ios"
                slot="end"
                class="ion-custom"
                [checked]="deviceAlarmsValues.alarmsos"
                (ionChange)="enableAlarmData($event.detail.checked, 'alarmsos')"
                [disabled]="!permissions.modify"
                vibrate-on-toggle
                [duration]="toggleVibrationTime"
                aria-label=""
              >
              </ion-toggle>
            </ion-item>
            <!-- SOS End -->

            <!-- Low Battery Start -->
            <ion-item *ngIf="deviceModel.alarm_batteriestand === 1">
              <!-- <div> -->
              <!-- <ion-avatar slot="start"> -->
              <div class="div-flex">
                <ion-icon
                  slot="start"
                  class="icon-align icon-padding"
                  src="../../assets/images/Alert_Battery.svg"
                ></ion-icon>
                <!-- </ion-avatar> -->
                <ion-label class="label-padding-bottom">{{
                  "alerts.batteryText" | translate
                }}</ion-label>
                <ion-icon
                  slot="start"
                  name="help-circle-outline"
                  class="pointer"
                  (click)="
                    showAlarmInfo(
                      'lowBatteryAlertText',
                      'lowBatteryInfoText',
                      'assets/images/Alert_Battery.svg',
                      $event
                    )
                  "
                ></ion-icon>
              </div>
              <!-- </div> -->

              <!-- <ngx-switch-input
              slot="end"
              label=""
              [value]="deviceAlarmsValues.alarmakkuwarnung"
              (valueChange)="enableAlarmData($event, 'alarmakkuwarnung')"
              [class.disabled-content]="!permissions.modify"
            >
            </ngx-switch-input> -->
              <ion-toggle
                color="success"
                mode="ios"
                slot="end"
                class="ion-custom"
                [checked]="deviceAlarmsValues.alarmakkuwarnung"
                (ionChange)="
                  enableAlarmData($event.detail.checked, 'alarmakkuwarnung')
                "
                [disabled]="!permissions.modify"
                vibrate-on-toggle
                [duration]="toggleVibrationTime"
                aria-label=""
              >
              </ion-toggle>
            </ion-item>
            <!-- Low Battery End -->

            <!-- Shock Start -->
            <ion-item *ngIf="deviceModel.alarm_erschuetterung === 1">
              <!-- <div>
                            <ion-avatar slot="start"> -->
              <div class="div-flex">
                <ion-icon
                  class="icon-align icon-padding"
                  slot="start"
                  src="../../assets/images/alert_shock(1).svg"
                ></ion-icon>
                <!-- </ion-avatar> -->
                <ion-label class="label-padding-bottom">{{
                  "alerts.shockText" | translate
                }}</ion-label>
                <ion-icon
                  slot="start"
                  name="help-circle-outline"
                  class="icon-padding-right pointer"
                  (click)="
                    showAlarmInfo(
                      'shockAlertText',
                      'shockInfoText',
                      '../../assets/images/alert_shock(1).svg',
                      $event
                    )
                  "
                ></ion-icon>
              </div>

              <!-- <ngx-switch-input
              slot="end"
              label=""
              [value]="deviceAlarmsValues.alarmbewegung"
              (valueChange)="enableAlarmData($event, 'alarmbewegung')"
              [class.disabled-content]="!permissions.modify"
            >
            </ngx-switch-input> -->
              <ion-toggle
                color="success"
                mode="ios"
                slot="end"
                class="ion-custom"
                [checked]="deviceAlarmsValues.alarmbewegung"
                (ionChange)="
                  enableAlarmData($event.detail.checked, 'alarmbewegung')
                "
                [disabled]="!permissions.modify"
                vibrate-on-toggle
                [duration]="toggleVibrationTime"
                aria-label=""
              >
              </ion-toggle>
            </ion-item>
            <!-- Shock End -->

            <!-- Power Cut Off Start -->
            <ion-item *ngIf="deviceModel.alarm_stromunterbrechung === 1">
              <!-- <div>
              <ion-avatar slot="start"> -->
              <div class="div-flex">
                <ion-icon
                  class="icon-align icon-padding"
                  slot="start"
                  src="../../assets/images/power-cut-off.svg"
                ></ion-icon>
                <!-- </ion-avatar> -->
                <ion-label class="label-padding-bottom">{{
                  "alerts.powerAlertText" | translate
                }}</ion-label>
                <ion-icon
                  slot="start"
                  name="help-circle-outline"
                  class="pointer"
                  (click)="
                    showAlarmInfo(
                      'powerCutOffAlertText',
                      'powerCutOffInfoText',
                      '../../assets/images/power-cut-off.svg',
                      $event
                    )
                  "
                ></ion-icon>
              </div>

              <!-- <ngx-switch-input
              slot="end"
              label=""
              [value]="deviceAlarmsValues.alarmstromunterbrechung"
              (valueChange)="enableAlarmData($event, 'alarmstromunterbrechung')"
              [class.disabled-content]="!permissions.modify"
            >
            </ngx-switch-input> -->
              <ion-toggle
                color="success"
                mode="ios"
                slot="end"
                class="ion-custom"
                [checked]="deviceAlarmsValues.alarmstromunterbrechung"
                (ionChange)="
                  enableAlarmData(
                    $event.detail.checked,
                    'alarmstromunterbrechung'
                  )
                "
                [disabled]="!permissions.modify"
                vibrate-on-toggle
                [duration]="toggleVibrationTime"
                aria-label=""
              >
              </ion-toggle>
            </ion-item>
            <!-- Power Cut Off End -->

            <!-- Ignition Alert Start -->
            <ion-item *ngIf="deviceModel.alarm_zuendalarm === 1">
              <!-- <div>
              <ion-avatar slot="start"> -->
              <div class="div-flex">
                <ion-icon
                  class="icon-align icon-padding"
                  slot="start"
                  src="../../assets/images/ignition-alert.svg"
                ></ion-icon>
                <!-- </ion-avatar> -->
                <ion-label class="label-padding-bottom">{{
                  "alerts.ignitionAlertText" | translate
                }}</ion-label>
                <ion-icon
                  slot="start"
                  name="help-circle-outline"
                  class="pointer"
                  (click)="
                    showAlarmInfo(
                      'ignitionLockAlertText',
                      'ignitionLockInfoText',
                      '../../assets/images/ignition-alert.svg',
                      $event
                    )
                  "
                ></ion-icon>
              </div>

              <!-- <ngx-switch-input
              slot="end"
              label=""
              [value]="deviceAlarmsValues.alarmzuendalarm"
              (valueChange)="enableAlarmData($event, 'alarmzuendalarm')"
              [class.disabled-content]="!permissions.modify"
            >
            </ngx-switch-input> -->
              <ion-toggle
                color="success"
                mode="ios"
                slot="end"
                class="ion-custom"
                [checked]="deviceAlarmsValues.alarmzuendalarm"
                (ionChange)="
                  enableAlarmData($event.detail.checked, 'alarmzuendalarm')
                "
                [disabled]="!permissions.modify"
                vibrate-on-toggle
                [duration]="toggleVibrationTime"
                aria-label=""
              >
              </ion-toggle>
            </ion-item>
            <!-- Ignition Alert End -->

            <!-- Device Turn off alert -->
            <ion-item *ngIf="deviceModel.alarm_turn_off === 1">
              <!-- <div>
              <ion-avatar slot="start"> -->
              <div class="div-flex">
                <ion-icon
                  class="icon-align icon-padding"
                  slot="start"
                  src="../../assets/images/powerOff.svg"
                ></ion-icon>
                <!-- </ion-avatar> -->
                <ion-label class="label-padding-bottom">{{
                  "alerts.deviceTurnOffAlertText" | translate
                }}</ion-label>
                <ion-icon
                  slot="start"
                  name="help-circle-outline"
                  class="pointer"
                  (click)="
                    showAlarmInfo(
                      'deviceTurnOffAlertText',
                      'deviceTurnOffPopupAlertText',
                      '../../assets/images/powerOff.svg',
                      $event
                    )
                  "
                ></ion-icon>
              </div>

              <!-- <ngx-switch-input
              slot="end"
              label=""
              [value]="deviceAlarmsValues.alarm_turn_off"
              (valueChange)="enableAlarmData($event, 'alarm_turn_off')"
              [class.disabled-content]="!permissions.modify"
            >
            </ngx-switch-input> -->
              <ion-toggle
                color="success"
                mode="ios"
                slot="end"
                class="ion-custom"
                [checked]="deviceAlarmsValues.alarm_turn_off"
                (ionChange)="
                  enableAlarmData($event.detail.checked, 'alarm_turn_off')
                "
                [disabled]="!permissions.modify"
                vibrate-on-toggle
                [duration]="toggleVibrationTime"
                aria-label=""
              >
              </ion-toggle>
            </ion-item>
            <!-- Device Turn off Alert End -->

            <!-- Deviceguard -->
            <!-- <div class="device-guard">
          <ion-item class="ion-item" *ngIf="brand != 3" (click)="openDeviceGuard()">
            <ion-icon class="icon-align icon-padding" src="assets/icons/shield.svg" slot="start"></ion-icon>
            <ion-label class="label-padding-bottom">{{ "side-bar.deviceGuard" | translate }}</ion-label>
          </ion-item>
          </div> -->

            <div *ngIf="showMenuItem">
              <ion-item *ngIf="brand !== 3" class="ion-item item-lines-full">
                <ion-icon
                  slot="start"
                  class="icon-align icon-padding"
                  src="assets/icons/shield.svg"
                ></ion-icon>
                <ion-label slot="start" class="label-padding-bottom">{{
                  "side-bar.deviceGuard" | translate
                }}</ion-label>
                <ion-button
                  mode="ios"
                  slot="end"
                  class="ion-custom"
                  color="light"
                  (click)="openDeviceGuard()"
                  title="Open Deviceguard"
                >
                  <ion-icon
                    src="assets/images/map/device-dashboard/maximize.svg"
                    color="primary"
                    style="cursor: pointer"
                  ></ion-icon>
                </ion-button>
              </ion-item>
            </div>
          </ion-list>

          <!-- Speeding Alert Start -->
          <ion-list
            lines="none"
            *ngIf="deviceModel.alarm_geschwindigkeit === 1 && showAlertsSection"
          >
            <h2 *ngIf="showTitles">{{ "geofence.speed" | translate }}</h2>
            <ion-item
              class="speed-alert"
              [class.activeContent]="
                deviceAlarmsValues?.alarmgeschwindigkeit === 1
              "
            >
              <div class="div-flex">
                <ion-icon
                  class="icon-align icon-padding"
                  slot="start"
                  src="../../assets/images/speed.svg"
                ></ion-icon>
                <ion-label class="label-padding-bottom">{{
                  "alerts.speedingAlertText" | translate
                }}</ion-label>
                <ion-icon
                  slot="start"
                  name="help-circle-outline"
                  class="pointer"
                  (click)="
                    showAlarmInfo(
                      'speedingLimitAlertText',
                      'speedingLimitInfoText',
                      '../../assets/images/speed.svg',
                      $event
                    )
                  "
                ></ion-icon>
              </div>
              <ion-toggle
                color="success"
                mode="ios"
                slot="end"
                class="ion-custom"
                [checked]="deviceAlarmsValues.alarmgeschwindigkeit"
                (ionChange)="
                  enableAlarmData(
                    $event.detail.checked,
                    'alarmgeschwindigkeit',
                    1
                  )
                "
                [disabled]="!permissions.modify"
                vibrate-on-toggle
                [duration]="toggleVibrationTime"
                aria-label=""
              >
              </ion-toggle>
            </ion-item>
                        
            <ion-item
              *ngIf="showSpeedSection"
              class="ion-no-padding item-subcontent"
              [class.disabled-content]="updatingSpeed"
            >
              <ion-item lines="none" class="speedButton">                
                <ion-input
                  type="tel"
                  maxlength="3"
                  pattern="[0-9]*"
                  min="10"
                  [value]="alarmgeschwindigkeitab | KmtoMiles: '1.0-0' : true"
                  placeholder="100"
                  [disabled]="!permissions.modify"
                  (ionInput)="onSpeedChange($event)"
                  (keypress)="numberOnlyValidation($event)"
                  #speedInput
                >
                </ion-input>                
                <ion-buttons slot="end">
                  <ion-label style="margin: 5px 8px">{{
                    speed_unit
                  }}</ion-label>
                  <ion-button
                    *ngIf="permissions.modify"
                    (click)="
                      updateSpeed(speedInput.value, 'alarmgeschwindigkeitab')
                    "
                    size="small"
                    style="
                      margin-left: 0;
                      --padding-start: 15px;
                      --padding-end: 16px;
                      --border-radius: 8px;
                    "
                    color="primary"
                    fill="solid"
                  >
                    {{ "deviceList.sortingGroup.save" | translate }}
                  </ion-button>
                </ion-buttons>
              </ion-item>
            </ion-item>
            <p style="padding-left: 10px" *ngIf="speedValue > 0 && speedValue < 10">
              <ion-note style="font-size: 13px">
                {{ "alerts.minimumSpeed" | translate }}:
                {{ distance_unit == "mi" ? 6 : 10 }} {{ speed_unit }}
              </ion-note>
            </p>            
          </ion-list>

          <!-- Speeding Alert End -->
          <ion-list>
            <ion-toolbar
              class="ion-text-center"
              *ngIf="data?.length > 1 && permissions.modify"
            >
              <div>
                <ion-button
                  style="text-transform: none"
                  (click)="saveForAllDevices()"
                  color="light"
                  fill="solid"
                  size="small"
                >
                  <ion-label autocapitalize="none"
                    >{{ "route.saveForAll" | translate }}
                  </ion-label>
                  <ion-icon
                    class="saveForAllIcon"
                    name="save-outline"
                    color="primary"
                    slot="start"
                  ></ion-icon>
                </ion-button>
              </div>
            </ion-toolbar>
          </ion-list>

          <!-- Voltage Alarm -->
          <ion-list lines="none" *ngIf="deviceModel.alarm_volt === 1">
            <h2>{{ "alerts.voltageHeadingAlertText" | translate }}</h2>
            <ion-item
              *ngIf="deviceModel.alarm_volt === 1"
              class="speed-alert"
              [class.activeContent]="deviceAlarmsValues?.alarm_volt === 1"
            >
              <!-- <div>
              <ion-avatar slot="start"> -->
              <div class="div-flex">
                <ion-icon
                  class="icon-align icon-padding"
                  slot="start"
                  src="../../assets/images/volt.svg"
                ></ion-icon>
                <!-- </ion-avatar> -->
                <ion-label class="label-padding-bottom">{{
                  "alerts.voltageAlertText" | translate
                }}</ion-label>
                <ion-icon
                  slot="start"
                  name="help-circle-outline"
                  class="pointer"
                  (click)="
                    showAlarmInfo(
                      'voltageAlertText',
                      'voltagePopUpAlertText',
                      '../../assets/images/volt.svg',
                      $event
                    )
                  "
                ></ion-icon>
              </div>

              <!-- <ngx-switch-input
              slot="end"
              label=""
              [value]="deviceAlarmsValues.alarm_volt"
              (valueChange)="enableAlarmData($event, 'alarm_volt', 1)"
              [class.disabled-content]="!permissions.modify"
            >
            </ngx-switch-input> -->
              <ion-toggle
                color="success"
                mode="ios"
                slot="end"
                class="ion-custom"
                [checked]="deviceAlarmsValues.alarm_volt"
                (ionChange)="
                  enableAlarmData($event.detail.checked, 'alarm_volt', 1)
                "
                [disabled]="!permissions.modify"
                vibrate-on-toggle
                [duration]="toggleVibrationTime"
                aria-label=""
              >
              </ion-toggle>
            </ion-item>

            <ion-item
              *ngIf="deviceAlarmsValues?.alarm_volt === 1"
              class="ion-no-padding item-subcontent"
              [class.disabled-content]="updatingVoltage"
            >
              <ion-item lines="none" class="speedButton">
                <ion-input
                  maxlength="5"
                  type="decimal"
                  min="0"
                  [(ngModel)]="alarm_volt_value"
                  placeholder="100"
                  [disabled]="!permissions.modify"
                  input-validation
                >
                </ion-input>

                <ion-buttons slot="end">
                  <ion-label style="margin: 5px 8px">V</ion-label>
                  <ion-button
                    *ngIf="permissions.modify"
                    (click)="updateSpeed(alarm_volt_value, 'alarm_volt_value')"
                    size="small"
                    style="
                      margin-left: 0;
                      --padding-start: 15px;
                      --padding-end: 16px;
                      --border-radius: 8px;
                    "
                    color="primary"
                    fill="solid"
                  >
                    {{ "deviceList.sortingGroup.save" | translate }}
                  </ion-button>
                </ion-buttons>
              </ion-item>
            </ion-item>
            <!-- Speeding Alert End -->

            <!-- <ion-toolbar class="ion-text-center">
            <div *ngIf="data?.length > 1 && permissions.modify">
              <ion-button
                style="text-transform: none"
                (click)="saveForAllDevices()"
                color="light"
                fill="solid"
                size="small"
              >
                <ion-label autocapitalize="none"
                  >{{ "route.saveForAll" | translate }}
                </ion-label>
                <ion-icon
                  class="saveForAllIcon"
                  name="save-outline"
                  color="primary"
                  slot="start"
                ></ion-icon>
              </ion-button>
            </div>
          </ion-toolbar> -->
          </ion-list>
        </div>
        <ion-list
          lines="none"
          *ngIf="deviceModel.alarm_radius === 1 && !hideGeofencesFeature"
          id="device-view-tab-content-geofence"
        >
          <ion-item lines="none">
            <h2 slot="start" style="margin: auto">
              {{ "geofence.geofences" | translate }}
            </h2>
            <ion-icon
              slot="start"
              name="help-circle-outline"
              class="pointer"
              (click)="
                showGeofenceInfo(
                  'geofenceAlertText',
                  'geofencesInfoDetails',
                  'assets/images/map/popup/geofence.gif',
                  $event,
                  true
                )
              "
            >
            </ion-icon>
          </ion-item>

          <ion-item
            class="speed-alert"
            [class.activeContent]="
              deviceAlarmsValues?.alarmgeschwindigkeit === 1
            "
          >
            <div class="div-flex">
              <ion-icon
                class="icon-align icon-padding"
                slot="start"
                src="../../assets/images/alarm/geofence-alert.svg"
              ></ion-icon>
              <ion-label class="label-padding-bottom">{{
                "alerts.geofencesAlertText" | translate
              }}</ion-label>
              <ion-icon
                slot="start"
                name="help-circle-outline"
                class="pointer"
                (click)="
                  showAlarmInfo(
                    'speedingLimitAlertText',
                    'geofencesInfoText',
                    '../../assets/images/alarm/geofence-alert.svg',
                    $event
                  )
                "
              ></ion-icon>
            </div>
            <ion-toggle
              color="success"
              mode="ios"
              slot="end"
              class="ion-custom"
              [checked]="geofencesAreDisabled"
              (ionChange)="enableDisableGeofences($event.detail.checked)"
              [disabled]="!permissions.modify"
              vibrate-on-toggle
              [duration]="toggleVibrationTime"
              aria-label=""
            >
            </ion-toggle>
          </ion-item>

          <!-- Leave / Enter Area Start -->
          <!-- <ion-item
            *ngIf="deviceModel.alarm_radius === 1"
            [class.activeContent]="deviceAlarmsValues?.alarm_geofence === 1"
          > -->
          <!-- <ion-icon
              class="icon-align"
              slot="start"
              src="../../assets/images/alert_radius.svg"
            ></ion-icon>

            <ion-icon
              slot="start"
              class="flaticon-information-2 pointer"
              (click)="
                showAlarmInfo(
                  'geofenceAlertText',
                  'geofenceInfoText',
                  'assets/images/map/popup/area.svg',
                  $event
                )
              "
            >
            </ion-icon> -->
          <!-- <ion-label>{{ "alerts.areaText" | translate }}</ion-label>

            <ngx-switch-input
              slot="end"
              label=""
              [value]="deviceAlarmsValues.alarm_geofence"
              (valueChange)="enableAlarmData($event, 'alarm_geofence', 0, 1)"
              [class.disabled-content]="!permissions.modify"
            >
            </ngx-switch-input> -->
          <!-- </ion-item> -->

          <div
            class="geowarp item-subcontent"
            color="light"
            style="position: relative"
          >
            <div
              [class.overlayGeofencesAlerts]="
                geofencesAreDisabled === false && device?.geofences.length > 0
              "
              class="bak-geof-subcontainer"
            >
              <label
                *ngIf="!geofencesAreDisabled && device?.geofences.length > 0"
                >{{
                  "alerts.geofencesUpdatedDisableMessage" | translate
                }}</label
              >
            </div>
            <div
              class="geofences-div"
              [class.geofencesDisable]="
                geofencesAreDisabled === false && device?.geofences.length > 0
              "
            >
              <!-- NOTE ABOUT THE GEOFENCE -->
              <div *ngFor="let geo of selectedDeviceGeoList; let i = index">
                <div *ngIf="isFirstInstance(geo.id, i)">
                  <ion-card>
                    <ion-card-header
                      color="transparent"
                      style="padding: 0 !important"
                    >
                      <!-- INPUT NAME -->
                      <ion-item class="input-box" lines="none">
                        <!-- RADIUS FOR CIRCLE SHAPE -->
                        <ion-icon
                          slot="start"
                          *ngIf="geo.shape_type == 1"
                          src="assets/images/alarm/circle.svg"
                          style="width: 25px"
                          [backgroundContrast]="geo.optionsObj.fillColor"
                          class="geo-type-icon"
                        ></ion-icon>
                        <!-- AREA FOR NON CIRCLE SHAPE -->
                        <ion-icon
                          slot="start"
                          *ngIf="geo?.shape_type == 3"
                          src="assets/images/alarm/poligon.svg"
                          [backgroundContrast]="geo.optionsObj.fillColor"
                          class="geo-type-icon"
                        >
                        </ion-icon>
                        <ion-icon
                          name="create-outline"
                          slot="end"
                          title="{{
                            'alerts.geofenceNameEditText' | translate
                          }}"
                        ></ion-icon>
                        <ion-input
                          [value]="geo?.name"
                          (ionBlur)="setName($event, geo)"
                          debounce="100"
                          autocomplete="off"
                          placeholder="{{ 'alerts.geofenceName' | translate }}"
                          class="ion-text-center"
                          type="text"
                          maxlength="50"
                          (keyup.enter)="setName($event, geo)"
                          (ionChange)="updateName($event, geo)"
                        >
                        </ion-input>
                      </ion-item>
                    </ion-card-header>

                    <ion-card-content>
                      <div class="geotype-item block">
                        <ion-list>
                          <ion-item lines="none">
                            <ion-label class="ion-text-wrap">{{
                              "alerts.geofenceView" | translate
                            }}</ion-label>
                            <ion-icon
                              slot="end"
                              name="eye-outline"
                              color="primary"
                              class="geo-eye-icon normal force-pointer pb-0"
                              (click)="centerGeo($event, geo, 0)"
                            ></ion-icon>
                          </ion-item>
                          <ion-item lines="none">
                            <ion-label class="ion-text-wrap">{{
                              "alerts.geofenceOptions" | translate
                            }}</ion-label>
                            <ion-icon
                              name="settings-outline"
                              slot="end"
                              class="normal force-pointer pb-0"
                              (click)="centerGeo($event, geo, 1)"
                            >
                            </ion-icon>
                          </ion-item>
                          <ion-item lines="none">
                            <!-- <ion-label class="ion-text-wrap">
                         Copy geofence layout
                          </ion-label> -->
                            <ion-label class="ion-text-wrap">{{
                              "alerts.copyGeofence" | translate
                            }}</ion-label>
                            <ion-icon
                              name="copy-outline"
                              slot="end"
                              class="normal force-pointer pb-0"
                              (click)="presentSelectDevice(geo)"
                            >
                            </ion-icon>
                          </ion-item>

                          <ion-item lines="none">
                            <ion-label class="ion-text-wrap">{{
                              "alerts.geofenceDelete" | translate
                            }}</ion-label>
                            <ion-icon
                              name="trash-outline"
                              color="danger"
                              slot="end"
                              class="normal force-pointer pb-0"
                              (click)="deleteGeo($event, geo.id)"
                            >
                            </ion-icon>
                          </ion-item>
                        </ion-list>
                      </div>
                    </ion-card-content>
                  </ion-card>
                </div>
              </div>

              <ion-card>
                <ion-card-content>
                  <div
                    class="geotype-item"
                    style="justify-content: space-around"
                  >
                    <!-- COLOR OF GEOFENCE -->
                    <ion-label class="ion-text-center">
                      <!--
                        <input
                          id="addNewColor"
                          [(ngModel)]="shapeColor"
                          value="#000"
                          type="color"
                          [ngStyle]="{ 'background-color': shapeColor }"
                        />
                        !-->
                      <div
                        class="color-button pointer"
                        [style.background-color]="this.shapeColor"
                        [ngStyle]="{ 'background-color': shapeColor }"
                        (click)="getPickr()"
                      ></div>

                      <p>{{ "geofence.color" | translate }}</p>
                    </ion-label>

                    <ion-button
                      class="geoType"
                      color="light"
                      size="small"
                      (click)="selectGeoType($event)"
                      *ngIf="selectedGeoType"
                      style="
                        --box-shadow: none;
                        height: 40px;
                        min-width: 125px;
                        margin: 0;
                      "
                    >
                      <ion-icon
                        src="{{ selectedGeoType.image }}"
                        class="normal"
                        color="primary"
                        slot="start"
                      >
                      </ion-icon>
                      <ion-label class="ion-text-center">{{
                        selectedGeoType.name
                      }}</ion-label>
                      <ion-icon
                        name="chevron-down-outline"
                        slot="end"
                        class="normal"
                      ></ion-icon>
                    </ion-button>
                  </div>

                  <div
                    class="geotype-item"
                    style="justify-content: center; margin-top: 10px"
                  >
                    <!-- ADD BUTTON -->
                    <ion-button
                      color="primary"
                      size="small"
                      (click)="drawShape($event)"
                      shape="round"
                    >
                      <ion-icon
                        name="add"
                        slot="start"
                        style="padding: 0"
                        class="normal"
                      ></ion-icon>
                      <ion-label
                        style="text-transform: none; line-height: 2ch"
                        >{{ "alerts.addGeofence" | translate }}</ion-label
                      >
                    </ion-button>
                  </div>
                </ion-card-content>
              </ion-card>
            </div>
            <!-- <div class="geoDetails">
              <div class="geoList" *ngIf="selectedDeviceGeoList">
                <ion-list>
                  <ion-item
                    class="ion-no-padding"
                    *ngFor="let geo of selectedDeviceGeoList"
                  >
                    <div class="geoItem">
                      <div>
                        <ion-button
                          shape="round"
                          [disabled]="!geo.status"
                          fill="solid"
                          color="light"
                          size="small"
                          class="btn-delete ion-no-padding"
                          slot="start"
                          (click)="centerGeo($event, geo)"
                        >
                          <ion-icon name="eye" color="primary"></ion-icon>
                        </ion-button>

                        <ngx-switch-input
                          slot="start"
                          style="margin-left: 0; margin-right: 5px; flex: 1"
                          label=""
                          [value]="geo.status"
                          (valueChange)="enableGeofence($event, geo)"
                          [class.disabled-content]="!permissions.modify"
                        >
                        </ngx-switch-input>

                        <img
                          *ngIf="geo.shape_type == 1"
                          src="assets/images/alarm/circle.png"
                          style="width: 25px"
                        />
                        <img
                          *ngIf="geo.shape_type == 3"
                          src="assets/images/alarm/poligon.png"
                          style="width: 25px"
                        />

                        <ion-buttons
                          slot="end"
                          style="
                            margin-left: 0;
                            flex: 3;
                            justify-content: inherit;
                          "
                        >
                          <ion-button class="colorButton">
                            <input
                              class="colorInput"
                              (change)="changeColor($event, geo.id)"
                              [ngStyle]="{
                                'background-color': (
                                  geo.options | JSON: ['parse']
                                ).color
                              }"
                              [value]="
                                (geo.options | JSON: ['parse']).color || '#000'
                              "
                              type="color"
                            />
                          </ion-button>
                          <ion-button
                            (click)="deleteGeo($event, geo.id)"
                            class="btn-delete"
                            fill="solid"
                            shape="round"
                            size="small"
                            color="light"
                          >
                            <ion-icon name="trash"></ion-icon>
                          </ion-button>
                        </ion-buttons>
                      </div>

                      <ion-list *ngIf="geo.status">
                        <ion-item>
                          <ion-label>{{
                            "alerts.enterGeofence" | translate
                          }}</ion-label>
                          <ngx-switch-input
                            slot="end"
                            label=""
                            [value]="geo.trigger_enter"
                            (valueChange)="
                              updateGeofence(geo, $event, 'trigger_enter')
                            "
                            [class.disabled-content]="!permissions.modify"
                          >
                          </ngx-switch-input>
                        </ion-item>
                        <ion-item>
                          <ion-label>{{
                            "alerts.leaveGeofence" | translate
                          }}</ion-label>
                          <ngx-switch-input
                            slot="end"
                            label=""
                            [value]="geo.trigger_leave"
                            (valueChange)="
                              updateGeofence(geo, $event, 'trigger_leave')
                            "
                            [class.disabled-content]="!permissions.modify"
                          >
                          </ngx-switch-input>
                        </ion-item>
                      </ion-list>
                    </div>
                  </ion-item>
                </ion-list>
              </div>

              <ion-item class="geo-zones">
                <ion-buttons slot="start">
                  <ion-button
                    class="geoType"
                    slot="start"
                    fill="solid"
                    shape="round"
                    color="light"
                    size="default"
                    (click)="selectGeoType($event)"
                    *ngIf="selectedGeoType"
                  >
                    <ion-label>{{ selectedGeoType.name }}</ion-label>
                    <img
                      slot="start"
                      src="{{ selectedGeoType.image }}"
                      style="width: 25"
                    />
                  </ion-button>
                </ion-buttons>
                <ion-buttons slot="end" style="margin-left: 1px">
                  <ion-button class="colorButton">
                    <input
                      id="addNewColor"
                      [(ngModel)]="shapeColor"
                      [ngStyle]="{ 'background-color': shapeColor }"
                      value="#000"
                      class="colorInput"
                      type="color"
                    />
                  </ion-button>

                  <ion-button
                    (click)="drawShape($event)"
                    [disabled]="shapeName == ''"
                    slot="end"
                    class="addButton"
                    fill="solid"
                    color="light"
                    shape="round"
                    size="small"
                  >
                    <ion-icon name="add"></ion-icon>
                  </ion-button>
                </ion-buttons>
              </ion-item>
            </div> -->
          </div>
        </ion-list>
        <!-- Leave / Enter Area End -->
      </ion-card-content>
    </ion-card>

    <app-sos [deviceID]="deviceID"></app-sos>
  </div>

  <ng-template #NoDeviceModel>
    <div class="label-center">
      <ion-label class="item-paddings">{{
        "alerts.noAlarmsText" | translate
      }}</ion-label>
    </div>
  </ng-template>
</div>
