import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';


export const enterTrigger = trigger('enterTrigger', [
  state(
    'fadeIn',
    style({
      opacity: '1',
    })
  ),
  transition('void => *', [
    style({ opacity: '0' }),
    animate('200ms'),
  ]),
]);
export const fadeInOut = trigger('fadeInOut', [
  state(
    'void',
    style({
      opacity: 0,
      // transform: 'scale(0.8)', // Initial scale
    })
  ),
  state(
    '*',
    style({
      opacity: 1,
      // transform: 'scale(1)', // Final scale
    })
  ),
  transition(
    'void <=> *',
    animate('700ms cubic-bezier(0.645, 0.045, 0.355, 1)')
  ), // Using cubic-bezier for smooth island-like transition
])
export const routeCardAnimation = trigger('toggleCard', [
  state(
    'show',
    style({
      transform: 'translateY(-75px)',
      opacity: 1,
    })
  ),
  state(
    'hide',
    style({
      transform: 'translateY(100%)',
      opacity: 0,
    })
  ),
  transition('show => hide', [animate('300ms ease-in-out')]),
  transition('hide => show', [animate('300ms ease-in-out')]),
]);

export const slideInFromRight = trigger('slideInFromRight', [
  state(
    'void',
    style({
      transform: 'translateX(100%)',
      opacity: 0
    })
  ),
  state(
    '*',
    style({
      transform: 'translateX(0)',
      opacity: 1
    })
  ),
  transition('void => *', [
    animate(
      '500ms cubic-bezier(0.645, 0.045, 0.355, 1)',
      style({
        transform: 'translateX(0)',
        opacity: 1
      })
    )
  ]),
  transition('* => void', [
    animate(
      '500ms cubic-bezier(0.645, 0.045, 0.355, 1)',
      style({
        transform: 'translateX(100%)',
        opacity: 0
      })
    )
  ])
]);

export const inOutSliderAnimation = trigger('inOutSliderAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('800ms ease-in-out', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('800ms ease-in-out', style({ opacity: 0 })),
  ]),
])
