//_ EXTRA PROPS TO ATTACH IN EVERY COMMAND, the translationKey is used for the description in the info popup
export const EXTRA_COMMAND_PROPS = {
  "LiveTrack": {
    image: 'assets/images/commands/search-location.svg',
    translationKey: 'alerts.commandsTab.liveTracking',
    type: 'listItem'
  },
  "SoundEmit": {
    image: 'assets/images/commands/sound.svg',
    translationKey: 'alerts.commandsTab.soundEmit',
    type: 'listItem'
  },
  "lightON": {
    image: 'assets/images/commands/lightOn.svg',
    translationKey: 'alerts.commandsTab.lightOn',
    type: 'listItem'
  },
  "lightOFF": {
    image: 'assets/images/commands/lightOff.svg',
    translationKey: 'alerts.commandsTab.lightOff',
    type: 'listItem'
  },
  "LocationFetch": {
    image: 'assets/images/commands/search-location.svg',
    translationKey: 'alerts.commandsTab.LocationFetch',
    type: 'listItem'
  },
  "XmPetTriggerWH": {
    image: 'assets/images/commands/wifi.svg',
    translationKey: 'alerts.commandsTab.SetupWifi',
    type: 'listItem',
    // parameters: [
    //   {
    //     type: 'text',
    //     name: 'ssid',
    //     inputType: 'input',
    //     label: 'Wifi Name'
    //   },
    //   {
    //     type: 'text',
    //     name: 'password',
    //     inputType: 'input',
    //     label: 'Wifi password'
    //   }
    // ]
  },
  "XmPetTriggerWH2": {
    image: 'assets/images/commands/sound.svg',
    translationKey: 'alerts.commandsTab.soundEmit',
    type: 'listItem',
    //input: true
  },
  "XmPetPlaySound": {
    image: 'assets/images/commands/sound.svg',
    translationKey: 'alerts.commandsTab.soundEmit',
    type: 'listItem'
  },
  "XmPetLedOn": {
    image: 'assets/images/commands/lightOn.svg',
    translationKey: 'alerts.commandsTab.lightOn',
    type: 'listItem'
  },
  "XmPetLedOff": {
    image: 'assets/images/commands/lightOff.svg',
    translationKey: 'alerts.commandsTab.lightOff',
    type: 'listItem'
  },
  "ChooseFetchInterval": {
    name: 'chooseFetchInterval',
    image: 'assets/images/commands/search-location.svg',
    translationKey: 'alerts.commandsTab.SendingInterval10Min',
    type: 'listItem',
    groupKey: 'FetchInterval', //_ Name to group the sub items to select the interval
    items: []
  },
  "SendingInterval1Min": {
    image: 'assets/images/commands/search-location.svg',
    translationKey: 'alerts.commandsTab.SendingInterval1Min',
    type: 'Select',
    intervalSeconds: 1*60,
    groupKey: 'FetchInterval'
  },
  "SendingInterval10Min": {
    image: 'assets/images/commands/search-location.svg',
    translationKey: 'alerts.commandsTab.SendingInterval10Min',
    type: 'Select',
    intervalSeconds: 10*60,
    groupKey: 'FetchInterval'
  },
  "SendingInterval60Min": {
    image: 'assets/images/commands/search-location.svg',
    translationKey: 'alerts.commandsTab.SendingInterval60Min',
    type: 'Select',
    intervalSeconds: 60*60,
    groupKey: 'FetchInterval'
  },
  "XmPetInt10": {
    image: 'assets/images/commands/search-location.svg',
    translationKey: 'alerts.commandsTab.SendingIntervalTo10Seconds',
    type: 'Select',
    intervalSeconds: 10,
    groupKey: 'FetchInterval'
  },
  "XmPetLiveTrack": {
    image: 'assets/images/commands/search-location.svg',
    translationKey: 'alerts.commandsTab.liveTracking',
    type: 'listItem'
  },

  //_ Custom interval with parameters in backend
  "XmPetInt": {
    name: 'chooseFetchInterval',
    image: 'assets/images/commands/search-location.svg',
    translationKey: 'alerts.commandsTab.SendingInterval10Min',
    min: 60, //_ Interval in seconds
    max: 600,
    type: 'customInterval',
    parameters: {
      INTERVAL: 60
    }
  },

  //_ FOR WATCH DEVICE
  "WatchText": {
    image: 'assets/images/commands/bubble-chat.svg',
    translationKey: 'alerts.commandsTab.sendTextMessage',
    type: 'listItem'
  },
  "WatchMeasurement": {
    image: 'assets/images/commands/heart-beat.svg',
    translationKey: 'alerts.commandsTab.triggerMeasurementManually',
    type: 'listItem'
  },
  "WatchClockAlarm": {
    image: 'assets/images/commands/alarm.svg',
    translationKey: 'alerts.commandsTab.fixClockAlarm',
    type: 'listItem'
  },
};
