import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import {
  enterTrigger,
  fadeInOut,
} from 'src/app/petmode/animations/home.animation';
import { DeviceService } from 'src/app/petmode/services/device.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import Pickr from '@simonwep/pickr';
import { StorageService } from 'src/app/services/storage.service';
import { AlertService } from 'src/app/members/map/devices-sidebar/device-item/device-submenu/alert/alert.service';
import { Router } from '@angular/router';
import { error } from 'console';
import { DeviceMixin } from '../../device.mixin';
import { IonNav } from '@ionic/angular';
import { GeofencePage } from '../../pages/geofence/geofence.page';
import { eventMethod } from '@ionic/core/dist/types/utils/overlays';
import { TabsService } from 'src/app/petmode/services/tabs.service';
import { PaneService } from '../../../home/components/right-menu/pane.service';
import { DeviceListService } from 'src/app/members/map/devices-sidebar/devices-list/device-list.service';

@Component({
  selector: 'app-device-tracker',
  templateUrl: './device-tracker.component.html',
  styleUrls: ['./device-tracker.component.scss'],
  animations: [fadeInOut],
})
export class DeviceTrackerComponent extends DeviceMixin implements OnInit {
  @Input() deviceId: any;
  @Input() device: any;
  @Input() map;
  isLoading: boolean = true;
  geofences: Array<any> = [];

  geoTypes = [
    {
      type: 'circle',
      name: this.translate.instant('alerts.circleText'),
      image: 'assets/images/alarm/circle.svg',
    },
    {
      type: 'polygon',
      name: this.translate.instant('alerts.polygonText'),
      image: 'assets/images/alarm/poligon.svg',
    },
  ];
  isPopoverOpen: boolean = false;
  selectedGeoType = {
    type: 'circle',
    name: this.translate.instant('alerts.circleText'),
    image: 'assets/images/alarm/circle.svg',
  };
  draw: any;
  observables = {};
  constructor(
    public authService: AuthenticationService,
    public appService: AppService,
    private alertService: AlertService,
    private deviceService: DeviceDataService,
    public toastController: ToastController,
    public dService: DeviceService,
    public translate: TranslateService,
    public storage: StorageService,
    public injector: Injector,
    private tService: TabsService,
    public paneService:PaneService,
    public deviceListService:DeviceListService
  ) {
    super(injector);
    let style = getComputedStyle(document.body);
    this.shapeColor = style.getPropertyValue('--ion-color-primary');
  }
  
  alarmChange($event) {
    console.log('alarmChange', $event);
  }
  ngOnInit() {
    console.log('deviceId', this.deviceId);
    console.log('DeviceTrackerComponent map', this.map);
  }
  ionViewWillEnter() {
    console.log('ionViewWillEnter', this.deviceId);
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.draw = this.alertService.drawer;
    console.log('device tracker component  drawer', this.draw);
    this.observables['onChanges'] = this.deviceService.deviceUpdated$.subscribe(
      (r) => {
        console.log('onChanges', r);
        if (this.deviceId == r.properties.id) {
          this.device.properties = r.properties;

          this.geofences = r.geofences.map((geo) => {
            return { ...geo, optionsObj: JSON.parse(geo.options) };
          });
        }
      },
    );
    this.observables['geofenceUpdated'] =
      this.alertService.geofenceUpdated.subscribe((geo: any) => {
        console.log('geofenceUpdated', { geo, deviceID: this.deviceId });

        if (geo.iddevice == this.deviceId) {
          this.geofences = this.geofences.map((g) =>
            g.id == geo.id && g.iddevice == geo.iddevice
              ? { ...g, optionsObj: JSON.parse(geo.options), name: geo.name }
              : g,
          );
        }
      });
  }

  ngOnDestroy() {
    Object.keys(this.observables).forEach((k) =>
      this.observables[k].unsubscribe(),
    );
  }

  close(){    
    this.tService.openDeviceModel$.next({ value: false, key: 'tracker' });
  }
}
