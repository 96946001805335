import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animations } from 'src/app/animations/animations';
import { AppService } from 'src/app/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MapMinComponent } from '../../generate-video/map-min/map-min.component';
import { DeviceImageService } from 'src/app/pipes/deviceImagePipe';
import { resizeImage } from '../../map/components/map/class/mapUtil';
import { ModalController } from '@ionic/angular';
import { SetupDeviceService } from '../device-setup-guide/device-setup.service';
import { BrowserService } from 'src/app/services/plugins/browser.service';

@Component({
  selector: 'setup-device-position',
  templateUrl: './device-position.page.html',
  styleUrls: ['./device-position.page.scss'],
  animations: animations
})
export class DevicePositionPage implements OnInit, OnDestroy {
  @Input() device = null;
  deviceLocation = null;
  requestingLastPosition = null;
  loadingLasposition = false;
  public deviceName: string = '';
  public deviceModel: string = '';
  userManualLinks = [
    { language: 'en', link: 'https://www.paj-gps.com/manual/' },
    { language: 'de', link: 'https://www.paj-gps.de/anleitungen/' },
    { language: 'fr', link: 'https://www.paj-gps.fr/mode-demploi/' },
    { language: 'it', link: 'https://www.paj-gps.it/istruzione-duso/' },
    { language: 'es', link: 'https://www.paj-gps.es/manual-de-instrucciones/' },
    { language: 'pt', link: 'https://www.paj-gps.pt/manual-de-instrucoes/' }
  ];
  userManualLink = 'https://www.paj-gps.com/manual/';
  @ViewChild('setup_device_map') map: MapMinComponent = null;

  constructor(private autheService: AuthenticationService, private appService: AppService,
              private imageService: DeviceImageService, private modalCtrl: ModalController,
              private setupDeviceService: SetupDeviceService,
              private browserService: BrowserService,
            ) { }

  ngOnInit() {
    //_ Request last location every 15 seconds
    this.getLastLocation();
    this.requestingLastPosition = setInterval(() => this.getLastLocation(), 1000 * 15);

    this.getUserManual();
    this.deviceName = this.device.name;
    if(this.device.device_models.length > 0)
      this.deviceModel = this.device.device_models[0].model;
  }

  //_ Get device last location
  async getLastLocation(){
    this.loadingLasposition = true;
    await this.autheService.getAllLastPositionsPost([this.device.id], false).then(res => {
      if (res.success){
        setTimeout(() => this.loadingLasposition = false, 2000);
        if (res.success.length > 0){
          this.deviceLocation = res.success[0];
          //_ Wait a bit until the map is rendered and detected by viewChild
          setTimeout(() => this.addMarkerOnMap(), 200); 

          //_ Stop timer to not request anymore
          clearInterval(this.requestingLastPosition);
        }
      }
    }).catch( error => {
      console.error('Error getting last position', error);
      this.loadingLasposition = false;
    });
  }

  //_ Fix userManual link
  getUserManual(){
    const language = this.appService.language?.slice(0, 2);
    if (this.userManualLinks[language])
      this.userManualLink = this.userManualLink[language];
  }

  openLink(url){
    window.open(url, "_blank");
  }

  addMarkerOnMap(){
    if (!this.map) return;

    this.map.clearAllData();
    let lat = this.deviceLocation.lat;
    let lng = this.deviceLocation.lng;

    //_ If image already exist then use the existent image and not wait to request new one from imageService
    if (this.map.map.hasImage('d' + this.device.id)){
      this.map.addLayerMarkerUsingExistentImage('d' + this.device.id, lng, lat, 0.25, '', { type: 'device', data: this.device }, 'viewport', 'bottom');
      this.map.addCircle([lng, lat], 'c' + this.device.id, this.device.spurfarbe, 7);
    }
    else{
      this.imageService.getImage(this.device, this.appService.user.id).subscribe(async (img: any) => {
        if (img != this.imageService.whitePicture) {
          if (img.endsWith(".svg"))
            //_ Change aspect ratio if is a paj_iconset svg image
            img = await resizeImage(img, 122, 170, "white", false);
          else img = await resizeImage(img, 170, 170, 'white', false, true);
  
          if (lat && lng) {
            if (!this.map.map) return; //_ Do not add things to the map if is destroyed or removed. 
            this.map.addLayerMarker('d' + this.device.id, lng, lat, img, 0.25, '', { type: 'device', data: this.device }, 'viewport', 'bottom');
            this.map.addCircle([lng, lat], 'c' + this.device.id, this.device.spurfarbe, 7);
          }
        }
      });
    }

    this.map.fitBounds([this.deviceLocation].map(r => [r.lng, r.lat]), { top: 55, bottom: 90, left: 20, right: 20 });
  }

  dismiss(){
    this.device.setup_done = true;
    this.setupDeviceService.onDeviceSetupDone.next(this.device);
    this.modalCtrl.dismiss();
  }

  async openInWhatsapp(){
    let url:string ='https://api.whatsapp.com/send/?phone=4917657810417'
    await this.openUrl(url) 
  }
  
  private async openUrl(url) {
    if (url)
      await this.browserService.openBrowser(
        url,
        "_system"
      );
  }

  ngOnDestroy(): void {
    if (this.requestingLastPosition)
      clearInterval(this.requestingLastPosition);
  }
}
