import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { commandsProps } from '../pages/home/route.mixin';
import { DeviceStatusEnum } from 'src/app/members/map/devices-sidebar/device-item/device-submenu/info/info.service';
import { ToastController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDataService } from 'src/app/members/map/devicesData.service';
import { GeofenceService } from './geofence.service';
import { TimerService } from './timer.service';

export interface DeviceStatus {
  status: boolean;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  public devicesSubject = new BehaviorSubject<any[]>([]);
  private device = new BehaviorSubject<any>({});
  device$ = this.device.asObservable();

  deviceCommands = [];
  private _deviceStatus = new BehaviorSubject<DeviceStatus>({
    status: false,
    value: DeviceStatusEnum[2],
  });
  deviceStatus$ = this._deviceStatus.asObservable();
  // private _deviceStatus = new BehaviorSubject<string>(null);
  // deviceStatus$ = this._deviceStatus.asObservable();
  // intervalTimeCommand = null;
  intervalTimeCommand = {
    range: { min: 0, max: 60, step: 60 },
    selectedIndex: 0,
    value: 0,
    steps: [],
    icon: '',
    color: '',
    selectedIntervalId: null,
  };

  private _battery = new BehaviorSubject<{
    percentage: string | null;
    image: string | null;
  }>({ percentage: null, image: null });
  battery$ = this._battery.asObservable();
  constructor(
    private apiService: ApiService,
    private toastController: ToastController,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private devicesService: DeviceDataService,
    public geoService: GeofenceService,
    private timerService: TimerService,
  ) {}

  updateDeviceStatus(deviceStatus: DeviceStatus) {
    this._deviceStatus.next(deviceStatus);
  }

  updateBattery(battery: { percentage: string; image: string }) {
    this._battery.next(battery);
  }

  updateDevice(newDevice) {
    this.device.next(newDevice);
  }

  getCommands(device) {
    const { properties } = device;
    console.log('device', properties);
    this.apiService.getDeviceCommands(properties.imei).then(
      (response) => {
        if (response['data'].length) {
          //_ Add translation key and image to the response
          response['data'] = response['data'].map((d) => {
            return commandsProps[d.name]
              ? { ...d, ...commandsProps[d.name] }
              : d;
          });

          //_ Add fetch interval select fetch interval item
          const keysForInterval = ['SendingInterval10Min', 'XmPetInt10'];
          console.log('response[]', response['data']);
          const haveIntervalItems = response['data'].some((c) =>
            keysForInterval.some((k) => k === c.name),
          );
          console.log('haveIntervalItems', haveIntervalItems);
          if (haveIntervalItems) {
            const newCommandItem = commandsProps['ChooseFetchInterval'];
            const items = response['data'].filter(
              (c) => c.groupKey == 'FetchInterval' && c.type != 'listItem',
            );
            console.log('items,', items);
            if (items.length > 1) {
              items.forEach((item) => {
                if (item.intervalSeconds == properties.intervalllaenge)
                  this.intervalTimeCommand.selectedIntervalId = item.id;
                this.intervalTimeCommand.value = properties.intervalllaenge;
                this.intervalTimeCommand.selectedIndex = items
                  .map((it) => it.intervalSeconds)
                  .indexOf(properties.intervalllaenge);
              });

              newCommandItem['items'] = items;
              console.log('newCommandItem,', newCommandItem);
              response['data'].push(newCommandItem);
            }
          }

          // response['data'].forEach(d => )
          const commands = response['data'].filter((c) => c.type == 'listItem');
          console.log('commands', commands);
          const deleteItem = commands.find(
            (item) => item.groupKey == 'FetchInterval',
          );
          console.log('deleteItem', deleteItem);
          if (deleteItem) {
            this.intervalTimeCommand = {
              ...this.intervalTimeCommand,
              ...JSON.parse(JSON.stringify(deleteItem)),
            };
            commands.splice(commands.indexOf(deleteItem), 1);
          }
          this.deviceCommands = commands.reverse();
          this.intervalTimeCommand = {
            ...this.intervalTimeCommand,
            ...response['data'].find((c) => c.type === 'customInterval'),
          };
          console.log('COMMANDS', {
            commands: this.deviceCommands,
            intervals: this.intervalTimeCommand,
            intervalTimeCommand: this.intervalTimeCommand,
          });
          console.log('this.deviceCommands', this.deviceCommands);
        }
        // this.commandsLoading = false;
      },
      (error) => {
        // this.commandsLoading = false;
      },
    );
  }

  async sendDeviceCommand(
    item: any,
    device: any,
  ): Promise<{ status: string; onlineStatus: boolean; item?: any; response }> {
    return new Promise(async (resolve, reject) => {
      try {
        let deviceStatus: DeviceStatus = await this.getDeviceStatus(device);
        this.updateDeviceStatus(deviceStatus);
        if (deviceStatus.status) {
          console.log('data', {
            imei: device.imei,
            id: item.id,
            params: item.parameters,
          });
          this.apiService
            .sendDeviceCommand(device.imei, item.id, item.parameters)
            .then(
              (response) => {
                console.log('response', { response });

                if (response['data']) {
                  if (
                    response['data']['commandPolicy'] &&
                    response['data']['commandPolicy']['cooldown'] !== undefined
                  ) {
                    item.remainingTime =
                      response['data']['commandPolicy']['cooldown'];
                    if (item.remainingTime === 0) {
                      item.remainingTime = Math.max(item.remainingTime, 5);
                    }
                  }

                  if (
                    response['data']['payload'] &&
                    response['data']['payload']['params'] &&
                    item.name != 'chooseFetchInterval'
                  ) {
                    let remainingTime =
                      response['data']['payload']['params']['DURATION'];
                    item.remainingTime = remainingTime;
                  }
                  if (item.name == 'chooseFetchInterval') {
                    let remainingTime =
                      response['data']['commandPolicy']['cooldown'];
                    console.log('remainingTime', remainingTime);
                    item.remainingTime = remainingTime;
                    item.parameters.DURATION = null;
                    item.parameters.INTERVAL = null;
                  }

                  console.log('item', item);
                  // if (item.name != 'chooseFetchInterval'){
                  //   let {commandPolicy} =
                  //   :
                  //   cooldown
                  //   :
                  //   10

                  // }else{
                  this.timerService.startTimer(item, device);
                  // }

                  resolve({
                    status: 'online',
                    item,
                    onlineStatus: true,
                    response: response['data'],
                  });
                } else {
                  item.loading = false;
                  reject({ status: 'online', error: 'No data in response' });
                }
              },
              (error) => {
                console.error('Error sending device command', error);
                reject({ status: 'online', error });
              },
            );
        }

        console.log('deviceStatus in send Command', deviceStatus);
      } catch (error) {
        console.error('Error in sendDeviceCommand', error);
        reject({ status: 'unknown', error });
      }
    });
  }

  getDeviceStatus(device): Promise<DeviceStatus> {
    return new Promise<DeviceStatus>((resolve, reject) => {
      let status: DeviceStatus = {
        status: false,
        value: DeviceStatusEnum[2],
      };
      this.authService
        .getDeviceStatusData(device.id)
        .then((res) => {
          if (res) {
            if (res.success) {
              if (
                +res.success.last_update_by_service + 300 <
                  moment.utc().valueOf() / 1000 &&
                +device.lastPoint.dateunix + 300 < moment.utc().valueOf() / 1000
              ) {
                status = { status: false, value: DeviceStatusEnum[2] };
              } else {
                status = { status: true, value: DeviceStatusEnum[1] };
              }
            }
            this.updateDeviceStatus(status);
            resolve(status); // Resolve the promise when the status is updated
          } else {
            reject('Failed to get device status data'); // Reject the promise in case of failure
          }
        })
        .catch((err) => {
          console.error('Error in getDeviceStatusData:', err);
          reject(err); // Reject in case of an error in the API call
        });
    });
  }

  async presentToast(msg, color) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color: color,
      position: 'top',
      cssClass: 'ion-text-center',
    });
    toast.present();
  }
}
