import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { animations } from 'src/app/animations/animations';
import { AppService } from 'src/app/app.service';
import { getLogbookAddress } from 'src/app/components/map-address-picker/util';
import { AlarmData, translationsKeys } from 'src/app/members/notifications/notificationsUtil';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MapRenderImagePipe } from '../map-render-image.pipe';
import { DashboardTimelineService } from '../timeline-service';
import { imageDataToFile } from '../util/util';

@Component({
  selector: 'timeline-alarm-card',
  templateUrl: './alarm-card.component.html',
  styleUrls: ['./alarm-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: animations
})
export class AlarmCardComponent  implements OnInit, OnDestroy {
  @Input('alarm') get alarm () { return this._alarm; }
  _alarm = null;
  @Output('onItemInView') onItemInView = new EventEmitter();

  isIntoView = false;
  device = null;
  userId = this.appService.user.id;
  alarmData: AlarmData = null;
  address = null;

  whitePicture = ('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
  mapPreview: Observable<string> = new BehaviorSubject(this.whitePicture);
  loading = { address: false, mapPreview: false };
  imageLoaded = false;
  loadingImageObs = null;
  imageFromMedia = null;
  constructor(private cdr: ChangeDetectorRef, private appService: AppService, private authService: AuthenticationService,
              private mapImagePipe: MapRenderImagePipe, private timelineService: DashboardTimelineService) { }

  ngOnInit() {

  }

  set alarm(value) {
    this._alarm = value;
    if (value){
      this.device = this.appService.devices.find(d => d.id == value?.iddevice);
      this.loadAlarmData();
      // this.imageFromMedia = this.timelineService.getImageFromMediaStorage(this.alarm);
    }

  }

  loadAlarmData(){
    this.alarmData = translationsKeys.find(a => a.id == this.alarm.meldungtyp);
    this.cdr.markForCheck();
  }

  requestAddress() {
    let prom1 = null;
    if (!this.address && !this.loading.address && this.alarm.lat && this.alarm.lng) {
      this.loading.address = true;
      prom1 = this.authService.getStreetAdrressNew(this.alarm.lat, this.alarm.lng).then(res => {
        if (res) {
          if (res.success){
            this.address = getLogbookAddress(res.success.features[0].properties).address;
            this.cdr.markForCheck();
          }
        }
        this.loading.address = false;
      });
    }
  }

  isInViewport(){
    this.isIntoView = true;
    this.onItemInView.emit(this.alarm);
    this.requestAddress();
    this.loadMapPreview();
  }

  outOfViewport(){
    // this.isIntoView = true;
    // this.cdr.markForCheck();
  }

  loadMapPreview(){
    console.log('LOAD MAP PREVIEW');
    if (this.alarm.lat && this.alarm.lng && !this.imageLoaded && !this.loading.mapPreview && !this.imageFromMedia){
      this.loading.mapPreview = true;
      this.mapPreview = this.mapImagePipe.transform(this.device, [ { lat: this.alarm.lat, lng: this.alarm.lng} ], { renderMarker: true });
      this.loadingImageObs = this.mapPreview.subscribe(r => {
        if (r != this.whitePicture){
          this.loading.mapPreview = false;
          this.imageLoaded = true;

          this.timelineService.uploadImageOnServer(null, this.alarm.id, imageDataToFile(r));
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loadingImageObs)
      this.loadingImageObs.unsubscribe();
  }

}
