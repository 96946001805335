
<ion-header class="ion-no-border" @fadeInOut>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="goToFirstMenu()">
        <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
        {{ "app.backButtonText" | translate }}
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>

<!-- <ion-content force-overscroll="false">
  <ion-card #card class="background-container" @fadeInOut>
    <ion-card-content class="absolute-bottom">
      <ion-text color="primary">
        <h1 class="ion-text-center ion-padding-bottom blastimo">
          <b>{{ "setupWifi.setupWifiHome" | translate }}</b>
        </h1>
      </ion-text>
      <ion-text class="text-color">
        <h6 class="ion-text-center ion-padding-bottom">

          {{ "commandsTab.Setupwifi.gpsTrackerNearby" | translate }}
        </h6>
      </ion-text>
     <ion-button expand="block" shape="round" color="primary" (click)="goToStep2()" [disabled]="initSearch">
      <ion-spinner *ngIf="initSearch" name="dots"></ion-spinner>
      <ion-icon name="arrow-forward" slot="end"></ion-icon>
      {{ 'devicesSetup.guideSteps.nextButton' | translate }}
    </ion-button>
      <br />
    </ion-card-content>
  </ion-card>
  </ion-content> -->
  <ion-content class="ion-padding">
    <ion-img class="image-padding" src="assets/images/setup-wifi/wifi-zone.svg"></ion-img>
    <!-- <p style="text-align: center;" *ngIf="!this.initSearch">{{ "commandsTab.Setupwifi.gpsTrackerNearby" | translate }}</p> -->

    <ion-text *ngIf="!this.initSearch && this.timeExceeded" style="text-align: center;">
      <h6 style="color: red">{{ "commandsTab.Setupwifi.noMacAddress" | translate }}</h6>
    </ion-text>
    
    <div >      
      <ion-text *ngIf="!this.initSearch" style="text-align: center;">
        <h6>{{ "commandsTab.Setupwifi.gpsTrackerNearby" | translate }}</h6>
      </ion-text>
    </div>

    <div class="ion-text-center ion-padding" *ngIf="this.initSearch && !this.iddevice" style="display: flex;flex-direction: column;justify-content: center; align-items: center; gap:16px;">
      <ion-spinner color="primary"></ion-spinner>
      <!-- <ion-label class="ion-text-center ion-text-wrap">{{ 'commandsTab.SetupWifi.SearchingNearbyNetworks' | translate }}</ion-label> -->
      <ion-text class="ion-text-center ion-text-wrap">
        <h6>{{ 'commandsTab.SetupWifi.SearchingNearbyNetworks' | translate }}</h6>
      </ion-text>
    </div>
  </ion-content>

  <ion-footer
    class="ion-text-center" style="margin-bottom: 16px;"    
  >
    <ion-button
      class="ion-no-shadow"
      shape="round"
      color="primary"
      (click)="goToStep2()"
      [disabled]="initSearch"
      *ngIf="!this.timeExceeded"
    >
      <ion-icon name="arrow-forward" slot="end"></ion-icon>
      {{ 'devicesSetup.guideSteps.nextButton' | translate }}      
    </ion-button>    
    <ion-button
      class="ion-no-shadow"
      shape="round"
      color="primary"
      (click)="goToStep2()"
      [disabled]="initSearch"
      *ngIf="this.timeExceeded"
    >
      <ion-icon name="arrow-forward" slot="end"></ion-icon>
      {{ 'standalonePage.tryagain' | translate }}
    </ion-button>    
    <!-- <ion-button class="ion-no-shadow" shape="round" color="primary" (click)="goToStep2()" *ngIf="this.initSearch">
      <ion-icon name="arrow-forward" slot="end"></ion-icon>
      Done
    </ion-button>   -->
  </ion-footer>