import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AppService } from "src/app/app.service";
import { MapMinComponent } from "src/app/members/generate-video/map-min/map-min.component";
import { DeviceImageService } from "src/app/pipes/deviceImagePipe";
import { GET_LEGS, resizeImage } from "../../map/class/mapUtil";
import { StackManager, StackObject } from "./util/stack-manager";
import { STORAGE_SETTINGS, StorageSetting } from "src/app/services/storage-settings";
import { StorageService } from "src/app/services/storage.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { MediaApiService } from "src/app/components/media-managment/media-api.service";
import { fetchUrlToImageData } from "./util/util";
import { MAP_RENDER_IMAGE_OPTIONS } from "./map-render-image.pipe";

@Injectable({
    providedIn: 'root'
})

export class DashboardTimelineService {
    map = new BehaviorSubject<MapMinComponent>(null);
    stackManager = new StackManager();
    currentStackIndex = null;
    mapRenderEnds = null;
    currentProccess: StackObject = null;
    resolveImage = new Subject<{ id, img }>();
    lastDateRange = new StorageSetting(this.storage, STORAGE_SETTINGS.DEVICES_TIMELINE_LAST_DATE_RANGE, { startDate: null, endDate: null, lastRequest: null });
    constructor(private appService: AppService, private imageService: DeviceImageService, private storage: StorageService,
                private authService: AuthenticationService, private mediaApi: MediaApiService, private zone: NgZone){

    }

    //_ Public event to render the device marker and polylines in the map
    public renderRouteInMap(device, points, options: MAP_RENDER_IMAGE_OPTIONS, map: MapMinComponent = this.map.value){
        const newRequest = async () => {
            map.clearAllData();
            map.removePolylines('', true);
            if (options.renderMarker)
                this.renderMarker(device, points);
            if (options.renderStartEndFlag)
                this.renderStartEndMarker(device, points);

            await this.renderPolylines(device, points);
        }

        const proc = this.stackManager.push(newRequest);
        //_ Run first proccess if there is not proccess running
        if (!this.currentProccess){
            this.currentProccess = proc;
            proc.fn();
        }
        return proc;
    }

    //_ Call next proccess
    //_ Still left add priority to proccess next with hight priority (for example when is in viewport)
    private proccessNext(){
        const nextProccess = this.stackManager.getNextUncompleteProccess();

        if (nextProccess){
            this.currentProccess = nextProccess;
            this.stackManager.run(this.currentProccess.id);
        }
        else{
            this.currentProccess = null;
        }
    }

    //_ Set map instance to use to render the images
    setMap(map: MapMinComponent){
        console.log('MAP CHANGED', this.map);
        this.map.next(map);
        this.currentProccess = null;
        this.stackManager = new StackManager();
        this.listenForRenderEnds();
    }

    //_ Listend when the map complete render the sources in the map
    listenForRenderEnds(){
        if (this.mapRenderEnds)
            this.mapRenderEnds.unsubscribe();

        this.mapRenderEnds = this.map.value.mapRenderEnds.subscribe( r => {
            if (r){
                if (this.currentProccess){
                    const canvas = this.map.value.map.getCanvas();
                    this.resolveImage.next({ id: this.currentProccess.id, img: canvas.toDataURL('image/png') });
                    this.stackManager.complete(this.currentProccess.id);
                    this.proccessNext();
                }
            }
        });
    }

    //_ Render marker
    private renderMarker(device, points){
        if (!this.map.value.map) return; //_ Do not add things to the map if is destroyed or removed.

        //_ Index 0 = end point and index-1 = start point
        const startPoint = points[0];

        //_ If image already exist then use the existent image and not wait to request new one from imageService
        if (this.map.value.map.hasImage('d' + device.id)){
             this.map.value.addLayerMarkerUsingExistentImage('d' + device.id, startPoint.lng, startPoint.lat, 0.35, '', { type: 'device', data: device }, 'viewport', 'bottom');
            this.map.value.addCircle([startPoint.lng, startPoint.lat], 'c' + device.id, device.spurfarbe, 7);
        }
        else{
            //_Show device marker
            this.imageService.getImage(device, this.appService.user.id).subscribe(async (img: any) => {
                if (img != this.imageService.whitePicture) {
                    if (img.endsWith(".svg"))
                         //_ Change aspect ratio if is a paj_iconset svg image
                        img = await resizeImage(img, 122, 170, "white", false);
                    else img = await resizeImage(img, 170, 170, 'white', false, true);

                    if (startPoint.lat && startPoint.lng) {

                        // this.map.value.addLayerMarkerUsingExistentImage('start-marker', startPoint.lng, startPoint.lat, 0.35, '', { type: 'device', data: device }, 'viewport', 'bottom', { "icon-offset": [3, 10] });
                        this.map.value.addLayerMarker('d' + device.id, startPoint.lng, startPoint.lat, img, 0.35, '', { type: 'device', data: device }, 'viewport', 'bottom');
                        this.map.value.addCircle([startPoint.lng, startPoint.lat], 'c' + device.id, device.spurfarbe, 7);

                        // this.map.value.addLayerMarkerUsingExistentImage('end-marker', endPoint.lng, endPoint.lat, 0.35, '', { type: 'device', data: device }, 'viewport', 'bottom', { "icon-offset": [3, 10] });
                        // this.map.value.addCircle([endPoint.lng, endPoint.lat], 'c' + device.id, device.spurfarbe, 7);
                    }
                }
            });
        }


    }

    async renderStartEndMarker (device, points) {
        if (!this.map.value.map) return; //_ Do not add things to the map if is destroyed or removed.

        const endPoint = points[0];
        const startPoint = points[points.length-1];

        if (endPoint.lat && endPoint.lng) {
            this.map.value.addLayerMarkerUsingExistentImage('start-marker', startPoint.lng, startPoint.lat, 0.35, '', { type: 'device', data: device }, 'viewport', 'bottom', { "icon-offset": [0, 0] });
            // this.map.value.addLayerMarker('d' + device.id, startPoint.lng, startPoint.lat, img, 0.20, '', { type: 'device', data: device }, 'viewport', 'bottom');
            this.map.value.addCircle([startPoint.lng, startPoint.lat], 'c' + device.id, device.spurfarbe, 7);

            this.map.value.addLayerMarkerUsingExistentImage('end-marker', endPoint.lng, endPoint.lat, 0.35, '', { type: 'device', data: device }, 'viewport', 'bottom', { "icon-offset": [35, 3] });
            this.map.value.addCircle([endPoint.lng, endPoint.lat], 'c' + device.id, device.spurfarbe, 7);
        }
    }

    //_ Render polylines
    async renderPolylines(device, points){
        const addDataOnMap = () => {
            this.map.value.addPolylines(points.map(p => [p.lng, p.lat]), false, this.map.value.map.getZoom(), 0, device.spurfarbe, 4);
            this.map.value.fitBounds(points.map(r => [r.lng, r.lat]), { top: 80, bottom: 100, left: 60, right: 100 });
        }

        if (points.length < 20 && points.length > 1) {
            this.map.value.disableRenderEnds = true;
            const snapResponse = await this.getSnapedAndPausePoints(points);
            points = snapResponse.snapPoints.map(p => ({ lat: p[1], lng: p[0] }));
            addDataOnMap();
            this.map.value.disableRenderEnds = false;
        }
        else
            addDataOnMap();
    }

    renderAlarmMarker(device){

    }

    getSnapedAndPausePoints(dataPoints, length = 0.05, snapFlag = true, device = null): Promise<any> {
        //_ Filter datapoints to ignore wifi points
        const dataPointsT = dataPoints.filter(pt => pt.wifi == null);
        let subscription = null;
        const postData = {
            dataPoints: dataPointsT,
            iddevice: dataPoints[0].iddevice,
            snapFlag,
            useDatapoints: true,
            noPauses: true,
            sortByDate: false,
            useGraphhopper: this.appService.userSettings.use_graphhopper ?? 0
        };
        const promise = new Promise((resolve, reject) => {
            if (dataPointsT.length > 0) {
                subscription = this.authService._getValhallaResponses(postData).subscribe((res: any) => {
                    console.log('GET VALHALLA CANCELABLE PROMISE', res);
                    // If get response from vallhalla, then send the points
                    // to the function getLegs to get array of legs (line strings) of the route
                    if (res?.success) {
                        resolve ({ snapPoints: GET_LEGS(res.success, length, this.appService.userSettings.use_graphhopper), pauses: res.success.pause_markers });
                    }
                    else {
                        resolve ({ snapPoints: [], pauses: [] });
                    }
                });
            }
            else resolve ({ snapPoints: [], pauses: [] });
        });

        return promise;
    }

    uploadImageOnServer(routeId, alarmId, file){
        const formData = new FormData();
        formData.append('images[0]', file, file.name);
        if (routeId)
            formData.append('routeId', routeId);
        if (alarmId)
            formData.append('alarmId', alarmId);

        this.authService.saveTimelineImage(this.appService.userId, formData).subscribe( r => {
          console.log('TIMELINE IMAGE UPLOADED', r);
        });
    }

    getImageFromMediaStorage(model){
        if (model.media?.length > 0){
            return new Observable(observer => {
                observer.next(this.mediaApi.whitePicture);
                this.zone.run( async () => {
                    const imageUrl = this.mediaApi.getImageLinkById(model.media[0].id);
                    const image = await fetchUrlToImageData(imageUrl, this.storage);
                    observer.next(image);
                }, error => {
                    //_ Return default image paj icon
                    //_ Define a crash image
                    observer.next(null);
                    return;
                });
                return { unsubscribe() { } };
            });
        }
        else
            return null;
    }
}
